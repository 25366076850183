const API_BASE_URL = process.env.REACT_APP_API_HOST
const ACCOUNT = {
  LOGIN_BY_ADMIN: (id: string) => `${API_BASE_URL}/Account/${id}/tokens`,
}
const USER_API_END_POINTS = {
  USERS: () => `${API_BASE_URL}/Users`,
  USERS_PROFILE: (id: string) => `${API_BASE_URL}/Users/${id}`,
  USERS_PASSES: (id: string) => `${API_BASE_URL}/UserProfile/${id}/passes`,
  USERS_SEARCH: () => `${API_BASE_URL}/UserProfile/search-autocomplete`,
  SET_ATTRIBUTE: (id: string, action: string | null) =>
    `${API_BASE_URL}/Users/${id}/set-attribute/${action}`,
  IS_EXIST_USERNAME: (username: string) => `${API_BASE_URL}/Users/is-exits-username/${username}`,
  CHANGE_USERNAME: () => `${API_BASE_URL}/Users/change-username`,
  IS_EXIST_EMAIL: (email: string) => `${API_BASE_URL}/Users/is-exist-email/${email}`,
  CHANGE_EMAIL: () => `${API_BASE_URL}/Users/change-email`,
  NETWORK_TREE_INFOR: (id: string) => `${API_BASE_URL}/UserProfile/${id}/network-tree-info`,
  MY_B_NETWORK_TREE: () => `${API_BASE_URL}/Users/manage/my-b-network-tree`,
  ADD_USERS: () => `${API_BASE_URL}/Users/add-users`,
  ADD_USER: () => `${API_BASE_URL}/Users/add-user`,
  SEARCH_CONTAIN: (username: string) =>
    `${API_BASE_URL}/Users/search?SearchKey=${username}&Comparison=StartWith`,
  SEARCH_EXACT: (username: string) =>
    `${API_BASE_URL}/Users/search?SearchKey=${username}&Comparison=Exact`,
  ROLES: () => `${API_BASE_URL}/Users/roles`,
  RENEW_MONTHLY_PASS: () => `${API_BASE_URL}/Users/manage/auto-renew-monthly-pass`,
  RENEW_ANNUAL_PASS: () => `${API_BASE_URL}/Users/manage/auto-renew-annual-pass`,
  DEACTIVE_MEMBERSHIP: () => `${API_BASE_URL}/UserProfile/deactive-membership`,
  DOWNLINE_MEMBER: () => `${API_BASE_URL}/Users/manage/downline-members`,
  LOGOUT: () => `${API_BASE_URL}/Account/logout`,
  LOGIN: () => `${API_BASE_URL}/Account/login-admin`,
}
const BONUS_MANAGERMENT = {
  DAILY_BONUS: (id: string) => `${API_BASE_URL}/DailyBonus/manage/${id}`,
  DAILY_BONUS_SEARCH: () => `${API_BASE_URL}/DailyBonus/manage/search`,
  DAILY_BONUS_GET_LIST: () => `${API_BASE_URL}/DailyBonus/get-list`,
  APPROVE: () => `${API_BASE_URL}/DailyBonus/manage/approve`,
}
const NETWORK_TREE = {
  MY_B_NETWORK_TREE: (NodeId: number, LevelLimit: number) =>
    `${API_BASE_URL}/NetworkTree/my-b-network-tree?NodeId=${NodeId}&LevelLimit=${LevelLimit}`,
  CHANGE_SPONSOR: () => `${API_BASE_URL}/NetworkTree/change-sponsor`,
}
const MEMBERSHIPS = {
  MONTHLY_PASS: () => `${API_BASE_URL}/MonthlyPasses/manage/get-list`,
  YEARLY_PASS: (isAdmin: boolean = true) => `${API_BASE_URL}/YearlyPasses?isAdmin=${isAdmin}`,
  ACTIVE_YEARLY_PASS: () => `${API_BASE_URL}/YearlyPasses/manage/active-yearly-pass`,
  ACTIVE_MONTHLY_PASS: () => `${API_BASE_URL}/MonthlyPasses/manage/active-monthly-pass`,
  ACTIVE_MONTHLY_PASS_MULTI: () =>
    `${API_BASE_URL}/MonthlyPasses/manage/activate-monthly-pass-multi-user`,
  VALIDATE_ACTIVE_BY_ADMIN: (monthlyPassId: string, userId: string) =>
    `${API_BASE_URL}/MonthlyPasses/manage/validate-active-by-admin?monthlyPassId=${monthlyPassId}&userId=${userId}`,
  UPGRADE_MONTHLY_PASS: () => `${API_BASE_URL}/MonthlyPasses/manage/upgrade-monthly-pass`,
  RENEW_MONTHLY_PASS: () => `${API_BASE_URL}/MonthlyPasses/manage/renew-monthly-pass`,
  ACTIVE_PACKAGE: () => `${API_BASE_URL}/Package/manage/activate-package`,
  PACKAGE: () => `${API_BASE_URL}/Package/manage/all-package-filters`,
  PASSCODES: () => `${API_BASE_URL}/PassCodes`,
}
const WALLETS = {
  MY_WALLET: () => `${API_BASE_URL}/Wallet`,
  WALLET: (userId: string) => `${API_BASE_URL}/Wallet/manage/${userId}/wallets`,
  WALLET_HISTORY: () => `${API_BASE_URL}/Wallet/manage/wallet-history`,
  DEPOSIT: () => `${API_BASE_URL}/Wallet/manage/deposit-wallets`,
  WALLET_TOPUP_TYPE: () => `${API_BASE_URL}/AppConfig/support-deposit-wallet-types`,
  WALLET_WITHDRAW_TYPE: () => `${API_BASE_URL}/AppConfig/support-withdraw-wallet-types`,
  WITHDRAW: () => `${API_BASE_URL}/Wallet/manage/withdraw-wallets`,
  TOKEN_RATE: () => `${API_BASE_URL}/TokenRate`,
  TOKEN_CONVERSION: () => `${API_BASE_URL}/TokenRate/rate-conversion`,
  WITHDRAW_HISTORY: () => `${API_BASE_URL}/Wallet/manage/withdraw-history`,
  WTIHDRAW_CONFIRM: () => `${API_BASE_URL}/Wallet/manage/withdraw-confirm`,
  ADMIN_WALLET_HISTORY: () => `${API_BASE_URL}/Wallet/manage/admin-wallet-history`,
  USERS_LOCK_WALLET: () => `${API_BASE_URL}/Wallet/list-black-list`,
  ADD_USER_LOCK_WALLET: () => `${API_BASE_URL}/Wallet/manage/add-blacklist-wallet`,
  DELETE_USER_LOCK_WALLET: () => `${API_BASE_URL}/Wallet/delete-black-list`,
}
const RECEIPT_MANAGEMENT = {
  GET_LIST: () => `${API_BASE_URL}/Receipt/receipts`,
  APPROVE: (receiptId: string) => `${API_BASE_URL}/Receipt/${receiptId}/approved`,
  REJECT: (receiptId: string) => `${API_BASE_URL}/Receipt/${receiptId}/rejected`,
  RESUBMIT: (receiptId: string) => `${API_BASE_URL}/Receipt/${receiptId}/ask-to-resubmit`,
  RECEIPT: (receiptId: string) => `${API_BASE_URL}/Receipt/${receiptId}`,
  RECEIPT_DETAIL: (receiptId: string) => `${API_BASE_URL}/Receipt?id=${receiptId}`,
  SUMMARY: () => `${API_BASE_URL}/Receipt/summary`,
  CURRENT_CYCLE: () => `${API_BASE_URL}/Receipt/current-cycle`,
  MY_CYCLES: () => `${API_BASE_URL}/Receipt/my-cycles`,
}
const DATA = {
  GET_RANK: () => `${API_BASE_URL}/Data/get-ranks`,
  GET_BONUS_RECIPIENT_CHANGE_HISTORIES: () =>
    `${API_BASE_URL}/Data/bonus-recipient-change-histories`,
  DELETE_BONUS_RECIPIENT_CHANGE: (id: string) =>
    `${API_BASE_URL}/Data/bonus-recipient-change/${id}`,
  APPLY_BONUS_RECIPIENT_CHANGE: () => `${API_BASE_URL}/Data/bonus-recipient-change`,
}
const STATISTIC = {
  STATISTIC: () => `${API_BASE_URL}/Statistic`,
  TRANSACTION: () => `${API_BASE_URL}/Statistic/transactions`,
  PAYOUT: () => `${API_BASE_URL}/Statistic/payout`,
  REVENUE: () => `${API_BASE_URL}/Statistic/revenue`,
  VMM: () => `${API_BASE_URL}/Statistic/VMM`,
  PASSCODE: () => `${API_BASE_URL}/Statistic/pass-code`,
  TOKEN: () => `${API_BASE_URL}/Statistic/token`,
  FILTER_TOKEN: () => `${API_BASE_URL}/Statistic/filter-token`,
  TOKEN_REPORT: () => `${API_BASE_URL}/Statistic/token-report`,
}
const TOKEN_RATE = {
  STORE_TOKEN: () => `${API_BASE_URL}/TokenRate/manage/store-token-price-daily`,
  GET_LIST: () => `${API_BASE_URL}/TokenRate/manage/get-token-price-daily-list`,
  TOKEN_RATE: () => `${API_BASE_URL}/TokenRate`,
  RATE_CONVERSION: () => `${API_BASE_URL}/TokenRate/rate-conversion`,
  UPDATE_TOKEN_PRICE: () => `${API_BASE_URL}/TokenRate/manage/update-token-price-daily`,
}

const LOTTERY = {
  LOTTERY_SUMMARY: () => `${API_BASE_URL}/Lottery/manage/lottery-summary`,
  LOTTERY_HISTORY: () => `${API_BASE_URL}/Lottery/manage/get-lottery-cycle-histories`,
  LOTTERY_UPDATE: () => `${API_BASE_URL}/Lottery/manage/edit-lottery-cycle`,
  LOTTERY_SEARCH_BUYERS: () => `${API_BASE_URL}/Lottery/manage/search-buyers-in-cycle`,
  LOTTERY_GET_NUMBER: () => `${API_BASE_URL}/Lottery/manage/get-lottery-numbers-in-cycle`,
  GENERATE_TICKET: () => `${API_BASE_URL}/Lottery/manage/generate-lottery-tickets`,
  LOTTERY_EVENTS: () => `${API_BASE_URL}/Lottery/manage/search-lottery-tickets`,
  LOTTERY_END_CYClE: () => `${API_BASE_URL}/Lottery/manage/end-current-lottery-cycle`,
  LOTTERY_CANCEL_CYClE: () => `${API_BASE_URL}/Lottery/manage/cancel-lottery-cycle`,
  LOTTERY_CYClE: (id: string) => `${API_BASE_URL}/Lottery/manage/lottery-cycles/${id}`,
  PRIZE_PLAN: () => `${API_BASE_URL}/Lottery/manage/ticket-reward-plan`,
}

const STORE = {
  STORE_LIST: () => `${API_BASE_URL}/Store/search-stores`,
  ADD_STORE: () => `${API_BASE_URL}/Store`,
  STORE: (id: string) => `${API_BASE_URL}/Store/${id}`,
  UPDATE_STORE: () => `${API_BASE_URL}/Store`,
  MERGE_STORE: () => `${API_BASE_URL}/Store/merge`,
}

const CATEGORY = {
  CATEGORY_STATUS: (status: string) => `${API_BASE_URL}/Categories/${status}`,
}
const SYS_NOTIFICATION = {
  FILTER: () => `${API_BASE_URL}/SysNotification/filter`,
  ADD_NOTIFICATION: () => `${API_BASE_URL}/SysNotification`,
  UPDATE_NOTIFICATION: () => `${API_BASE_URL}/SysNotification`,
  GET_BY_ID: () => `${API_BASE_URL}/SysNotification/by-id`,
  TOGGLE: () => `${API_BASE_URL}/SysNotification/toggle`,
}
const LANGUAGE = {
  LIST: () => `${API_BASE_URL}/Language`,
}

const APP_CONFIG = {
  LIST_SETTINGS: () => `${API_BASE_URL}/AppConfig/function-settings`,
  MAINTENANCE: () => `${API_BASE_URL}/AppConfig/maintenance`,
  TOOGLE_FUNCTION_SETTING: () => `${API_BASE_URL}/AppConfig/toggle-fucntion-setting`,
  RELOAD_CONFIG: () => `${API_BASE_URL}/AppConfig/reload-configured-values`,
  UPDATE_APP_CONFIG_VALUE: () => `${API_BASE_URL}/AppConfig/manage/update-app-config-value`,
  GET_APP_CONFIG_VALUE: () => `${API_BASE_URL}/AppConfig/manage/get-app-config-value`,
  LIST_FEETRANSFERCONFIGS: () => `${API_BASE_URL}/AppConfig/get-package-by-changeType-walletType`,
  UPDATE_FEETRANSFERCONFIGS: () => `${API_BASE_URL}/AppConfig/update-min-max-transfer-by-id`,
}

const PACKAGE_SETTING = {
  GET_PACKAGES: () => `${API_BASE_URL}/AppConfig/all-package-setting`,
  PACKAGE_DETAIL: () => `${API_BASE_URL}/AppConfig/package-setting-by-id`,
  UPDATE_PACKAGE: () => `${API_BASE_URL}/AppConfig/update-package-setting`,
  GET_TAG_PACKAGE: () => `${API_BASE_URL}/AppConfig/get-tag-pakages`,
}

const PAYMENT_PACKAGE = {
  GET_ALL_OPTION: (id: string) => `${API_BASE_URL}/Payment/payment-option/${id}`,
  PAYMENT_OPTION: () => `${API_BASE_URL}/Payment/payment-option`,
  GET_PAYMENT_METHOD: () => `${API_BASE_URL}/PaymentMethod/manage/search-payment-methods`,
  PUT_PAYMENT_METHOD_STATUS: (id: string) =>
    `${API_BASE_URL}/PaymentMethod/manage/payment-methods/${id}/status`,
  SEARCH_PAYMENT_METHOD: () => `${API_BASE_URL}/PaymentMethod/manage/search-payment-methods`,
  SET_PAYMENT_METHOD_TO_PAYMENT_ITEM: () =>
    `${API_BASE_URL}/PaymentMethod/manage/set-payment-methods-to-payment-item`,
}

const AZURE_STORAGE = {
  UPLOAD_ADMIN: (type: string) => `${API_BASE_URL}/AzureStorage/manage?uploadType=${type}`,
}

const SAVING = {
  GET_SAVING_ACCOUNT: () => `${API_BASE_URL}/saving/manage/get-list-saving-account`,
  GET_SAVING_TERM: () => `${API_BASE_URL}/saving/manage/get-list-saving-term`,
  ADD_SAVING_TERM: () => `${API_BASE_URL}/saving/manage/create-saving-term`,
  UPDATE_SAVING_TERM: () => `${API_BASE_URL}/saving/manage/update-saving-term`,
  DELETE_SAVING_TERM: (id: string) => `${API_BASE_URL}/saving/manage/saving-term/${id}`,
  DETAIL_SAVING_TERM: (id: string) => `${API_BASE_URL}/saving/manage/saving-term/${id}`,
  UPDATE_EARLY_WITHDRAWAL_RATE: () => `${API_BASE_URL}/saving/manage/update-early-withdrawal-rate`,
  GET_EARLY_WITHDRAWAL_RATE: () => `${API_BASE_URL}/saving/early-withdrawal-rate`,
}

const SWAP_MANAGEMENT = {
  GET_LIST_SWAP: () => `${API_BASE_URL}/Swap/manage/list-swap`,
  REJECT_SWAP: () => `${API_BASE_URL}/Swap/manage/reject-swap`,
  APPROVE_SWAP: () => `${API_BASE_URL}/Swap/manage/approve-swap`,
  GET_SLIPPARE_RATE: () => `${API_BASE_URL}/Swap/manage/slippage-rate`,
  UPDATE_SLIPPARE_RATE: () => `${API_BASE_URL}/Swap/manage/slippage-rate`,
}
const External_Transfer = {
  GET_LIST_External: () => `${API_BASE_URL}/ExchangePayment/payment-history-list`,
}
const APP_SETTING = {
  GET_LIST_FUNCTION: () => `${API_BASE_URL}/App/list-config-maintenance-app`,
  UPDATE_FUNCTION: (id: string) => `${API_BASE_URL}/App/update-maintenance/${id}`,
  GET_LIST_CHECK_VERSION: () => `${API_BASE_URL}/App/get-version`,
  UPDATE_CHECK_VERSION: (id: string) => `${API_BASE_URL}/App/update-version`,
}
const PAYOUT = {
  GET_LIST: () => `${API_BASE_URL}/`,
}
const PAYMENT = {
  GET_LIST: () => `${API_BASE_URL}/`,
  CREATE_PAYMENT_METHOD: () => `${API_BASE_URL}/PaymentMethod/manage/payment-methods`,
  UPDATE_PAYMENT_METHOD: (id: string) =>
    `${API_BASE_URL}/PaymentMethod/manage/payment-methods/${id}`,
  GET_LIST_PAYMENT_METHOD: () => `${API_BASE_URL}/PaymentMethod/manage/payment-method-items`,
}
const END_POINTS = {
  ACCOUNT,
  USER_API_END_POINTS,
  BONUS_MANAGERMENT,
  MEMBERSHIPS,
  NETWORK_TREE,
  External_Transfer,
  DATA,
  WALLETS,
  RECEIPT_MANAGEMENT,
  STATISTIC,
  TOKEN_RATE,
  LOTTERY,
  STORE,
  CATEGORY,
  SYS_NOTIFICATION,
  LANGUAGE,
  APP_CONFIG,
  PACKAGE_SETTING,
  AZURE_STORAGE,
  PAYMENT_PACKAGE,
  SAVING,
  SWAP_MANAGEMENT,
  APP_SETTING,
  PAYOUT,
  PAYMENT,
}

export default END_POINTS
