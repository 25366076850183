import React from 'react'
function Page404() {
  return (
    <>
      <div className="d-flex flex-column align-item-center justify-content-center text-center">
        <div className="position-relative">
          <img src="/assets/img/mascot/bg-bee.jpg" alt="VMM Cashback" width={'100%'} />
          <div className="position-absolute start-0 end-0" style={{ bottom: '2%' }}>
            <h3 className="text-warning mt-3">ERROR: 404</h3>
          </div>
        </div>
      </div>
    </>
  )
}

export default Page404
