import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function Toast(props: any) {
  const { position } = props

  return (
    <ToastContainer
      position={position ? position : 'bottom-right'}
      autoClose={false}
      hideProgressBar={true}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      theme="light" //light|dark|colored
    />
  )
}
