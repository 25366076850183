import { PackageDtoModel, PackageSettingGroup } from 'models'
import React from 'react'
import { Badge } from 'react-bootstrap'
interface TypeProps {
  data: PackageDtoModel[] | undefined
  onClick: Function
  onEditPayout: Function
  onEditPayment: Function
}

const listNamePackageGroup = [
  { value: PackageSettingGroup.AnnualPlatformFee, name: 'ANNUAL PLATFORM FEE' },
  { value: PackageSettingGroup.AiBigData, name: 'AI BIGDATA' },
  { value: PackageSettingGroup.MerchantPackage, name: 'MERCHANT PACKAGE' },
  { value: PackageSettingGroup.PackageService, name: 'PACKAGE SERVICE' },
  { value: PackageSettingGroup.AiBigDataTravel, name: 'AI BIGDATA TRAVEL' },
  { value: PackageSettingGroup.AiTrader, name: 'AI TRADER' },
]
function ListManagePackage(props: TypeProps) {
  const { data, onClick, onEditPayout, onEditPayment } = props
  return (
    <>
      {data && data.length > 0 && (
        <div className="table-responsive my-4 custom-table-td">
          <table className="table table-striped table-hover">
            <thead>
              <tr className="text-muted">
                <th style={{ maxWidth: 500 }}>Group</th>
                <th>Name</th>
                <th className="text-end">Price</th>
                <th className="text-center">Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((_pack: PackageDtoModel) => {
                return (
                  <tr key={_pack.id}>
                    {_pack.rowSpan && _pack.rowSpan > 0 && (
                      <td
                        className="text-uppercase"
                        rowSpan={_pack.rowSpan}
                        style={{
                          backgroundColor: '#F7F8FA',
                          borderRight: '1px solid #cccccc87',
                        }}
                      >
                        {
                          listNamePackageGroup.find((p) => p.value === _pack.packageSettingGroup)
                            ?.name
                        }
                      </td>
                    )}
                    <td>
                      <strong className="text-uppercase">{_pack.boDisplayName}</strong>
                    </td>
                    <td className="text-end ">
                      <strong>${_pack.price}</strong>
                    </td>
                    <td className="text-center">
                      <Badge
                        bg={!_pack.blockPurchase ? 'success' : 'secondary'}
                        style={{ fontSize: '11px' }}
                      >
                        {!_pack.blockPurchase ? 'Active' : 'Block'}
                      </Badge>
                    </td>
                    <td>
                      <div className="row">
                        <div className="d-flex justify-content-center">
                          <button
                            className=" btn btn-primary btn-sm px-2 me-2"
                            onClick={() => onClick(_pack)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit information"
                          >
                            <i className="bi bi-pencil"></i>
                          </button>
                          <button
                            className=" btn btn-info btn-sm px-2 me-2"
                            onClick={() => onEditPayment(_pack)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit payment method"
                          >
                            <img src={'/assets/img/packs/ph_hand.svg'} alt="" />
                          </button>

                          {/* <button
                            className=" btn btn-success btn-sm px-2 me-2"
                            onClick={() => onEditPayout(_pack)}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit payout method"
                          >
                            <img src={'/assets/img/packs/icon-payout-method.svg'} alt="" />
                          </button> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ListManagePackage
