import FormEditPackage from 'components/form-edit-package'
import ListManagePackage from 'components/list-manage-package'
import Loader from 'components/loader'
import OpenAlert from 'components/sweetAlert'
import { LOADING } from 'helpers/constants'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import {
  GetPackageSettingDto,
  ModelPayoutMethod,
  PackageDtoModel,
  PackagesSettingDto,
  I2SearchPaymentMethod,
  I2PaymentMethod,
  ModelPaymentMethodItem,
  I2ParamSetPayment,
} from 'models'
import EditPackagePayment from 'pages/components/edit-package-payment'
import EditPackagePayout from 'pages/components/edit-package-payout'
import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const MENU_ITEMS_MANAGEMENT_PACKAGE = {
  Packages: 'Packages',
  PaymentMethods: 'Payment methods',
  // PayoutMethods: 'Payout methods',
  Overview: 'Overview',
}

export const MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE = {
  Packages: 'Packages',
  PaymentMethods: 'PaymentMethods',
  // PayoutMethods: 'PayoutMethods',
  Overview: 'Overview',
}
export const MENU_MANAGEMENT_PACKAGE = [
  {
    label: MENU_ITEMS_MANAGEMENT_PACKAGE.Packages,
    value: MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE.Packages,
    link: 'packages-setting',
  },
  {
    label: MENU_ITEMS_MANAGEMENT_PACKAGE.PaymentMethods,
    value: MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE.PaymentMethods,
    link: 'payment-method',
  },

  // {
  //   label: MENU_ITEMS_MANAGEMENT_PACKAGE.PayoutMethods,
  //   value: MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE.PayoutMethods,
  //   link: 'payout',
  // },
  //   {
  //     label: MENU_ITEMS_MANAGEMENT_PACKAGE.Overview,
  //     value: MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE.Overview,
  //     link: '/',
  //   },
]
function PackagesSetting() {
  const [menuActive, setMenuActive] = useState(MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE.Packages)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState<Array<PackageDtoModel>>([])
  const [status, setStatus] = useState<string>('')
  const [group, setGroup] = useState<string>('')
  const [showModalEdit, setShowModalEdit] = useState(false)
  const [showModalPaymentMethod, setShowModalPaymentMethod] = useState(false)
  const [showModalPayoutMethod, setShowModalPayoutMethod] = useState(false)
  const [showFormLimitEdit, setShowFormLimitEdit] = useState<PackageDtoModel>()
  const [packageName, setPackageName] = useState<string>('')
  const [packageSelected, setPackageSelected] = useState<PackageDtoModel>()
  const [packageSelectedForPayment, setPackageSelectedForPayment] = useState<PackageDtoModel>()

  const [currentPackage, setCurrentPackage] = useState<GetPackageSettingDto>(
    {} as GetPackageSettingDto,
  )
  const [currentPaymentForPackage, setCurrentPaymentForPackage] = useState<I2PaymentMethod[]>([])
  const [isOn, setIsOn] = useState()
  const [monthlyPurchasedReserveByRanks, setMonthlyPurchasedReserveByRanks] = useState()
  const [monthlyPurchasedPackageLimit, setMonthlyPurchasedPackageLimit] = useState()
  const [packageTabSelected, setPackageTabSelected] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const [payoutMethods, setPayoutMethods] = useState<any[]>([])
  const [paymentMethods, setPaymentMethods] = useState<ModelPaymentMethodItem[]>([])

  const [packageSelectedForPayout, setPackageSelectedForPayout] = useState<PackageDtoModel>()
  const [currentPayoutForPackage, setCurrentPayoutForPackage] = useState<ModelPayoutMethod[]>([])
  const loadData = async () => {
    setIsLoading(true)
    axiosClient()
      .get(END_POINTS.PACKAGE_SETTING.GET_TAG_PACKAGE())
      .then((tab) => setPackageTabSelected(tab.data))
    PubSub.subscribe(LOADING, function (msg, data) {
      setIsLoading(data)
    })

    const query: any = await axiosClient().get(END_POINTS.PACKAGE_SETTING.GET_PACKAGES(), {
      params: {
        keySearch: packageName,
        packageSettingGroup: group ? group : null,
        blockPurchase: status == '' ? null : status == 'true' ? true : false,
      },
    })
    if (!query.data || query.data?.length === 0) {
      setDataTable([])
      return
    } else {
      const _data: PackagesSettingDto[] = query.data || []
      let _dataMap: PackageDtoModel[] = []
      _data.forEach((item, idx) => {
        if (item?.packagesSetting && item?.packagesSetting.length > 0) {
          item?.packagesSetting.forEach((pack, idxPack) => {
            _dataMap.push({
              ...pack,
              packageSettingGroup: item.packageSettingGroup,
              rowSpan: idxPack === 0 ? item?.packagesSetting?.length : undefined,
            })
          })
        }
      })
      setDataTable(_dataMap)
    }
  }
  useEffect(() => {
    loadData()
  }, [])

  const handleSubmitUpdatePackage = async (
    pack: GetPackageSettingDto,
    monthlyPurchasedReserveByRanks: any,
    monthlyPurchasedPackageLimit: any,
    isOn: any,
  ) => {
    if (pack !== undefined) {
      let reload = false
      setLoading(true)
      PubSub.subscribe(LOADING, function (msg, data) {
        setLoading(data)
      })
      const res = await axiosClient().post(END_POINTS.PACKAGE_SETTING.UPDATE_PACKAGE(), {
        ...pack,
        packageSettingGroup: packageSelected?.packageSettingGroup,
        monthlyPurchasedPackageLimit: monthlyPurchasedPackageLimit,
        monthlyPurchasedReserveByRanks: monthlyPurchasedReserveByRanks,
        hasMonthlyLimitAndReserve: isOn,
      })
      if (res) {
        reload = true
        setShowModalEdit(false)
        OpenAlert('Update package successfully!', '', 'success')
      }
      if (reload) {
        setTimeout(() => {
          loadData()
          loadPackage()
        }, 500)
      }
    }
  }

  const onClickPackageDetail = async (_pack: PackageDtoModel) => {
    setPackageSelected(_pack)
    setShowModalEdit(true)
    setShowFormLimitEdit(_pack)
  }
  const loadPackage = useCallback(async () => {
    setLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setLoading(data)
    })
    setCurrentPackage({} as GetPackageSettingDto)
    if (packageSelected && packageSelected.id) {
      const query = await axiosClient().get(END_POINTS.PACKAGE_SETTING.PACKAGE_DETAIL(), {
        params: {
          packageSettingGroup: packageSelected?.packageSettingGroup,
          id: packageSelected.id,
        },
      })

      if (!query.data) return
      query.data.packageTabSelected = packageTabSelected
      const data: GetPackageSettingDto = query.data
      setCurrentPackage(data)
    }
  }, [packageSelected?.id])

  useEffect(() => {
    loadPackage()
  }, [loadPackage])

  const onCloseModalEdit = () => {
    setPackageSelectedForPayment(undefined)
    setCurrentPaymentForPackage([])
    setShowModalPaymentMethod(false)
  }

  const loadPayoutMethod = async () => {
    // Todo: call api load payout methods
    // const query = await axiosClient().get(END_POINTS.PAYOUT.GET_LIST(), {})
    // if (query.data) {
    //   setPayoutMethods(query.data)
    // }
  }
  const loadPaymentMethod = async () => {
    setLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setLoading(data)
    })
    const query: any = await axiosClient().post(END_POINTS.PAYMENT_PACKAGE.GET_PAYMENT_METHOD(), {})
    if (!!!query.data || query.data?.length === 0) {
      setPaymentMethods([])
    } else {
      setPaymentMethods(query.data)
    }
  }
  useEffect(() => {
    loadPayoutMethod()
    loadPaymentMethod()
  }, [])

  const handleCancelEditPayment = () => {
    setShowModalPaymentMethod(false)
  }
  const handleSubmitEditPayment = async (paymentMethodIds: string[]) => {
    if (packageSelectedForPayment && packageSelectedForPayment.entityType) {
      try {
        let bodyParams: I2ParamSetPayment = {
          entityType: packageSelectedForPayment.entityType,
          entityId: packageSelectedForPayment.id,
          paymentMethodIds: paymentMethodIds,
        }
        setLoading(true)
        PubSub.subscribe(LOADING, function (msg, data) {
          setLoading(data)
        })
        const query = await axiosClient().post(
          END_POINTS.PAYMENT_PACKAGE.SET_PAYMENT_METHOD_TO_PAYMENT_ITEM(),
          bodyParams,
        )
        if (query) {
          OpenAlert(
            `Update payment method for ${packageSelectedForPayment.boDisplayName} success!`,
            '',
            'success',
          )
          onCloseModalEdit()
          loadData()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handleCancelEditPayout = () => {
    setShowModalPayoutMethod(false)
  }
  const handleSubmitEditPayout = () => {
    // Todo: update payout method

    setShowModalPayoutMethod(false)
  }

  const handleEditPayment = (_pack: any) => {
    setPackageSelectedForPayment(_pack)
    setPackageSelected(_pack)
    setShowFormLimitEdit(_pack)
    setShowModalPaymentMethod(true)

    // TODO: SEARCH PAYMENTS METHOD
    if (_pack) searchPaymentMethods(_pack.id, _pack.paymentEntityType)
  }
  const handleEditPayout = (_pack: any) => {
    setPackageSelectedForPayout(_pack)
    setPackageSelected(_pack)
    setShowFormLimitEdit(_pack)
    setShowModalPayoutMethod(true)
  }

  const searchPaymentMethods = async (entityId: string, entityType: string) => {
    // Todo: call api load PAYMENT methods
    setCurrentPaymentForPackage([])
    setLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setLoading(data)
    })
    const bodyParams: I2SearchPaymentMethod = {
      entity: {
        entityId: entityId,
        entityType: entityType,
        isIncludeUnsetItems: false,
      },
    }
    const query = await axiosClient().post(
      END_POINTS.PAYMENT_PACKAGE.SEARCH_PAYMENT_METHOD(),
      bodyParams,
    )

    if (query.data) {
      setCurrentPaymentForPackage(query.data)
    }
  }

  return (
    <>
      <div className="container-fluid ">
        <div className="row align-items-center page-title">
          <div className="col-12 col-md mb-2 mb-sm-0">
            <h3 className="mb-0">Manage Packages</h3>
          </div>
        </div>
        <hr className="p-0 m-0 mb-4" />
      </div>

      <div className="container-fluid">
        <ul className="nav nav-tabs justify-content-center nav-WinDOORS nav-lg mb-4 mt-4">
          {MENU_MANAGEMENT_PACKAGE.map((item) => (
            <li className="nav-item" key={item.value}>
              <Link
                className={`nav-link ${menuActive === item.value ? 'active' : ''}`}
                to={`/${item.link}`}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
        <div className="d-flex align-items-end justify-content-end mt-3">
          <div className="card border-0 rounded" style={{ minWidth: '100%' }}>
            <div className="card-header rounded d-inline-block">
              <div className="row gx-1 align-items-end justify-content-end">
                <div className="col-md-4 mb-2">
                  <label htmlFor="selectUser" className="lbl mb-1">
                    Search
                  </label>
                  <input
                    className="form-control border"
                    placeholder=" Enter package name"
                    type="text"
                    value={packageName}
                    onChange={(e: any) => {
                      setPackageName(e.target.value)
                    }}
                  />
                </div>
                <div className={`col-md-2`}>
                  <div className="row gx-1">
                    <div className="col-md  mb-2">
                      <label className="lbl mb-1">Status</label>
                      <div className="d-flex align-items-center">
                        <div className="col">
                          <select
                            className="form-select border w-100"
                            value={status}
                            onChange={(event) => {
                              setStatus(event.target.value)
                            }}
                          >
                            <option value={''}>All</option>
                            <option value={'false'}>Active</option>
                            <option value={'true'}>Block</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`col-md-2`}>
                  <div className="row gx-1">
                    <div className="col-md  mb-2">
                      <label className="lbl mb-1">Group</label>
                      <div className="d-flex align-items-center">
                        <div className="col">
                          <select
                            className="form-select border w-100"
                            value={group}
                            onChange={(event) => {
                              setGroup(event.target.value)
                            }}
                          >
                            <option value={''}>All</option>
                            <option value={'ANNUALPLATFORMFEE'}>ANNUAL PLATFORM FEE</option>
                            <option value={'AIBIGDATA'}>AI BIG DATA</option>
                            <option value={'MERCHANTPACKAGE'}>MERCHANT PACKAGE</option>
                            <option value={'PACKAGESERVICE'}>PACKAGE SERVICE</option>
                            <option value={'AIBIGDATATRAVEL'}>AI BIG DATA TRAVEL</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto mb-2">
                  <button
                    className="btn btn-primary btn-md border-0 px-2"
                    onClick={() => {
                      loadData()
                    }}
                  >
                    <small>Search</small>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card border-0 mt-2 rounded">
          <div className="card-header">
            <div className="row">
              <div className="col">
                <h4 className="mb-0">List package</h4>
              </div>
              {isLoading && <Loader />}
              <ListManagePackage
                data={dataTable}
                onClick={onClickPackageDetail}
                onEditPayment={handleEditPayment}
                onEditPayout={handleEditPayout}
              />
            </div>
          </div>
        </div>
        <Modal
          show={showModalEdit}
          centered
          enforceFocus={false}
          onHide={() => setShowModalEdit(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Package details </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading && <Loader />}
            <FormEditPackage
              data={currentPackage}
              onSubmit={(model: any) => {
                handleSubmitUpdatePackage(
                  model,
                  monthlyPurchasedReserveByRanks,
                  monthlyPurchasedPackageLimit,
                  isOn,
                )
              }}
              onClose={() => setShowModalEdit(false)}
              showFormLimitEdit={showFormLimitEdit}
              onLimitSubmit={(
                monthlyPurchasedReserveByRanks,
                monthlyPurchasedPackageLimit,
                isOn,
              ) => {
                setMonthlyPurchasedReserveByRanks(monthlyPurchasedReserveByRanks)
                setMonthlyPurchasedPackageLimit(monthlyPurchasedPackageLimit)
                setIsOn(isOn)
              }}
            />
          </Modal.Body>
        </Modal>

        {/* modal edit payment methods */}
        <Modal
          show={showModalPaymentMethod}
          onHide={() => setShowModalPaymentMethod(false)}
          dialogClassName="modal-md"
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit payment methods for the {currentPackage.boName} package </Modal.Title>
          </Modal.Header>

          <EditPackagePayment
            paymentMethods={paymentMethods}
            onCancel={handleCancelEditPayment}
            onSubmit={handleSubmitEditPayment}
            selectedPackage={packageSelectedForPayment}
            currentPaymentForPackage={currentPaymentForPackage}
            isLoading={isLoading}
          />
        </Modal>
        {/* modal payout method */}
        <Modal
          show={showModalPayoutMethod}
          onHide={() => setShowModalPayoutMethod(false)}
          dialogClassName="modal-md"
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit payout methods for the {currentPackage.boName} package</Modal.Title>
          </Modal.Header>
          <EditPackagePayout
            payoutMethods={payoutMethods}
            onCancel={handleCancelEditPayout}
            onSubmit={handleSubmitEditPayout}
            currentPackage={packageSelectedForPayment}
            currentPayoutForPackage={currentPayoutForPackage}
          />
        </Modal>
        <div className="pt-2"></div>
      </div>
    </>
  )
}

export default PackagesSetting
