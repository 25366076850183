import { texasDate, texasDateFormat } from 'helpers/date-time-helper'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import Swal from 'sweetalert2'

function MaintainanceSetting() {
  const [isLoading, setIsLoading] = useState(false)
  const [startDateTime, setStartDateTime] = useState<Date | null>(new Date())
  const [endDateTime, setEndDateTime] = useState<Date | null>(new Date())
  const [enabled, setEnabled] = useState<boolean>(false)
  const [maintenance, setMaintenance] = useState<any>([])

  const loadData = useCallback(async () => {
    setIsLoading(true)
    const query: any = await axiosClient().get(END_POINTS.APP_CONFIG.MAINTENANCE())
    setIsLoading(false)
    setStartDateTime(new Date(query.data.startTime))
    setEndDateTime(new Date(query.data.endTime))
    setEnabled(query.data.enabled)
  }, [])
  const isFormValid = startDateTime && endDateTime && startDateTime <= endDateTime
  useEffect(() => {
    loadData()
  }, [loadData])
  const onSubmitConfirm = async () => {
    Swal.fire({
      title: 'Update',
      text: 'Are you sure you want to update maintenance mode?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Update successfully!', '', 'success')
      }
    })
    setIsLoading(true)
    await axiosClient().put(END_POINTS.APP_CONFIG.MAINTENANCE(), {
      enabled,
      startTime: texasDate(startDateTime),
      endTime: texasDate(endDateTime),
    })
    setIsLoading(false)
  }
  const texasTime = texasDateFormat()
  const mintexasTimeString = new Date(texasTime)

  return (
    <>
      <div className="container-fluid">
        <div className="card border-0 mt-3 rounded row">
          <div className="position-relative">
            {isLoading && (
              <div className="loading-data">
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only"> </span>
                </div>
              </div>
            )}
            <form>
              <div className="col-12 col-lg-5 col-xl-4 pb-5">
                <h6 className="title">Maintenance settings</h6>
                <div className="mb-2">
                  <div className="form-group mb-3 position-relative check-valid">
                    <label>Start date</label>
                    <div className="input-group input-group-lg border py-2">
                      <DatePicker
                        className="form-control"
                        id="exampleInputEmail1"
                        selected={startDateTime}
                        onChange={(date) => setStartDateTime(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={mintexasTimeString}
                      />
                    </div>
                  </div>
                  <div className="invalid-feedback mb-3">Add valid data </div>
                </div>

                <div className="mb-2">
                  <div className="form-group mb-3 position-relative check-valid">
                    <label>End date</label>
                    <div className="input-group input-group-lg border py-2">
                      <DatePicker
                        className="form-control"
                        id="exampleInputEmail1"
                        selected={endDateTime}
                        onChange={(date) => setEndDateTime(date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={mintexasTimeString}
                      />
                    </div>
                  </div>
                  <div className="invalid-feedback mb-3">Add valid data </div>
                </div>

                <div className="form-check form-switch mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="savecard1"
                    checked={enabled}
                    onChange={(event) => setEnabled(event.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="savecard1">
                    Enable maintenance mode
                  </label>
                </div>
                <button
                  type="button"
                  onClick={onSubmitConfirm}
                  disabled={!isFormValid}
                  className="btn btn-theme"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="py-2"></div>
      </div>
    </>
  )
}
export default MaintainanceSetting
