import {
  formatDate,
  formatInterest,
  formatRate,
  formatStableCoinUSD,
} from 'components/commons-function'
import { SavingTermStatus } from 'helpers/constants'
import { SavingTerm } from 'models'
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
interface TypeProps {
  data: Array<SavingTerm> | undefined
  onEdit: Function
  onDelete: Function
  onCopy: Function
}
interface DataType extends SavingTerm {
  isChecked: boolean
  isEven: boolean
}
const lightColors = ['#FFBFCB', '#FFFFE1', '#E0FFFF']
function ListSavingTerm(props: TypeProps) {
  const { data, onEdit, onDelete, onCopy } = props
  const [list, setList] = useState<Array<DataType>>([])
  useEffect(() => {
    setList([])
    if (data && data.length > 0) {
      const monthsArray = data.map((item: any) => item.months)
      const uniqueSet = new Set(monthsArray)
      const uniqueArray = Array.from(uniqueSet)
      const mydata = data?.map((item) => {
        const index = uniqueArray.findIndex((number: any) => number === item.months)
        return { isEven: index % 2 === 0, isChecked: false, ...item } as DataType
      })

      setList(mydata ?? [])
    }
  }, [data])

  const hanldeDelete = (id: string) => {
    swal({
      title: 'Are you sure you want to delete this Saving Term?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((isConfirm) => {
      if (isConfirm) {
        onDelete(id)
      }
    })
  }
  const handleCopy = (item: any) => {
    onCopy(item)
  }
  return (
    <>
      {data && (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr className="text-muted">
                <th>Name</th>
                <th>Currency</th>
                <th className="text-end">
                  Duration <small>(Month)</small>
                </th>
                <th className="text-end">Interest</th>
                <th className="text-end">Condition</th>
                <th>Interest Payment Cycle</th>
                <th>Start date</th>
                <th>End date</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index, arr) => {
                const isFirstOfMonth = index === 0 || item.months !== arr[index - 1].months
                return (
                  <tr
                    key={item.id}
                    className={`row-of-tbl${item.isChecked ? '-selected' : ''}`}
                    style={{
                      backgroundColor: item.isEven ? '#F0FFFF' : '#FFFFF0',
                    }}
                  >
                    <td>
                      <b>{item.name}</b>
                    </td>
                    <td>{item.currencyUnit}</td>
                    {isFirstOfMonth && (
                      <td
                        rowSpan={arr.filter((x) => x.months === item.months).length}
                        style={{ verticalAlign: 'middle', textAlign: 'center' }}
                      >
                        {item.months}
                      </td>
                    )}

                    <td className="text-end">{formatInterest(item.annualInterestRate * 100)}%</td>
                    <td className="text-end">
                      <span className=" ">
                        {item.fromAmount > 0 && (
                          <>
                            From <b>{formatStableCoinUSD(item.fromAmount)}</b>
                          </>
                        )}
                      </span>
                      <div>
                        <span className=" ">
                          {item.toAmount && item.toAmount > 0 && item.fromAmount ? (
                            <>
                              To under <b>{formatStableCoinUSD(item.toAmount)}</b>
                            </>
                          ) : (
                            ' and above'
                          )}
                        </span>
                      </div>
                    </td>
                    <td>{item.interestPeriodType}</td>
                    {/* <td className="text-end">{item.maxInterestPeriods}</td> */}
                    <td>
                      <b>{item?.startDate ? formatDate(item?.startDate) : ''}</b>
                    </td>
                    <td>
                      <b>{item?.endDate ? formatDate(item?.endDate) : ''}</b>
                    </td>
                    <td>
                      {item.status && (
                        <span
                          className={`badge badge-sm ${
                            item.status === SavingTermStatus.Active
                              ? 'bg-success'
                              : item.status === SavingTermStatus.Inactive
                              ? 'bg-secondary'
                              : 'bg-warning'
                          }`}
                        >
                          {item.status}
                        </span>
                      )}
                    </td>
                    <td>
                      <div className="row gx-2 gy-2">
                        <div className="col-auto">
                          <button
                            className=" btn btn-primary btn-sm py-0"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Update"
                            onClick={() => onEdit(item)}
                          >
                            <i className="bi bi-pencil"></i>
                          </button>
                        </div>
                        <div className="col-auto">
                          <button
                            className="btn btn-warning btn-sm py-0 text-white"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Copyto new item"
                            onClick={() => handleCopy(item)}
                          >
                            <i className="bi bi-journals"></i>
                          </button>
                        </div>

                        <div className="col-auto">
                          <button
                            className="btn btn-danger btn-sm py-0 text-white"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete"
                            onClick={() => hanldeDelete(item.id)}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ListSavingTerm
