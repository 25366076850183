import { FORMAT_DATE_API } from 'helpers/constants'
import { PaymentMethodName } from 'helpers/enums'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import moment from 'moment'
import { format } from 'date-fns'
export function formatDate(date: Date | string) {
  return moment(date).format('MMM Do, YYYY')
}
export function formatDateTime(date: Date | string) {
  return moment(date).format('MMM Do, YYYY hh:mm a')
}
export function convertDateToISO(date: Date | null) {
  return date ? format(date, FORMAT_DATE_API) : null
}
export function formatAmount(amount: number) {
  if (!amount) amount = 0
  return `$${amount ? amount : 0}`
}
export function formatCurrency(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export function formatCoin(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
export function formatTxiD(id: string) {
  return id.split('-')[0]
}

export function formatRate(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
  })
}
export function formatInterest(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
export function formatStableCoin(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function formatStableCoinUSD(amount: number) {
  if (!amount) amount = 0
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
export function formatCoinWithKMB(num: number, precision = 2) {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ]

  const found = map.find((x) => Math.abs(num) >= x.threshold)
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix
    return formatted
  }
  return num
}

export function validateEmail(inputText: string) {
  var mailformat =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
  if (inputText.match(mailformat)) {
    return true
  } else {
    return false
  }
}
export function validatePassword(inputtxt: string) {
  var passw = /^.{6,}$/
  if (inputtxt.match(passw)) {
    return true
  } else {
    return false
  }
}

export function checkConfirmPassword(passStringConnfirm: string, passString: string) {
  if (validatePassword(passStringConnfirm) && passStringConnfirm === passString) {
    return true
  } else {
    return false
  }
}
export function validateName(inputtxt: string) {
  var myformat = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{0,}$/
  if (inputtxt.match(myformat)) {
    return true
  } else {
    return false
  }
}

export async function checkUserName(inputtxt: string) {
  const queryCheckUsernameExist = await axiosClient().get(
    END_POINTS.USER_API_END_POINTS.IS_EXIST_USERNAME(inputtxt),
  )
  if (queryCheckUsernameExist.data === true) {
    return false
  }
  return true
}

export function loadImgRank(rankName: string | null) {
  const urlImgRank = '/assets/img/ranks/'
  const imgName = rankName?.replaceAll(' ', '_').toLowerCase()
  if (rankName) return urlImgRank + imgName + '.png'
  else return urlImgRank + 'beginner.png'
}

export function loadImgPack(packname: string) {
  const urlImgRank = '/assets/img/packs/'
  return urlImgRank + packname + '.png'
}

export function loadImgWallet(name: string) {
  const urlImg = '/assets/img/wallets/'
  const imgname = name.split('_')[0]
  if (imgname) return urlImg + imgname.toLowerCase() + '.png'
}
export function hideEmail(email: string) {
  return email.replace(/(\w{4}).*?@/, '$1***@')
}
export function hideString(text: string) {
  if (text) {
    const l = text.length
    const count = l > 3 ? 4 : l
    return '***' + text.substr(text.length - count, count)
  }
  return ''
}
export function validateTime(time: string) {
  if (time) {
    const timeReg = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/
    return time.match(timeReg)
  }
}
export function validateTimeStart(time: string) {
  if (time) {
    const timeReg = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
    return time.match(timeReg)
  }
}

export function getTimeForInput(strDateTime: string) {
  if (strDateTime) {
    const myH =
      new Date(strDateTime).getHours() > 9
        ? new Date(strDateTime).getHours()
        : `0${new Date(strDateTime).getHours()}`
    const myM =
      new Date(strDateTime).getMinutes() > 9
        ? new Date(strDateTime).getMinutes()
        : `0${new Date(strDateTime).getMinutes()}`

    return `${myH}:${myM}`
  }
  return null
}

export function validateRequiredText(inputText: string) {
  if (inputText && inputText.trim().length > 0) {
    return true
  }
  return false
}
export function getNameLanguage(uniqueSeoCode: string | undefined) {
  switch (uniqueSeoCode) {
    case 'vi':
      return 'Vietnamese'
    case 'ja':
      return 'Japanese'
    default:
      return 'English'
  }
}

export function validateRequiredDateTime(input: string | Date | null) {
  if (input) {
    return true
  }
  return false
}
export function limitTransactionHash(str: string) {
  let length = 20,
    start = 6,
    end = 27
  if (str?.length <= length) return str
  return str ? str.replace(str.substring(start, end), '...') : ''
}

export function formatDateParam(date: Date | string) {
  return moment(date).format('YYYY-MM-DD')
}

export function tokenNameFromTokenWallet(wallet: any) {
  switch (wallet) {
    case PaymentMethodName.VmmWallet: {
      return 'VMM'
    }
    case PaymentMethodName.VlgWallet: {
      return 'VLG'
    }
    case PaymentMethodName.VpcWallet: {
      return 'VPC'
    }
    case PaymentMethodName.VpcBonusWallet: {
      return 'VPC Bonus'
    }
    case PaymentMethodName.UsdWallet: {
      return 'USD'
    }
    case PaymentMethodName.VusdWallet: {
      return 'VUSD'
    }
    case PaymentMethodName.UsdtWallet: {
      return 'USDT'
    }
    case PaymentMethodName.VndWallet: {
      return 'VND'
    }
    case PaymentMethodName.VlgStalkingWallet: {
      return 'VLG Stalking'
    }
  }

  return wallet
}
export function formatTimeSpan(timeSpan: string) {
  if (!timeSpan) return ''
  const time = moment(timeSpan, 'HH:mm:ss')
  return time.format('HH:mm:ss')
}
