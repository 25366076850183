import DateRange from 'components/daterange'
import { SavingTermDetail, SavingTermDetailUpdate } from 'models'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import moment from 'moment-timezone'
import Decimal from 'decimal.js'
import {
  CurrencyUnit,
  FORMAT_DATE_PARAMS,
  SavingInterestPeriodType,
  SavingTermStatus,
} from 'helpers/constants'
import OpenAlert from 'components/sweetAlert'

interface TypeProps {
  onSave: Function
  onCancel: Function
  initialData?: SavingTermDetail | undefined
}
const currentDateTexas = moment().tz('America/Chicago').format('YYYY-MM-DD')
const futureDateTexas = moment().tz('America/Chicago').clone().add(30, 'days').format('YYYY-MM-DD')
const SavingTermForm = (props: TypeProps) => {
  const { onSave, initialData, onCancel } = props
  let annualInterestRate = new Decimal(0)
  if (initialData?.annualInterestRate) {
    const num1 = new Decimal(initialData?.annualInterestRate)
    const num2 = new Decimal(100)
    annualInterestRate = num1.times(num2)
  }

  const [formData, setFormData] = useState({
    name: initialData?.localeName?.en || '',
    name_vi: initialData?.localeName?.vi || '',
    name_ja: initialData?.localeName?.ja || '',
    months: initialData?.months || 1,
    currencyUnit: initialData?.currencyUnit || CurrencyUnit.Vmm,
    status: initialData?.status || SavingTermStatus.Active,
    startDate: initialData?.startDate
      ? new Date(initialData.startDate)
      : new Date(currentDateTexas),
    endDate: initialData?.endDate ? new Date(initialData?.endDate) : null,
    fromAmount: initialData?.fromAmount || 0,
    toAmount: initialData?.toAmount || '',
    interestPeriodType: initialData?.interestPeriodType || '',
    annualInterestRate: annualInterestRate || 0,
    earlyWithdrawalRate: initialData?.earlyWithdrawalRate || 0,
  })

  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    const num1 = new Decimal(formData.annualInterestRate)
    const num2 = new Decimal(100)
    const annualInterestRate = num1.dividedBy(num2)

    if (annualInterestRate.lessThan(0)) {
      OpenAlert('Annual Interest Rate is not valid!', '', 'warning')
      return
    }
    if (
      (formData.toAmount !== '' &&
        parseFloat(formData.toAmount.toString()) <= formData.fromAmount) ||
      (formData.toAmount !== '' && parseFloat(formData.toAmount.toString()) < 0)
    ) {
      OpenAlert('To USD Amount is not valid!', '', 'warning')
      return
    }
    if (!formData.interestPeriodType) {
      OpenAlert('Please select interest period type!', '', 'warning')
      return
    }
    if (!formData.name || !formData.name_ja || !formData.name_vi) {
      OpenAlert('Please enter name of saving term!', '', 'warning')
      return
    }

    const params: SavingTermDetailUpdate = {
      months: formData.months,
      currencyUnit: formData.currencyUnit || CurrencyUnit.Vmm,
      startDate: formData.startDate ? moment(formData.startDate).format(FORMAT_DATE_PARAMS) : null,
      endDate: formData.endDate ? moment(formData.endDate).format(FORMAT_DATE_PARAMS) : null,
      fromAmount: formData.fromAmount,
      toAmount: parseFloat(formData.toAmount.toString()),
      interestPeriodType: formData.interestPeriodType,
      annualInterestRate: annualInterestRate,
      earlyWithdrawalRate: formData.earlyWithdrawalRate,
      status: formData.status,
      localeName: {
        en: formData.name,
        vi: formData.name_vi,
        ja: formData.name_ja,
      },
    }
    onSave(params)
  }
  const handleCancel = () => {
    onCancel()
  }

  return (
    <>
      <form className="myfrm" onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="mb-3">
            <label className="mb-1">
              Name <span className="text-danger">*</span>
            </label>
            <div className="border mb-3 rounded">
              <div className="row align-items-center gx-0">
                <div className="col-auto">
                  <span className="input-group-addon px-1 bg-white mx-1 mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5692 5.99001C12.4639 5.99001 13.1892 5.2647 13.1892 4.37C13.1892 3.47529 12.4639 2.75 11.5692 2.75C10.6745 2.75 9.94922 3.47529 9.94922 4.37C9.94922 5.2647 10.6745 5.99001 11.5692 5.99001Z"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.13966 12.0819C4.55966 11.3919 5.12965 10.8018 5.84965 10.3618C6.61965 9.89185 7.49964 9.63185 8.39964 9.49185C9.85964 9.28185 11.3296 9.33185 12.7996 9.39185C12.8696 9.39185 12.9296 9.40184 12.9996 9.40184C13.3796 9.45184 13.6896 9.37185 14.0296 9.14185C15.0296 8.48185 16.1697 8.24185 17.3897 8.30185C17.6597 8.31185 17.8297 8.40184 17.9097 8.54184C17.9997 8.72184 17.8896 8.94185 17.8396 9.05185C17.6396 9.47185 17.3997 10.0518 17.0997 10.8418C17.2497 11.0518 17.4597 11.3318 17.7297 11.6518C17.8297 11.7718 18.2196 12.2318 18.5596 12.5418C18.8796 12.8318 19.5197 13.3319 20.6297 13.6919C20.6197 14.6519 20.6097 15.6018 20.5997 16.5618C20.3497 16.6218 19.9796 16.7418 19.5896 16.9818C18.8096 17.4718 18.6596 18.0418 18.0596 18.5618C17.7196 18.8618 17.1496 19.2119 16.2196 19.3519C16.0496 19.5419 15.8296 19.8219 15.6496 20.1919C15.4596 20.6019 15.3796 20.9619 15.3396 21.2219C14.4596 21.2219 13.5896 21.2219 12.7096 21.2219C12.6296 21.0819 12.5496 20.9319 12.4696 20.7719C12.3996 20.6319 12.3296 20.4919 12.2796 20.3519C11.2596 20.3519 10.2396 20.3519 9.21965 20.3519C9.15965 20.4619 9.09965 20.5819 9.02965 20.7119C8.93965 20.8919 8.86965 21.0718 8.80965 21.2318C7.92965 21.2218 7.05964 21.2219 6.17964 21.2119C6.12964 21.1419 6.04966 21.0319 5.97966 20.8819C5.76966 20.4619 5.80964 20.1318 5.70964 19.8718C5.55964 19.5018 5.38965 19.1319 5.12965 18.8219C4.16965 17.6619 3.37963 16.3219 3.35963 14.8319C3.34963 13.9019 3.62966 12.9219 4.13966 12.0819Z"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.92969 12.4883H13.2197"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.9802 13.8387C16.433 13.8387 16.8001 13.4761 16.8001 13.0287C16.8001 12.5814 16.433 12.2188 15.9802 12.2188C15.5273 12.2188 15.1602 12.5814 15.1602 13.0287C15.1602 13.4761 15.5273 13.8387 15.9802 13.8387Z"
                        fill="#2C3EBA"
                      />
                    </svg>
                  </span>
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="18"
                    viewBox="0 0 25 18"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5679_74770)">
                      <path d="M0.570312 0H24.9989V18H0.570312V0Z" fill="#012169" />
                      <path
                        d="M3.43304 0L12.7464 6.7875L22.0217 0H24.9989V2.325L15.8382 9.0375L24.9989 15.7125V18H21.9453L12.7846 11.2875L3.66205 18H0.570312V15.75L9.69286 9.075L0.570312 2.4V0H3.43304Z"
                        fill="white"
                      />
                      <path
                        d="M16.7542 10.5375L24.9989 16.5V18L14.6549 10.5375H16.7542ZM9.73103 11.2875L9.96004 12.6L2.63147 18H0.570312L9.73103 11.2875ZM24.9989 0V0.1125L15.4946 7.1625L15.571 5.5125L23.0904 0H24.9989ZM0.570312 0L9.69286 6.6H7.40268L0.570312 1.575V0Z"
                        fill="#C8102E"
                      />
                      <path
                        d="M9.7692 0V18H15.8763V0H9.7692ZM0.570312 6V12H24.9989V6H0.570312Z"
                        fill="white"
                      />
                      <path
                        d="M0.570312 7.2375V10.8375H24.9989V7.2375H0.570312ZM10.9906 0V18H14.6549V0H10.9906Z"
                        fill="#C8102E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5679_74770">
                        <rect
                          width="24.4286"
                          height="18"
                          fill="white"
                          transform="translate(0.570312)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className="border mb-3 rounded">
              <div className="row align-items-center gx-0">
                <div className="col-auto">
                  <span className="input-group-addon px-1 bg-white mx-1 mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5692 5.99001C12.4639 5.99001 13.1892 5.2647 13.1892 4.37C13.1892 3.47529 12.4639 2.75 11.5692 2.75C10.6745 2.75 9.94922 3.47529 9.94922 4.37C9.94922 5.2647 10.6745 5.99001 11.5692 5.99001Z"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.13966 12.0819C4.55966 11.3919 5.12965 10.8018 5.84965 10.3618C6.61965 9.89185 7.49964 9.63185 8.39964 9.49185C9.85964 9.28185 11.3296 9.33185 12.7996 9.39185C12.8696 9.39185 12.9296 9.40184 12.9996 9.40184C13.3796 9.45184 13.6896 9.37185 14.0296 9.14185C15.0296 8.48185 16.1697 8.24185 17.3897 8.30185C17.6597 8.31185 17.8297 8.40184 17.9097 8.54184C17.9997 8.72184 17.8896 8.94185 17.8396 9.05185C17.6396 9.47185 17.3997 10.0518 17.0997 10.8418C17.2497 11.0518 17.4597 11.3318 17.7297 11.6518C17.8297 11.7718 18.2196 12.2318 18.5596 12.5418C18.8796 12.8318 19.5197 13.3319 20.6297 13.6919C20.6197 14.6519 20.6097 15.6018 20.5997 16.5618C20.3497 16.6218 19.9796 16.7418 19.5896 16.9818C18.8096 17.4718 18.6596 18.0418 18.0596 18.5618C17.7196 18.8618 17.1496 19.2119 16.2196 19.3519C16.0496 19.5419 15.8296 19.8219 15.6496 20.1919C15.4596 20.6019 15.3796 20.9619 15.3396 21.2219C14.4596 21.2219 13.5896 21.2219 12.7096 21.2219C12.6296 21.0819 12.5496 20.9319 12.4696 20.7719C12.3996 20.6319 12.3296 20.4919 12.2796 20.3519C11.2596 20.3519 10.2396 20.3519 9.21965 20.3519C9.15965 20.4619 9.09965 20.5819 9.02965 20.7119C8.93965 20.8919 8.86965 21.0718 8.80965 21.2318C7.92965 21.2218 7.05964 21.2219 6.17964 21.2119C6.12964 21.1419 6.04966 21.0319 5.97966 20.8819C5.76966 20.4619 5.80964 20.1318 5.70964 19.8718C5.55964 19.5018 5.38965 19.1319 5.12965 18.8219C4.16965 17.6619 3.37963 16.3219 3.35963 14.8319C3.34963 13.9019 3.62966 12.9219 4.13966 12.0819Z"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.92969 12.4883H13.2197"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.9802 13.8387C16.433 13.8387 16.8001 13.4761 16.8001 13.0287C16.8001 12.5814 16.433 12.2188 15.9802 12.2188C15.5273 12.2188 15.1602 12.5814 15.1602 13.0287C15.1602 13.4761 15.5273 13.8387 15.9802 13.8387Z"
                        fill="#2C3EBA"
                      />
                    </svg>
                  </span>
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    type="text"
                    name="name_vi"
                    value={formData.name_vi}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="18"
                    viewBox="0 0 24 18"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5679_74797)">
                      <mask
                        id="mask0_5679_74797"
                        style={{ maskType: 'luminance' }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="18"
                      >
                        <path d="M0 0H23.9977V18H0V0Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_5679_74797)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M-1.5 0H25.5V18H-1.5V0Z"
                          fill="#EC0015"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2906 13.3941L12.1406 11.0492L9.01172 13.4152L10.1719 9.56211L7.04297 7.18203L10.9137 7.14688L12.116 3.30078L13.3359 7.13633L17.2066 7.13984L14.0953 9.54453L15.2871 13.3977L15.2906 13.3941Z"
                          fill="#FFFF00"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_5679_74797">
                        <rect width="24" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className="border mb-3 rounded">
              <div className="row align-items-center gx-0">
                <div className="col-auto">
                  <span className="input-group-addon px-1 bg-white mx-1 mt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M11.5692 5.99001C12.4639 5.99001 13.1892 5.2647 13.1892 4.37C13.1892 3.47529 12.4639 2.75 11.5692 2.75C10.6745 2.75 9.94922 3.47529 9.94922 4.37C9.94922 5.2647 10.6745 5.99001 11.5692 5.99001Z"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.13966 12.0819C4.55966 11.3919 5.12965 10.8018 5.84965 10.3618C6.61965 9.89185 7.49964 9.63185 8.39964 9.49185C9.85964 9.28185 11.3296 9.33185 12.7996 9.39185C12.8696 9.39185 12.9296 9.40184 12.9996 9.40184C13.3796 9.45184 13.6896 9.37185 14.0296 9.14185C15.0296 8.48185 16.1697 8.24185 17.3897 8.30185C17.6597 8.31185 17.8297 8.40184 17.9097 8.54184C17.9997 8.72184 17.8896 8.94185 17.8396 9.05185C17.6396 9.47185 17.3997 10.0518 17.0997 10.8418C17.2497 11.0518 17.4597 11.3318 17.7297 11.6518C17.8297 11.7718 18.2196 12.2318 18.5596 12.5418C18.8796 12.8318 19.5197 13.3319 20.6297 13.6919C20.6197 14.6519 20.6097 15.6018 20.5997 16.5618C20.3497 16.6218 19.9796 16.7418 19.5896 16.9818C18.8096 17.4718 18.6596 18.0418 18.0596 18.5618C17.7196 18.8618 17.1496 19.2119 16.2196 19.3519C16.0496 19.5419 15.8296 19.8219 15.6496 20.1919C15.4596 20.6019 15.3796 20.9619 15.3396 21.2219C14.4596 21.2219 13.5896 21.2219 12.7096 21.2219C12.6296 21.0819 12.5496 20.9319 12.4696 20.7719C12.3996 20.6319 12.3296 20.4919 12.2796 20.3519C11.2596 20.3519 10.2396 20.3519 9.21965 20.3519C9.15965 20.4619 9.09965 20.5819 9.02965 20.7119C8.93965 20.8919 8.86965 21.0718 8.80965 21.2318C7.92965 21.2218 7.05964 21.2219 6.17964 21.2119C6.12964 21.1419 6.04966 21.0319 5.97966 20.8819C5.76966 20.4619 5.80964 20.1318 5.70964 19.8718C5.55964 19.5018 5.38965 19.1319 5.12965 18.8219C4.16965 17.6619 3.37963 16.3219 3.35963 14.8319C3.34963 13.9019 3.62966 12.9219 4.13966 12.0819Z"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.92969 12.4883H13.2197"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.9802 13.8387C16.433 13.8387 16.8001 13.4761 16.8001 13.0287C16.8001 12.5814 16.433 12.2188 15.9802 12.2188C15.5273 12.2188 15.1602 12.5814 15.1602 13.0287C15.1602 13.4761 15.5273 13.8387 15.9802 13.8387Z"
                        fill="#2C3EBA"
                      />
                    </svg>
                  </span>
                </div>
                <div className="col">
                  <input
                    className="form-control"
                    type="text"
                    name="name_ja"
                    value={formData.name_ja}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29"
                    height="20"
                    viewBox="0 0 29 20"
                    fill="none"
                  >
                    <path
                      d="M1 0.5H0.5V1V19V19.5H1H28H28.5V19V1V0.5H28H1Z"
                      fill="white"
                      stroke="#E1E2E6"
                    />
                    <path
                      d="M14.5014 15.5965C17.5915 15.5965 20.0965 13.0915 20.0965 10.0014C20.0965 6.91128 17.5915 4.40625 14.5014 4.40625C11.4113 4.40625 8.90625 6.91128 8.90625 10.0014C8.90625 13.0915 11.4113 15.5965 14.5014 15.5965Z"
                      fill="#D30000"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-1">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="mb-1">
                  Duration(Months) <span className="text-danger">*</span>
                </label>
                <div className="border mb-3 rounded">
                  <div className="row align-items-center gx-0">
                    <div className="col-auto">
                      <span className="px-1 bg-white mx-1 input-group-addon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3346 10.0013C18.3346 14.6013 14.6013 18.3346 10.0013 18.3346C5.4013 18.3346 1.66797 14.6013 1.66797 10.0013C1.66797 5.4013 5.4013 1.66797 10.0013 1.66797C14.6013 1.66797 18.3346 5.4013 18.3346 10.0013Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.0914 12.6495L10.5081 11.1078C10.0581 10.8411 9.69141 10.1995 9.69141 9.67448V6.25781"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className="form-control "
                        type="number"
                        name="months"
                        value={formData.months}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="mb-1">
                  Currency Unit <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control border"
                  name="currencyUnit"
                  value={formData.currencyUnit}
                  onChange={handleInputChange}
                >
                  {Object.values(CurrencyUnit).map((unit) => (
                    <option key={unit} value={unit}>
                      {unit}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="mb-1">
                  From VMM Amount <span className="text-danger">*</span>
                </label>
                <div className="border mb-3 rounded">
                  <div className="row align-items-center gx-0">
                    <div className="col-auto">
                      <span className="px-1 bg-white mx-1 input-group-addon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.082 14.082C16.332 11.832 16.332 8.08203 14.082 5.83203"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.91406 5.91797C3.66406 8.16797 3.66406 11.918 5.91406 14.168"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.08203 11.332C8.08203 12.082 8.66536 12.6654 9.41536 12.6654H10.832C11.4154 12.6654 11.9987 12.1654 11.9987 11.4987C11.9987 10.7487 11.6654 10.4987 11.2487 10.332L8.91536 9.4987C8.33203 9.41536 8.08203 9.16536 8.08203 8.4987C8.08203 7.83203 8.58203 7.33203 9.2487 7.33203H10.7487C11.4987 7.33203 12.082 7.91536 12.082 8.66536"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 6.5V13.5"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.0013 18.3346C14.6037 18.3346 18.3346 14.6037 18.3346 10.0013C18.3346 5.39893 14.6037 1.66797 10.0013 1.66797C5.39893 1.66797 1.66797 5.39893 1.66797 10.0013C1.66797 14.6037 5.39893 18.3346 10.0013 18.3346Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className="form-control  "
                        type="number"
                        name="fromAmount"
                        value={formData.fromAmount?.toString()}
                        onChange={handleInputChange}
                        min="0"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="mb-1">To VMM Amount</label>

                <div className="border mb-3 rounded">
                  <div className="row align-items-center gx-0">
                    <div className="col-auto">
                      <span className="px-1 bg-white mx-1 input-group-addon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M14.082 14.082C16.332 11.832 16.332 8.08203 14.082 5.83203"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.91406 5.91797C3.66406 8.16797 3.66406 11.918 5.91406 14.168"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.08203 11.332C8.08203 12.082 8.66536 12.6654 9.41536 12.6654H10.832C11.4154 12.6654 11.9987 12.1654 11.9987 11.4987C11.9987 10.7487 11.6654 10.4987 11.2487 10.332L8.91536 9.4987C8.33203 9.41536 8.08203 9.16536 8.08203 8.4987C8.08203 7.83203 8.58203 7.33203 9.2487 7.33203H10.7487C11.4987 7.33203 12.082 7.91536 12.082 8.66536"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10 6.5V13.5"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M10.0013 18.3346C14.6037 18.3346 18.3346 14.6037 18.3346 10.0013C18.3346 5.39893 14.6037 1.66797 10.0013 1.66797C5.39893 1.66797 1.66797 5.39893 1.66797 10.0013C1.66797 14.6037 5.39893 18.3346 10.0013 18.3346Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className="form-control "
                        type="number"
                        name="toAmount"
                        value={formData.toAmount?.toString()}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="mb-3">
                <label className="mb-1">
                  Annual Interest Percent <span className="text-danger">*</span>
                </label>
                <div className="border mb-3 rounded">
                  <div className="row align-items-center gx-0">
                    <div className="col-auto">
                      <span className="px-1 bg-white mx-1 input-group-addon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M11.988 7.98438L7.92969 12.0427"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7.43282 8.48281C8.01272 8.48281 8.4828 8.01271 8.4828 7.43281C8.4828 6.85291 8.01272 6.38281 7.43282 6.38281C6.85292 6.38281 6.38281 6.85291 6.38281 7.43281C6.38281 8.01271 6.85292 8.48281 7.43282 8.48281Z"
                            fill="#2C3EBA"
                          />
                          <path
                            d="M12.5656 13.6156C13.1455 13.6156 13.6156 13.1455 13.6156 12.5656C13.6156 11.9857 13.1455 11.5156 12.5656 11.5156C11.9857 11.5156 11.5156 11.9857 11.5156 12.5656C11.5156 13.1455 11.9857 13.6156 12.5656 13.6156Z"
                            fill="#2C3EBA"
                          />
                          <path
                            d="M10.0013 17.7096C14.2585 17.7096 17.7096 14.2585 17.7096 10.0013C17.7096 5.74411 14.2585 2.29297 10.0013 2.29297C5.74411 2.29297 2.29297 5.74411 2.29297 10.0013C2.29297 14.2585 5.74411 17.7096 10.0013 17.7096Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className="form-control "
                        type="number"
                        name="annualInterestRate"
                        value={formData.annualInterestRate?.toString()}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="mb-1">
                  Interest Payment Cycle <span className="text-danger">*</span>
                </label>

                <div className="border mb-3 rounded">
                  <div className="row align-items-center gx-0">
                    <div className="col-auto">
                      <span className="px-1 bg-white mx-1 input-group-addon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M8.95835 14.0508V15.7425C8.95835 17.1758 7.62501 18.3341 5.98335 18.3341C4.34168 18.3341 3 17.1758 3 15.7425V14.0508C3 15.4841 4.33335 16.5008 5.98335 16.5008C7.62501 16.5008 8.95835 15.4758 8.95835 14.0508Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.95883 11.7591C8.95883 12.1758 8.84216 12.5591 8.64216 12.8924C8.15049 13.7007 7.14215 14.2091 5.97548 14.2091C4.80882 14.2091 3.80047 13.6924 3.30881 12.8924C3.10881 12.5591 2.99219 12.1758 2.99219 11.7591C2.99219 11.0424 3.3255 10.4008 3.85884 9.93409C4.4005 9.45909 5.14214 9.17578 5.96714 9.17578C6.79214 9.17578 7.53383 9.46743 8.0755 9.93409C8.6255 10.3924 8.95883 11.0424 8.95883 11.7591Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.95835 11.7596V14.0513C8.95835 15.4846 7.62501 16.5013 5.98335 16.5013C4.34168 16.5013 3 15.4763 3 14.0513V11.7596C3 10.3263 4.33335 9.16797 5.98335 9.16797C6.80835 9.16797 7.55004 9.45961 8.09171 9.92628C8.62504 10.3929 8.95835 11.043 8.95835 11.7596Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.3329 9.14293V10.8597C18.3329 11.318 17.9662 11.6929 17.4995 11.7096H15.8662C14.9662 11.7096 14.1412 11.0513 14.0662 10.1513C14.0162 9.62629 14.2162 9.13462 14.5662 8.79295C14.8745 8.47628 15.2995 8.29297 15.7662 8.29297H17.4995C17.9662 8.30964 18.3329 8.6846 18.3329 9.14293Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M1.66797 8.7513V7.08464C1.66797 4.81797 3.03464 3.23463 5.15964 2.96797C5.3763 2.93463 5.6013 2.91797 5.83464 2.91797H13.3346C13.5513 2.91797 13.7596 2.92629 13.9596 2.95963C16.1096 3.20963 17.5013 4.8013 17.5013 7.08464V8.29298H15.768C15.3013 8.29298 14.8763 8.47629 14.568 8.79296C14.218 9.13463 14.018 9.6263 14.068 10.1513C14.143 11.0513 14.968 11.7096 15.868 11.7096H17.5013V12.918C17.5013 15.418 15.8346 17.0846 13.3346 17.0846H11.2513"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="col">
                      <select
                        className="form-control "
                        name="interestPeriodType"
                        value={formData.interestPeriodType}
                        onChange={handleInputChange}
                      >
                        <option key={''} value={''} disabled>
                          Select interest payment cycle
                        </option>
                        {Object.values(SavingInterestPeriodType).map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3">
                <label className="mb-1">
                  Status <span className="text-danger">*</span>
                </label>

                <div className="border mb-3 rounded">
                  <div className="row align-items-center gx-0">
                    <div className="col-auto">
                      <span className="px-1 bg-white mx-1 input-group-addon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M15.832 6.66797C17.2127 6.66797 18.332 5.54868 18.332 4.16797C18.332 2.78726 17.2127 1.66797 15.832 1.66797C14.4513 1.66797 13.332 2.78726 13.332 4.16797C13.332 5.54868 14.4513 6.66797 15.832 6.66797Z"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.668 1.66797H7.5013C3.33464 1.66797 1.66797 3.33464 1.66797 7.5013V12.5013C1.66797 16.668 3.33464 18.3346 7.5013 18.3346H12.5013C16.668 18.3346 18.3346 16.668 18.3346 12.5013V8.33464"
                            stroke="#2C3EBA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="col">
                      <select
                        className="form-control  "
                        name="status"
                        value={formData.status}
                        onChange={handleInputChange}
                      >
                        <option key={''} value={''} disabled>
                          Select status
                        </option>
                        {Object.values(SavingTermStatus).map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <DateRange
              lbl1="Start Date"
              lbl2="End Date"
              isClear
              showIcon
              lbl1Require
              dafaultDate={{ dateStart: formData?.startDate, dateEnd: formData?.endDate }}
              onChange={(myDate: any) => {
                setFormData({ ...formData, startDate: myDate.dateStart })
                setFormData({ ...formData, endDate: myDate.dateEnd })
              }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="">
          <div className="row w-100 align-items-center justify-content-center">
            <div className="col-auto">
              <Button
                variant="secondary text-white"
                className="border-0"
                data-toggle="tooltip"
                data-placement="top"
                title={'Cancel'}
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
            <div className="col-auto">
              <Button
                type="submit"
                variant="primary text-white"
                className="border-0"
                data-toggle="tooltip"
                data-placement="top"
                title={'Update'}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </>
  )
}

export default SavingTermForm
