import { MaintenanceSetting } from 'models'
import React, { useCallback, useEffect, useState } from 'react'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import Toast from 'components/toast'
import ListMaintenance from 'components/app-list-maintenace'

function AppMaintenanceSetting() {
  const [dataMaintenace, setDataSwaps] = useState<Array<MaintenanceSetting>>([])
  const [isLoading, setIsLoading] = useState(false)
  const loadData = useCallback(async () => {
    setIsLoading(true)
    const query = await axiosClient().get(END_POINTS.APP_SETTING.GET_LIST_FUNCTION())
    setIsLoading(false)
    if (!query.data) return
    setDataSwaps(query.data)
  }, [])
  useEffect(() => {
    loadData()
  }, [loadData])
  const onUpdate = async (update: any) => {
    console.log(update)
    const res = await axiosClient().put(END_POINTS.APP_SETTING.UPDATE_FUNCTION(update.id), {
      id: update.id,
      page: update.page,
      available: update.available,
    })
    if (res.status === 200 || res.status === 204) {
      loadData()
    }
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center page-title">
          <div className="col-12 col-md mb-2 mb-sm-0">
            <h3 className="mb-0">Maintenance Function</h3>
          </div>
        </div>
        <hr className="p-0 m-0 mb-4" />
      </div>
      <div className="container-fluid">
        <div className="card border-0 mt-3">
          {isLoading && (
            <div className="loading-data">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          <ListMaintenance data={dataMaintenace} onUpdate={onUpdate} />
        </div>
      </div>
      <div className="pt-2"></div>
      <Toast />
    </>
  )
}

export default AppMaintenanceSetting
