import { ROLE } from 'helpers/constants'
import React from 'react'
import { LOGIN_ROUTE } from 'helpers/constants'
import SavingManagement from 'pages/saving-management'
import AppMaintenanceSetting from 'pages/app-maintenance-setting'
import AppCheckVersion from 'pages/app-check-version'
import MaintainanceSetting from 'pages/system-management/maintainance-setting'
import PayoutMethodManagement from 'pages/payout-method-management'
import OverViewPayout from 'pages/components/overview-payout'

const HomePage = React.lazy(() => import('pages/home-page'))
const UserPage = React.lazy(() => import('pages/user'))
const UserLockPage = React.lazy(() => import('pages/user-lock'))
const BonusManagement = React.lazy(() => import('pages/bonus-management'))
const DirectBonus = React.lazy(() => import('pages/direct-bonus'))
const SwapManagement = React.lazy(() => import('pages/swap-management'))
const MembershipPack = React.lazy(() => import('pages/member-pack'))
const NetworkTree = React.lazy(() => import('pages/tree'))
const PageWallets = React.lazy(() => import('pages/wallets'))
const ReceiptManagement = React.lazy(() => import('pages/receipt-management'))
const Statistic = React.lazy(() => import('pages/statistic'))
const Lottery = React.lazy(() => import('pages/lottery'))
const TokenRate = React.lazy(() => import('pages/token-rate'))
const LotteryEvents = React.lazy(() => import('pages/lottery-events'))
const PrizePlan = React.lazy(() => import('pages/ticket-prize-plan'))
const VmmStatistic = React.lazy(() => import('pages/vmm-statistic'))
const StoreManagement = React.lazy(() => import('pages/store-management'))
const AnnouncementSetting = React.lazy(() => import('pages/system-management/announcement-setting'))
const FeeTransfer = React.lazy(() => import('pages/system-management/feetransfer'))
const FunctionsSetting = React.lazy(() => import('pages/system-management/functions-setting'))
const ExternalTransactionsPage = React.lazy(() => import('pages/external-transactions'))
const MembershipReport = React.lazy(() => import('pages/memberships/member-report'))
const TokenStatistic = React.lazy(() => import('pages/token-statistic'))
const PackagesSetting = React.lazy(() => import('pages/system-management/packages-setting'))
const ExternalTransferPage = React.lazy(() => import('pages/external-transfer'))
const PaymentMethodPage = React.lazy(() => import('pages/components/payment-method-page'))
const LoginPage = React.lazy(() => import('pages/login'))

export interface RouteConfigObject {
  path: string
  name?: string
  icon?: string
  title?: string
  exact?: boolean
  component?: any
  useAuthLayout?: boolean
  useAdminLayout?: boolean
  roles?: any
  action?: string
  isShow?: boolean
  children?: RouteConfigObject[]
}
const routes: Array<RouteConfigObject> = [
  {
    path: LOGIN_ROUTE,
    name: 'login',
    component: LoginPage,
    icon: 'bi bi-boxes',
    title: 'Login',
    useAuthLayout: false,
    isShow: false,
    roles: null,
  },
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    icon: 'bi bi-boxes',
    title: 'Dashboard',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR, ROLE.APPROVER],
  },
  {
    path: '/users',
    name: 'UserPage',
    icon: 'bi bi-people',
    component: UserPage,
    title: 'Member management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/users-lock',
    name: 'UserLockPage',
    icon: 'bi bi-people',
    component: UserLockPage,
    title: 'Locked-member management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/saving',
    name: 'SavingManagement',
    icon: 'bi bi-piggy-bank',
    component: SavingManagement,
    title: 'Saving management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/external-transfer',
    name: 'ExternalTransfer',
    icon: 'bi bi-clock',
    component: ExternalTransferPage,
    title: 'External Transfer',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/bonus-management',
    name: 'BonusManagement',
    component: BonusManagement,
    icon: 'bi bi-coin',
    title: 'Bonus management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/direct-bonus',
    name: 'DirectBonus',
    component: DirectBonus,
    icon: 'bi bi-coin',
    title: 'Direct bonus',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/swap-management',
    name: 'SwapManagement',
    component: SwapManagement,
    icon: 'bi bi-coin',
    title: 'Swap management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/network-tree',
    name: 'NetworkTree',
    component: NetworkTree,
    icon: 'bi bi-bezier',
    title: 'Network tree',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: 'memberships',
    name: 'MembershipPack',
    component: MembershipPack,
    icon: 'bi bi-credit-card-2-back',
    title: 'Membership management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    children: [
      {
        path: '/memberships',
        name: 'MembershipPack',
        component: MembershipPack,
        icon: 'bi bi-credit-card-2-back',
        title: 'Membership',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/memberships-report',
        name: 'MembershipReport',
        component: MembershipReport,
        icon: 'bi bi-credit-card-2-back',
        title: 'Membership report',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
    ],
  },
  {
    path: '/wallets',
    name: 'Wallets',
    component: PageWallets,
    icon: 'bi bi-wallet2',
    title: 'Wallets management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/receipts',
    name: 'Receipts',
    component: ReceiptManagement,
    icon: 'bi bi-receipt',
    title: 'Receipt management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR, ROLE.APPROVER],
  },
  {
    path: 'statistic',
    name: 'Statistic',
    component: Statistic,
    icon: 'bi bi-bar-chart',
    title: 'Statistic',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    children: [
      {
        path: '/statistic',
        name: 'Cashflow Statistic',
        component: Statistic,
        icon: 'bi bi-bar-chart',
        title: 'Cashflow statistic',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/vmm-statistic',
        name: 'VMM statistic',
        component: VmmStatistic,
        icon: 'bi bi-bar-chart',
        title: 'VMM Statistic',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      // {
      //   path: '/token-statistic',
      //   name: 'Token statistic',
      //   component: TokenStatistic,
      //   icon: 'bi bi-bar-chart',
      //   title: 'Token statistic',
      //   useAuthLayout: true,
      //   isShow: false,
      //   roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      // },
    ],
  },
  {
    path: '/token-rate',
    name: 'TokenRate',
    component: TokenRate,
    icon: 'bi bi-graph-up',
    title: 'Token rate',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/lucky-tickets',
    name: 'Lottery',
    component: Lottery,
    icon: 'bi bi-ticket-detailed',
    title: 'Lucky units',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/lucky-tickets-event',
    name: 'LotteryEvents',
    component: LotteryEvents,
    icon: 'bi bi-ticket-detailed',
    title: 'Lucky units events',
    useAuthLayout: true,
    isShow: false,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/ticket-prize-plan',
    name: 'PrizePlan',
    component: PrizePlan,
    icon: 'bi bi-ticket-detailed',
    title: 'Prize plan',
    useAuthLayout: true,
    isShow: false,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: '/stores',
    name: 'Store management',
    component: StoreManagement,
    icon: 'bi bi-shop-window',
    title: 'Store management',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: 'announcement-setting',
    name: 'Announcement setting',
    component: AnnouncementSetting,
    icon: 'bi bi-gear',
    title: 'Settings',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    children: [
      {
        path: '/announcement-setting',
        name: 'Announcement setting',
        component: AnnouncementSetting,
        icon: 'bi bi-gear',
        title: 'Announcement setting',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/feetransfer-configuration',
        name: 'FeeTransfer',
        component: FeeTransfer,
        icon: 'bi bi-gear',
        title: 'Fee transfer setting',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/functions-setting',
        name: 'Functions setting',
        component: FunctionsSetting,
        icon: 'bi bi-gear',
        title: 'Functions setting',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/packages-setting',
        name: 'Package setting',
        component: PackagesSetting,
        icon: 'bi bi-gear',
        title: 'Package setting',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/payment-method',
        name: 'Payment method',
        component: PaymentMethodPage,
        icon: 'bi bi-gear',
        title: 'Payment method',
        useAuthLayout: true,
        isShow: false,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/maintainance-setting',
        name: 'Maintainance setting',
        component: MaintainanceSetting,
        icon: 'bi bi-gear',
        title: 'Maintainance setting',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/payout',
        name: 'Payout setting',
        component: PayoutMethodManagement,
        icon: 'bi bi-gear',
        title: 'Payout setting',
        useAuthLayout: true,
        isShow: false,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/overview-payout',
        name: 'Overview Payout',
        component: OverViewPayout,
        icon: 'bi bi-gear',
        title: 'Overview Payout',
        useAuthLayout: true,
        isShow: false,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
    ],
  },
  {
    path: '/external-transactions',
    name: 'External transactions',
    component: ExternalTransactionsPage,
    icon: 'bi bi-wallet',
    title: 'External transactions',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
  },
  {
    path: 'maintenance-setting',
    name: 'Maintenance setting',
    component: AnnouncementSetting,
    icon: 'bi bi-gear',
    title: 'App mobile',
    useAuthLayout: true,
    isShow: true,
    roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
    children: [
      {
        path: '/maintenance-setting',
        name: 'Maintenance setting',
        component: AppMaintenanceSetting,
        icon: 'bi bi-gear',
        title: 'Maintenance setting',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
      {
        path: '/version-app',
        name: 'Update version',
        component: AppCheckVersion,
        icon: 'bi bi-gear',
        title: 'Update version',
        useAuthLayout: true,
        isShow: true,
        roles: [ROLE.SYS_ADMIN_ROLE, ROLE.ADMINISTRATOR],
      },
    ],
  },
]
export { routes }
