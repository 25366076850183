import { PayoutMethod } from 'helpers/constants'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
export const MENU_ITEMS = {
  Type1: 'Payout method 1',
  Type2: 'Payout method 2',
}

export const MENU_ITEMS_VALUE = {
  Type1: 'Type1',
  Type2: 'Type2',
}
export const MENU = [
  {
    label: MENU_ITEMS.Type1,
    value: MENU_ITEMS_VALUE.Type1,
  },
  {
    label: MENU_ITEMS.Type2,
    value: MENU_ITEMS_VALUE.Type2,
  },
]
function EditPackagePayout(props: any) {
  const {
    payoutMethods,
    onSubmit,
    onCancel,
    currentPackage,
    currentPayoutForPackage,
    paymentMethods,
  } = props
  interface ModalPayoutList {
    payoutRandomId: string
    payoutId: string
    isDefault: boolean
    payoutType: string
    selectedPaymentMethods: {
      paymentRandomId: string
      paymentId: string
    }[]
  }
  // todo: load currentPayoutForPackage to state for edit
  const [menuActive, setMenuActive] = useState(MENU_ITEMS_VALUE.Type1)
  const [selectedMethods, setSelectedMethods] = useState<ModalPayoutList[]>([])
  const [selectedTypePayout, setSelectedTypePayout] = useState(PayoutMethod.Type1.toString())
  const handleAddMethod = () => {
    const initNewData = {
      payoutRandomId: uuidv4(),
      payoutId: '',
      isDefault: false,
      payoutType: selectedTypePayout,
      selectedPaymentMethods: [],
    }
    setSelectedMethods([...selectedMethods, initNewData])
  }
  const handleRemoveMethod = (id: string) => {
    const newData = selectedMethods.filter((item) => item.payoutRandomId !== id)
    setSelectedMethods([...newData])
  }

  const updateList = (arr: any[], id: string, name: string, value: any) => {
    return arr.map((item) => {
      if (item.payoutRandomId === id) {
        return {
          ...item,
          [name]: value,
        }
      }
      return item
    })
  }

  const handleChangeMethod = (id: string, e: any) => {
    const { name, value } = e.target
    const updatedList = updateList(selectedMethods, id, name, value)
    setSelectedMethods(updatedList)
  }
  const handleSubmit = () => {
    onSubmit(selectedMethods)
  }
  const handleSetDefault = (id: string) => {
    const name = 'isDefault'
    const newArray = selectedMethods.map((item) => ({
      ...item,
      isDefault: false,
      selectedPaymentMethods: [],
    }))
    const updatedList = updateList(newArray, id, name, true)
    setSelectedMethods(updatedList)
  }
  const handleRemovePaymentMethod = (payoutRandomId: string, paymentRandomId: string) => {
    const updatedSelectedMethods: ModalPayoutList[] = selectedMethods.map((method) => {
      if (method.payoutRandomId === payoutRandomId) {
        return {
          ...method,
          selectedPaymentMethods: method.selectedPaymentMethods.filter(
            (payment) => payment.paymentRandomId !== paymentRandomId,
          ),
        }
      }
      return method
    })

    setSelectedMethods(updatedSelectedMethods)
  }
  const handleAddPaymentMethod = (payoutRandomId: string) => {
    const newPaymentMethod = {
      paymentRandomId: uuidv4(),
      paymentId: '',
    }

    const updatedSelectedMethods: ModalPayoutList[] = selectedMethods.map((method) => {
      if (method.payoutRandomId === payoutRandomId) {
        return {
          ...method,
          selectedPaymentMethods: [...method.selectedPaymentMethods, newPaymentMethod],
        }
      }
      return method
    })

    setSelectedMethods(updatedSelectedMethods)
  }
  const handleChangePaymentMethod = (id: string, e: any) => {}

  return (
    <>
      <Modal.Body>
        <ul className="nav nav-tabs justify-content-center nav-WinDOORS nav-lg mb-4 mt-4">
          {MENU.map((item) => (
            <li className="nav-item" key={item.value}>
              <button
                className={`nav-link ${menuActive === item.value ? 'active' : ''}`}
                onClick={() => {
                  setMenuActive(item.value)
                  if (item.value === MENU_ITEMS_VALUE.Type1)
                    setSelectedTypePayout(PayoutMethod.Type1.toString())
                  else if (item.value === MENU_ITEMS_VALUE.Type2)
                    setSelectedTypePayout(PayoutMethod.Type2.toString())
                }}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>

        {menuActive && (
          <>
            {selectedMethods
              .filter((n) => n.payoutType === selectedTypePayout)
              .map((payoutItem, index) => (
                <>
                  <div className="payout-wallet-item mb-4 ">
                    <button
                      type="button"
                      className="btn btn-primary iconbutton"
                      onClick={() => handleRemoveMethod(payoutItem.payoutRandomId)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>

                    <div className="row gx-1">
                      <div className="col-12">
                        <div className="d-flex gap-2 align-items-center mb-2">
                          <label>
                            Method <span className="text-danger">*</span>
                          </label>
                          {payoutItem.isDefault === true && (
                            <span className="badge rounded-pill bg-success">
                              <i className="bi bi-check"></i> Default
                            </span>
                          )}
                          {payoutItem.isDefault === false && (
                            <button
                              type="button"
                              className="btn btn-outline-primary btn-sm py-0"
                              onClick={() => handleSetDefault(payoutItem.payoutRandomId)}
                            >
                              <small>Set default</small>
                            </button>
                          )}
                        </div>

                        <div className="mb-2 mt-1">
                          <select
                            className="form-select border w-100"
                            value={payoutItem.payoutId}
                            name="payoutId"
                            onChange={(e: any) => handleChangeMethod(payoutItem.payoutRandomId, e)}
                          >
                            <option disabled value={''}>
                              Select a payout method
                            </option>
                            {payoutMethods
                              .filter((x: any) => x.type === PayoutMethod.Type1.toString())
                              ?.map((y: any) => (
                                <option
                                  value={y.id}
                                  key={y.id}
                                  // disabled={}
                                >
                                  {y?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {payoutItem.isDefault === false && (
                          <>
                            <div className="bg-white p-3 mt-3">
                              <p className="fw-bold mb-3 mx-1">Payment methods:</p>
                              {payoutItem.selectedPaymentMethods.map(
                                (paymentItem: any, index: any) => (
                                  <>
                                    <div
                                      className={`payout-wallet-item ${
                                        index > 0 ? 'mt-4' : 'mb-4'
                                      }`}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary iconbutton"
                                        onClick={() =>
                                          handleRemovePaymentMethod(
                                            payoutItem.payoutRandomId,
                                            paymentItem.paymentRandomId,
                                          )
                                        }
                                      >
                                        <i className="bi bi-trash"></i>
                                      </button>

                                      <div className="row gx-1">
                                        <div className="col-12">
                                          <label>
                                            Method <span className="text-danger">*</span>
                                          </label>
                                          <div className="mb-2 mt-1">
                                            <select
                                              className="form-select border w-100"
                                              value={paymentItem.value}
                                              name="paymentId"
                                              onChange={(event: any) =>
                                                handleChangePaymentMethod(
                                                  paymentItem.payoutRandomId,
                                                  event,
                                                )
                                              }
                                            >
                                              <option disabled value={''}>
                                                Select a payment method
                                              </option>
                                              {paymentMethods?.map((item: any) => (
                                                <option
                                                  value={item.id}
                                                  key={item.id}
                                                  // disabled={}
                                                >
                                                  {item?.name}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ),
                              )}
                              <button
                                type="button"
                                className="btn btn-sm btn-link"
                                onClick={() => handleAddPaymentMethod(payoutItem.payoutRandomId)}
                              >
                                <i className="bi bi-plus"></i> Add payment method
                              </button>
                            </div>
                            <p className="mt-2"></p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </>
        )}

        <button type="button" className="btn btn-md btn-link" onClick={handleAddMethod}>
          <i className="bi bi-plus"></i> Add payout method
        </button>
      </Modal.Body>
      <Modal.Footer className="">
        <div className="row w-100 align-items-center justify-content-center">
          <div className="col-auto">
            <Button
              variant="secondary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Cancel'}
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              variant="primary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Update'}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

export default EditPackagePayout
