import { WalletBlackListStatus } from 'models'
import {
  DateRangeFilterType,
  MemberReportFilterStatus,
  PackageSituation,
  PassCodeType,
  WalletType,
  PackageStatus,
  DateType,
} from './enums'

export const TOKEN_ADMIN = 'TOKEN_ADMIN'
export const USER_ID = 'USER_ID'
export const LOGIN_ROUTE = '/login'
export const ROLE = {
  SYS_ADMIN_ROLE: 'System Admin',
  ADMINISTRATOR: 'Administrator',
  APPROVER: 'Approver',
  MEMBER_ROLE: 'Member',
}
export const KEY_EDIT_USER_PROFILE = {
  USERNAME: 'USERNAME',
  STATUS: 'STATUS',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  PIN_STATUS: 'PIN_STATUS',
}
export const USER_ATTRIBUTE = {
  ACTIVE: 'Active',
  DEACTIVE: 'Deactive',
  LOCK: 'Lock',
  UNLOCK: 'Unlock',
}

export const FORMAT_DATE = 'MMM Do, YYYY'
export const FORMAT_DATE_DATEPICKER = 'MMM do, yyyy'
export const FORMAT_DATE_PARAMS = 'YYYY-MM-DD'
export const FORMAT_DATE_TABLE = 'MM/DD/YYYY'
export const FORMAT_DATE_API = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
export const FORMAT_DATE_TIME = 'YYYY-MM-DDTHH:mm:ss'

export const GUID_EMPTY = '00000000-0000-0000-0000-000000000000'

export const BONUSDAILY_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  PAID: 'Paid',
  LEADERSHIPPAID: 'LeadershipPaid',
}

export const ERROR_401_EVENT = 'ERROR_401_EVENT'
export const ERROR_403_EVENT = 'ERROR_403_EVENT'
export const LOADING = 'LOADING'

export const SEARCH_BY_FIELD = {
  USER_USERNAME: 'username',
  USER_EMAIL: 'email',
  USER_PHONE: 'phoneNumber',
  SPONSER_USERNAME: 'SPONSER_USERNAME',
  TRANSACTION_TYPE: 'Type',
  ALL: 'All',
  CATEGORY: 'CATEGORY',
}
export const TYPE_SEARCH = {
  CONTAIN: 'Contains',
  EQUAL: 'Equals',
  STARTWITH: 'StartsWith',
}

export const STYPE_SELECT_REACT = {
  borderColor: '#dee2e6',
  boxShadow: 'none',
  borderRadius: '0.375rem',
  minHeight: '34px',
  height: '34px',
  color: 'black',
}
export const STYPE_SELECT_REACT_WITHOUT_BORDER = {
  border: 0,
  boxShadow: 'none',
  borderRadius: '0.375rem',
  minHeight: '34px',
  height: '34px',
  color: 'black',
}
export const colourStyles = {
  control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles: any, { isFocused, isSelected }: { isFocused: any; isSelected: any }) => {
    return {
      ...styles,
      ...STYPE_SELECT_REACT,
      backgroundColor: isFocused ? '#AADAFA' : 'white',
    }
  },
}
export const colourStylesLg = {
  control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles: any, { isFocused, isSelected }: { isFocused: any; isSelected: any }) => {
    return {
      ...styles,
      ...STYPE_SELECT_REACT,
      backgroundColor: isFocused ? '#AADAFA' : 'white',
      height: 'auto',
    }
  },
}
export const RECEIPT_STATUS = {
  INPROGRESS: 'InProgress',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  DELETED: 'Deleted',
  NEW: 'New',
  ASKRESUBMIT: 'AskedToResubmit',
  INPROGRESS2: 'InProgress2',
  PAID: 'Paid',
  PARTIALPAID: 'PartialPaid',
  UNPAID: 'Unpaid',
  HISTORY: 'History',
  PENDING: 'Pending',
}
export const ARR_RECEIPT_STATUS = [
  {
    text: 'InProgress',
    value: RECEIPT_STATUS.INPROGRESS,
  },
  {
    text: 'Approved',
    value: RECEIPT_STATUS.APPROVED,
  },
  {
    text: 'Rejected',
    value: RECEIPT_STATUS.REJECTED,
  },
  {
    text: 'Deleted',
    value: RECEIPT_STATUS.DELETED,
  },
  {
    text: 'New',
    value: RECEIPT_STATUS.NEW,
  },
  {
    text: 'AskedToResubmit',
    value: RECEIPT_STATUS.ASKRESUBMIT,
  },
  {
    text: 'InProgress2',
    value: RECEIPT_STATUS.INPROGRESS2,
  },
  {
    text: 'Paid',
    value: RECEIPT_STATUS.PAID,
  },
  {
    text: 'PartialPaid',
    value: RECEIPT_STATUS.PARTIALPAID,
  },
  {
    text: 'Unpaid',
    value: RECEIPT_STATUS.UNPAID,
  },
  {
    text: 'Pending',
    value: RECEIPT_STATUS.PENDING,
  },
]
export const RECEIPT_PERCENT = 20

export const TRANSACTION_SEARCH_CATEGORY = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Pay Out',
    value: 'PaidOut',
  },
  {
    text: 'Revenue',
    value: 'Revenue',
  },
]
export const TRANSACTION_TYPES = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Lucky tickets',
    value: 'Lottery',
  },
  {
    text: 'Network Daily Bonus',
    value: 'NetworkDailyBonus',
  },
  {
    text: 'Network Leadership Bonus',
    value: 'NetworkLeadershipBonus',
  },
  {
    text: 'Annual Pass',
    value: 'YearlyPass',
  },
  {
    text: 'Monthly Pass',
    value: 'MonthlyPass',
  },

  {
    text: 'Cashback Reward',
    value: 'CashbackReward',
  },
  {
    text: 'Cashback Daily PaidOut',
    value: 'CashbackDailyPaidOut',
  },
  {
    text: 'Global Bonus',
    value: 'NetworkGlobalBonus',
  },
]

export const TRANSACTION_CATEGORY = {
  PAIDOUT: 'PaidOut',
  REVENUE: 'Revenue',
}
export const TRANSACTION_CATEGORY_TEXT = {
  PAIDOUT: 'Pay Out',
}

export const VIEW_CHART = {
  REVENUE: 'REVENUE',
  PAYOUT: 'PAYOUT',
}
export const STATISTIC_TYPE = {
  NETWORK_DAILY_BONUS: 'Network Daily Bonus',
  LEADER_SHIP: 'Leadership Matching',
  GLOBAL_BONUS: 'Global Bonus',
  CASHBACK_DAILY: 'Cashback Daily Pay Out',
  CASBACK_REWARD: 'Cashback Reward Monthly',
  MONTHLY_PASS: 'Monthly Pass',
  YEARLY_PASS: 'Annual Pass',
  LUCKY_TICKETS: 'Lucky Tickets',
}
export const WALLET_TYPES = [
  {
    text: 'VMM(VRC20)',
    value: 'Vmm',
    name: 'VMM_WALLET',
  },
  {
    text: 'VLINKPAY COIN',
    value: 'VlinkpayCoin',
    name: 'VPC_WALLET',
  },
  {
    text: 'VLINKPAY COIN (Bonus)',
    value: 'VlinkpayCoin(bonus)',
    name: 'VPCBONUS_WALLET',
  },
  {
    text: 'VUSD(VRC20)',
    value: 'Vusd',
    name: 'VUSD_WALLET',
  },
  {
    text: 'USDT(VRC20)',
    value: 'Usdt',
    name: 'USDT_WALLET',
  },
  {
    text: 'USDT CASHBACK',
    value: 'UsdtCashback',
    name: 'USDT_BONUS_WALLET',
  },
  {
    text: 'USDT CASHBACK HOLDING',
    value: 'UsdtCashbackHolding',
    name: 'USDT_BONUS_HOLDING_WALLET',
  },
  {
    text: 'USD',
    value: 'Usd',
    name: 'USD_WALLET',
  },
  {
    text: 'VND',
    value: 'Vnd',
    name: 'VND_WALLET',
  },
  {
    text: 'VLG(VRC20)',
    value: 'Vlg',
    name: 'VLG_WALLET',
  },
  {
    text: 'VLG STAKING(VRC20)',
    value: 'VlgStaking',
    name: 'VLG_STALKING_WALLET',
  },
  {
    text: 'VLINKPAY COIN (Bonus Block)',
    value: 'VlinkpayCoin(bonusBlock)',
    name: 'VPCBONUSBLOCK_WALLET',
  },
  {
    text: 'NFT',
    value: 'Nft',
    name: 'NFT_WALLET',
  },
  {
    text: 'USDT CAPITAL HOLDING',
    value: 'UsdtCapitalHolding',
    name: 'USDT_CAPITAL_HOLDING_WALLET',
  },
  {
    text: 'USDT PROFIT HOLDING',
    value: 'UsdtProfitHolding',
    name: 'USDT_PROFIT_HOLDING_WALLET',
  },
  {
    text: 'VPC CREDIT',
    value: 'BonusCredit',
    name: 'BONUS_CREDIT_WALLET',
  },
  {
    text: 'BONUS VMM SAVING',
    value: 'BonusVmmSaving',
    name: 'VMM_SAVING_WALLET',
  },
  {
    text: 'USDC',
    value: 'Usdc',
    name: 'USDC_WALLET',
  },
  {
    text: 'BONUS POINT',
    value: 'BonusPoint',
    name: 'BONUS_POINT_WALLET',
  },
]
export const WALLET_TOPUP = [
  {
    text: 'VMM',
    value: 'Vmm',
    name: 'VMM_WALLET',
  },
  {
    text: 'VUSD',
    value: 'Vusd',
    name: 'VUSD_WALLET',
  },

  {
    text: 'VPC',
    value: 'Vpc',
    name: 'VPC_WALLET',
  },
]

export const enum MEMBERSHIP_PACKAGE_TYPES {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}
export const SEARCH_RECEIPT_BY_DATE = {
  CYCLES: 'CYCLES',
  USED_DATE: 'USED_DATE',
  END_DATE: 'END_DATE',
}
export const ORDER_RECEIPT_BY = {
  USED_DATE: 'UsedDate',
  END_DATE: 'EndDate',
  USERNAME: 'Username',
  DEFAULT: 'DEFAULT',
}
export enum ActivationMembership {
  FromToday = 'FromToday',
  FromLastExpirationDate = 'FromLastExpirationDate',
}
export enum PaidMembership {
  None = 'None',
  PaidAndDebt = 'PaidAndDebt',
}

export const ActivationMembershipType = [
  {
    label: 'From Today',
    value: ActivationMembership.FromToday,
  },
  {
    label: 'From Last Expiration Date',
    value: ActivationMembership.FromLastExpirationDate,
  },
]
export const PaidMembershipOption = [
  {
    label: 'None',
    value: PaidMembership.None,
  },
  {
    label: 'Paid And Debt',
    value: PaidMembership.PaidAndDebt,
  },
]

export const PACKAGE_TYPES = {
  DataReceipt: 'DataReceipt',
  BigdataPlatinum: 'BigdataPlatinum',
  AutoBinary: 'AutoBinary',
  LinksSystem: 'LinksSystem',
  BigdataDaily: 'BigdataDaily',
  BigdataTravel: 'BigdataTravel',
}
export const CurrencyUnitsValues = { Vmm: 'Vmm', Vlg: 'Vlg' }

export const CurrencyUnits = [
  {
    text: 'Vmm',
    value: CurrencyUnitsValues.Vmm,
  },
  {
    text: 'VLG',
    value: CurrencyUnitsValues.Vlg,
  },
]

export const SelectWinnerValue = {
  MEMBER: 'SetBySystem',
  RANDOM: 'Random',
  NOBODY: 'NoWinner',
}
export const SelectWinnerText: any = {
  SetBySystem: 'Selected',
  Random: 'Random',
  NoWinner: 'Nobody',
}
export const SelectWinner = [
  { text: 'Nobody', value: SelectWinnerValue.NOBODY },
  { text: 'Random lucky numner', value: SelectWinnerValue.RANDOM },
  { text: 'Set lucky number', value: SelectWinnerValue.MEMBER },
]

export const CyclesStatusValue = {
  ACTIVE: 'Active',
  DONE: 'Done',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
}

export const SelectCyclesStatus = [
  { text: 'Active', value: CyclesStatusValue.ACTIVE },
  { text: 'Pending', value: CyclesStatusValue.PENDING },
  { text: 'Done', value: CyclesStatusValue.DONE },
  { text: 'Cancelled', value: CyclesStatusValue.CANCELLED },
]

export const SelectCyclesTypeValue = {
  LATEST: '1',
  SETBYADMIN: '2',
}
export const SelectCyclesType = [
  { text: 'Current Lucky Tickets Event', value: SelectCyclesTypeValue.LATEST },
  { text: 'Choose Lucky Tickets Event', value: SelectCyclesTypeValue.SETBYADMIN },
]

export const LotteryTicketStatusValue = {
  Active: 'Active',
  Done: 'Done',
  Pending: 'Pending',
  Jackpot: 'Jackpot',
  Cancelled: 'Cancelled',
}
export const LotteryTicketStatusSelect = [
  { text: 'All', value: '' },
  { text: 'Active', value: LotteryTicketStatusValue.Active },
  { text: 'Pending', value: LotteryTicketStatusValue.Pending },
  { text: 'Jackpot', value: LotteryTicketStatusValue.Jackpot },
  { text: 'Done', value: LotteryTicketStatusValue.Done },
  { text: 'Cancelled', value: LotteryTicketStatusValue.Cancelled },
]

export const SelectMonthlyPassTypeValue = {
  AiBigdata: 'Bigdata',
  Package: 'Package',
  MonthlyPass: 'MonthlyPass',
}
export const SelectMonthlyPassType = [
  { text: 'Bigdata package', value: SelectMonthlyPassTypeValue.AiBigdata },
  // { text: 'Data receipt package', value: SelectMonthlyPassTypeValue.Package },
  { text: ' Monthly pass', value: SelectMonthlyPassTypeValue.MonthlyPass },
]
export const TicketPrizePlanStatusValue = {
  WaitingPayment: 'WaitingPayment',
  Paid: 'Paid',
  Pending: 'Pending',
  Expired: 'Expired',
}
export const TicketPrizePlanStatusText: any = {
  WaitingPayment: 'WaitingPayment',
  Paid: 'Paid',
  Pending: 'Processing',
  Expired: 'Expired',
}

export const StoreStatusValue = {
  Active: 'Active',
  Inactive: 'Inactive',
}
export const ARR_STORE_STATUS = [
  { text: 'Active', value: StoreStatusValue.Active },
  { text: 'Inactive', value: StoreStatusValue.Inactive },
]

export const AMOUNT_CONVERT = ['USD', 'VND']

export const enum WithdrawStatusValue {
  Reject = 'Reject',
  Completed = 'Completed',
  Pending = 'Pending',
  Approved = 'Approved',
  Scheduled = 'Scheduled',
  Error = 'Error',
}

export const WithdrawStatus = [
  { text: WithdrawStatusValue.Completed, value: WithdrawStatusValue.Completed },
  { text: WithdrawStatusValue.Error, value: WithdrawStatusValue.Reject },
]
export const DEFAULT_PAGE_SIZE = 10

export enum TransactionType {
  None = 0,
  ADMIN_TOPUP = 1,
  ADMIN_RECLAIM = 2,
}
export const TXN_WALLET_TYPES = [
  {
    text: 'VMM',
    value: 'Vmm',
    name: 'VMM_WALLET',
  },
  {
    text: 'VUSD',
    value: 'Vusd',
    name: 'VUSD_WALLET',
  },

  {
    text: 'VPC',
    value: 'Vpc',
    name: 'VPC_WALLET',
  },
]
export const TXN_TRANSACTION_TYPES = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Reclaim',
    value: TransactionType.ADMIN_RECLAIM,
  },
  {
    text: 'Top up',
    value: TransactionType.ADMIN_TOPUP,
  },
  {
    text: 'None',
    value: TransactionType.None,
  },
]
export const enum ViewType {
  ByList = 0,
  ByCard = 1,
}

export const enum BinaryTreeBranchType {
  Left = 'Left',
  Mid = 'Mid',
  Right = 'Right',
  None = 'None',
}

export const ARR_MEMBER_REPORT_STATUS = [
  {
    text: 'All',
    value: MemberReportFilterStatus.ALL,
  },
  {
    text: 'Renewed',
    value: MemberReportFilterStatus.Renewed,
  },
  {
    text: 'Bought',
    value: MemberReportFilterStatus.Bought,
  },
  {
    text: 'Expired',
    value: MemberReportFilterStatus.Expired,
  },
  {
    text: 'Active',
    value: MemberReportFilterStatus.Active,
  },
]

export const ARR_DateType = [
  {
    text: 'BuyDate',
    value: DateType.BuyDate,
  },
  {
    text: 'ExpiredDate',
    value: DateType.ExpiredDate,
  },
]

export const ARR_PACKAGE_STATUS = [
  {
    text: 'All',
    value: PackageStatus.ALL,
  },
  {
    text: 'Available',
    value: PackageStatus.Active,
  },
  {
    text: 'Not Available',
    value: PackageStatus.InActive,
  },
]

export const ARR_PASS_CODE_TYPE = [
  {
    text: 'Normal',
    value: PassCodeType.Normal,
  },
  {
    text: 'Monthly',
    value: PassCodeType.Monthly,
  },
  {
    text: 'Yearly',
    value: PassCodeType.Yearly,
  },
]

export const MaxValueTopup = 999999999999

export const enum HistoryTransactionType {
  None = 'None',
  ADMIN_TOPUP = 'ADMIN_TOPUP',
  ADMIN_RECLAIM = 'ADMIN_RECLAIM',
  CASHBACK_DAILY_PAYOUT = 'CASHBACK_DAILY_PAYOUT',
  LEADERSHIP_BONUS = 'LEADERSHIP_BONUS',
  NETWORK_DAILY_BONUS = 'NETWORK_DAILY_BONUS',
  RECEIPT_CASHBACK = 'RECEIPT_CASHBACK',
  GLOBAL_BONUS = 'GLOBAL_BONUS',
  LOTTERY_AFFILIATE_BONUS = 'LOTTERY_AFFILIATE_BONUS',
}
export const HistoryTransactionTypeText: any = {
  None: 'None',
  ADMIN_TOPUP: 'Topup',
  ADMIN_RECLAIM: 'Reclaim',
  CASHBACK_DAILY_PAYOUT: 'Cashback daily payout',
  LEADERSHIP_BONUS: 'Leadership bonus',
  NETWORK_DAILY_BONUS: 'Network daily bonus',
  RECEIPT_CASHBACK: 'Receipt cashback',
  GLOBAL_BONUS: 'Global bonus',
  LOTTERY_AFFILIATE_BONUS: 'Lottery affiliate bonus',
}

export const WALLET_TYPES_TOKEN_REPORT = [
  {
    label: 'VMM',
    value: WalletType.Vmm,
  },
  {
    label: 'VPC',
    value: WalletType.Vpc,
  },
  {
    label: 'VLG',
    value: WalletType.Vlg,
  },
  {
    label: 'VUSD',
    value: WalletType.Vusd,
  },
  {
    label: 'USD',
    value: WalletType.Usd,
  },
  {
    label: 'VND',
    value: WalletType.Vnd,
  },
]

export const AllDateRangeFilterTypes = [
  { label: 'Today', type: DateRangeFilterType.Today },
  { label: 'Last 7 days', type: DateRangeFilterType.Last7Days },
  { label: 'Last 30 days', type: DateRangeFilterType.Last30Days },
  { label: 'Custom', type: DateRangeFilterType.Custom },
]
export enum LocalhostKeys {
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  LOTTERY_INFORMATION = 'LOTTERY_INFORMATION',
  THEME_COLOR = 'THEME_COLOR',
  SIDEBAR_FILL_COLOR = 'SIDEBAR_FILL_COLOR',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
  SIDEBAR_STYLE = 'SIDEBAR_STYLE',
  THEME = 'THEME',
  MODE = 'MODE',
  USER = 'USER',
  USERNAME = 'USERNAME',
  LANGUAGE = 'LANGUAGE',
}

export enum SavingTermStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
export enum CurrencyUnit {
  Vmm = 'Vmm',
  // Vlg = 'Vlg',
  // Usd = 'Usd',
  // Vnd = 'Vnd',
}
export enum SavingInterestPeriodType {
  EndOfTerm = 'EndOfTerm',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
}
export enum SavingAccountStatus {
  New = 'New',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}
export enum StatusExtenralStatus {
  All = 'All',
  Completed = 'Completed',
  Expired = 'Expired',
  New = 'New',
}
export enum PaymentOptionGroup {
  Internal = 'Internal',
  VlinkPay = 'VlinkPay',
  Trc20 = 'Trc20',
  Single = 'Single',
  Custom = 'Custom',
}

export enum FunctionSettingGroupType {
  TransferToken = 'TransferToken',
  SwapToken = 'SwapToken',
  BuyTicket = 'BuyTicket',
  Deposit = 'Deposit',
  CreditCard = 'CreditCard',
  Upload = 'Upload',
  Other = 'Other',
}

export enum FunctionSettingSubGroupType {
  Transfer = 'Transfer',
  TransferToMoongleFarm = 'TransferToMoongleFarm',
  SwapToken_ToVMM = 'SwapToken_ToVMM',
  SwapToken_ToVPCAvailable = 'SwapToken_ToVPCAvailable',
  BuyTicket_LuckyTicket = 'BuyTicket_LuckyTicket',
  Deposit_FromExchange = 'Deposit_FromExchange',
  CreditCard_Payment = 'CreditCard_Payment',
  Upload_Receipt = 'Upload_Receipt',
  BuyPackage_BigDataDaily9K = 'BuyPackage_BigDataDaily9K',
  BuyPackage_BigDataTravel = 'BuyPackage_BigDataTravel',
  BuyPackage_BigDataDaily5K = 'BuyPackage_BigDataDaily5K',
  BuyPackage_BigDataDaily = 'BuyPackage_BigDataDaily',
  BuyPackage_BigDataPlantinum = 'BuyPackage_BigDataPlantinum',
  TopUpVlinkPay = 'TopUp_VlinkPay',
  SignNewDownlineMembers = 'SignNewDownlineMembers',
  Authentication_SignIn = 'Authentication_SignIn',
  Authentication_SignUp = 'Authentication_SignUp',
  Authentication_SignUp_ConfirmEmail = 'Authentication_SignUp_ConfirmEmail',
  BonusCreditWallet_ResetEveryMonth = 'BonusCreditWallet_ResetEveryMonth',
  Other_EnableKyb = 'Other_EnableKyb',
  Other_EnableChatbot = 'Other_EnableChatbot',
}

export enum SwapStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export const USER_LOCK_STATUS = {
  ACTIVED: WalletBlackListStatus.Actived,
  UNACTIVED: WalletBlackListStatus.Unactived,
}
export const PACKAGE_SITUATION = [
  {
    text: 'All',
    value: PackageSituation.ALL,
  },
  {
    text: 'Available',
    value: PackageSituation.Available,
  },
  {
    text: 'Not Available',
    value: PackageSituation.NotAvailable,
  },
]

export const PAYOUT_METHOD = {
  PAYOUT_METHOD_TYPE_1: 'PAYOUT_METHOD_TYPE_1',
  PAYOUT_METHOD_TYPE_2: 'PAYOUT_METHOD_TYPE_2',
}
export const PAYOUT_METHOD_TYPE = [
  { text: 'Payout method 1', value: PAYOUT_METHOD.PAYOUT_METHOD_TYPE_1 },
  { text: 'Payout method 2', value: PAYOUT_METHOD.PAYOUT_METHOD_TYPE_2 },
]
export const PAYMENT_METHOD = {
  SINGLE: 'SingleMethod',
  COMBINE: 'CustomMethod',
}

export const PAYMENT_METHOD_V2 = {
  SINGLE: 'SingleMethod',
  COMBINE: 'CustomMethod',
  VLINKPAY: 'VlinkPay',
  TRC20: 'Trc20',
}
export const PAYMENT_METHOD_TYPE = [
  { text: 'Single', value: PAYMENT_METHOD.SINGLE },
  { text: 'Combine', value: PAYMENT_METHOD.COMBINE },
]
export enum PayoutStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
export enum PayoutMethod {
  Type1 = 'Type1',
  Type2 = 'Type2',
}
export const PaymentMethod = {
  All: '',
  Single: 'SingleMethod',
  Combine: 'CustomMethod',
  Trc20: 'Trc20',
  VlinkPay: 'Vlinkpay',
}
export const PACKAGE_STATUS = [
  {
    text: '',
    value: '',
  },
  {
    text: 'Active',
    value: 'false',
  },
  {
    text: 'Block',
    value: 'true',
  },
]

export const PAYMENT_STATUS = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Active',
    value: 'ACTIVE',
  },
  {
    text: 'Inactive',
    value: 'INACTIVE',
  },
]
export enum PackageGroup {
  ALL = '',
  ANNUALPLATFORMFEE = 'ANNUALPLATFORMFEE',
  AIBIGDATA = 'AIBIGDATA',
  MERCHANTPACKAGE = 'MERCHANTPACKAGE',
  PACKAGESERVICE = 'PACKAGESERVICE',
  AIBIGDATATRAVEL = 'AIBIGDATATRAVEL',
}

export const PAYMENT_OPTIONS = [
  {
    text: 'All',
    value: PackageGroup.ALL,
  },
  {
    text: 'Annual Platform Fee',
    value: PackageGroup.ANNUALPLATFORMFEE,
  },
  {
    text: 'AI Big Data',
    value: PackageGroup.AIBIGDATA,
  },
  {
    text: 'Merchant Package',
    value: PackageGroup.MERCHANTPACKAGE,
  },
  {
    text: 'Package Service',
    value: PackageGroup.PACKAGESERVICE,
  },
  {
    text: 'AI Big Data Travel',
    value: PackageGroup.AIBIGDATATRAVEL,
  },
]

export const StatusPackage = {
  ACTIVE: true,
  INACTIVE: false,
}
export const TYPE_PAYMENT_METHOD = [
  {
    text: 'All',
    value: PaymentMethod.All,
  },
  {
    text: 'Single',
    value: PaymentMethod.Single,
  },
  {
    text: 'Combine',
    value: PaymentMethod.Combine,
  },
  {
    text: 'Trc20',
    value: PaymentMethod.Trc20,
  },
  {
    text: 'Vlinkpay',
    value: PaymentMethod.VlinkPay,
  },
]

export const TypePaymentMethod = {
  SingleMethod: 'Single',
  CustomMethod: 'Custom',
  Trc20: 'Trc20',
  VlinkPay: 'Vlinkpay',
}
