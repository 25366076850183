import moment from 'moment-timezone'

export const texasDate = (date: any) => {
  return moment(date).tz('America/Chicago').format()
}
export const texasDateFormat = () => {
  return moment().tz('America/Chicago').format('YYYY-MM-DDTHH:mm:ss')
}
export const toMoment = (date: Date, format: string) => {
  return moment(date).format(format)
}
