import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
function FormAddPayoutType1(props: any) {
  const { onSubmit, wallets, onCancel, data } = props

  const initValues = {
    name: data?.name || '',
    status: data?.status || true,
  }
  const initData = {
    randomId: uuidv4(),
    name: '',
    walletType: '',
    value: '0',
  }
  const [formValues, setFormValues] = useState(initValues)
  const [payoutWallets, setPayoutWallets] = useState([initData])

  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setFormValues((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const handleSubmit = () => {
    const value = {
      formValues: formValues,
      payoutWallets: payoutWallets,
    }
    onSubmit(value)
  }

  const handleAddWalletPayout = () => {
    const initNewData = {
      randomId: uuidv4(),
      name: '',
      walletType: '',
      value: '0',
    }
    setPayoutWallets([...payoutWallets, initNewData])
  }
  const handleRemovePayoutWallet = (id: string) => {
    const newData = payoutWallets.filter((item) => item.randomId !== id)
    setPayoutWallets([...newData])
  }
  const handleChangePayoutWallets = (id: string, e: any) => {
    const { name, value } = e.target
    const updatedList = payoutWallets.map((wallet) => {
      if (wallet.randomId === id) {
        return {
          ...wallet,
          [name]: value,
        }
      }
      return wallet
    })
    setPayoutWallets(updatedList)
  }
  return (
    <>
      <Modal.Body>
        <form className="myfrm">
          <div className="row gy-2 gx-2">
            <div className="col-12">
              <label className="mb-1">Status</label>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="lockoutEnabled"
                  checked={formValues.status === true}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      status:
                        event.target.type === 'checkbox'
                          ? !!event.target.checked
                          : !!event.target.value,
                    })
                  }
                />
                <label
                  className="form-check-label text-uppercase fw-bold"
                  htmlFor="lockoutEnabled"
                  style={{ color: `${formValues?.status ? 'blue' : 'red'}` }}
                >
                  {formValues?.status ? 'Ative' : 'Inactive'}
                </label>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label className="mb-1">
                  Name <span className="text-danger">*</span>
                </label>
                <div className="border mb-3 rounded">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      <input
                        className="form-control "
                        type="text"
                        name="name"
                        value={formValues.name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <h6>Wallets</h6>
              <div>
                {payoutWallets.map((wallet, index) => (
                  <>
                    <div className="payout-wallet-item mb-4">
                      {index > 0 && (
                        <button
                          type="button"
                          className="btn btn-primary iconbutton"
                          onClick={() => handleRemovePayoutWallet(wallet.randomId)}
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      )}

                      <div className="row gx-1">
                        <div className="col-md-8">
                          <label htmlFor="selectUser">
                            Wallet <span className="text-danger">*</span>
                          </label>
                          <div className="mb-2 mt-1">
                            <select
                              className="form-select border w-100"
                              value={wallet.value}
                              name="name"
                              onChange={(e: any) => handleChangePayoutWallets(wallet.randomId, e)}
                            >
                              <option disabled value={''}>
                                Select a wallet
                              </option>
                              {wallets?.map((item: any) => (
                                <option
                                  value={item.value}
                                  key={item.value}
                                  // disabled={}
                                >
                                  {item?.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label className="mb-1">
                            Percent(%)<span className="text-danger">*</span>
                          </label>
                          <div className="border mb-3 rounded">
                            <div className="row align-items-center gx-0">
                              <div className="col">
                                <input
                                  className="form-control "
                                  type="text"
                                  value={wallet.value}
                                  name="value"
                                  onChange={(e: any) =>
                                    handleChangePayoutWallets(wallet.randomId, e)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>

              <button type="button" className="btn btn-md btn-link" onClick={handleAddWalletPayout}>
                <i className="bi bi-plus"></i> Add wallet
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="">
        <div className="row w-100 align-items-center justify-content-center">
          <div className="col-auto">
            <Button
              variant="secondary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Cancel'}
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              variant="primary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Update'}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

export default FormAddPayoutType1
