import React, { useCallback, useEffect, useState } from 'react'
import ListSavingTerm from './components/list-saving-term'
import { ModelUserSearch, SavingAccount, SavingTerm, SavingTermDetail } from 'models'
import { Modal } from 'react-bootstrap'
import SavingTermForm from './components/form-saving-term'
import ListSaver from './components/list-saver'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import { debounce } from 'lodash'
import AsyncSelect from 'react-select/async'
import {
  FORMAT_DATE_PARAMS,
  LOADING,
  SavingAccountStatus,
  SavingInterestPeriodType,
  SavingTermStatus,
  colourStyles,
} from 'helpers/constants'
import DateRange from 'components/daterange'
import moment from 'moment-timezone'
import Pagination from 'components/pagination'
import OpenAlert from 'components/sweetAlert'
import Loader from 'components/loader'
import { useLocation } from 'react-router-dom'

const MENU_ITEMS = {
  USERS: 'Member list',
  SETTING: 'Saving configuration',
  SETTING_DETAIL: 'Savings interest',
}

const MENU_ITEMS_VALUE = {
  USERS: 'accounts',
  SETTING: 'configuration',
}
const MenuItems = [
  {
    label: MENU_ITEMS.USERS,
    value: MENU_ITEMS_VALUE.USERS,
  },
  {
    label: MENU_ITEMS.SETTING,
    value: MENU_ITEMS_VALUE.SETTING,
  },
]

const actions = {
  addTerm: 'addNewTerm',
  editTerm: 'editTerm',
  copyTerm: 'copyTerm',
}
function SavingManagement() {
  const location = useLocation()
  const [menuActive, setMenuActive] = useState(MENU_ITEMS_VALUE.USERS)
  const [percent, setPercent] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [myAction, setMyAction] = useState('')
  const [loading, setLoading] = useState(false)
  const [userSeacrh, setUserSeacrh] = useState<ModelUserSearch>()
  const [savingAccounts, setSavingAccounts] = useState<SavingAccount[]>([])
  const [savingTerms, setSavingTerms] = useState<SavingTerm[]>([])
  const [savingTermSelected, setSavingTermSelected] = useState<SavingTermDetail | undefined>(
    undefined,
  )
  const [searchSaver, setSearchSaver] = useState({
    openDate: { startDate: '', endDate: '' },
    maturity: { startDate: '', endDate: '' },
    savingTermId: '',
    periodType: '',
    status: '',
    pageSize: 10,
    currentPage: 1,
  })
  const [totalCountSaver, setTotalCountSaver] = useState(0)
  const [totalCountSavingTerm, setTotalCountSavingTerm] = useState(0)

  const [searchSavingTerm, setSearcSavingTerm] = useState({
    periodType: '',
    status: SavingTermStatus.Active.toString(),
    pageSize: 10,
    currentPage: 1,
    startDate: '',
    endDate: '',
  })

  const handleMenuActive = (item: string) => {
    setMenuActive(item)
  }
  const onChangePercents = (event: any) => {
    setPercent(event.target.value)
  }
  const handleEdit = async (item: SavingTerm) => {
    setMyAction(actions.editTerm)
    setLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setLoading(data)
    })
    const query: any = await axiosClient().get(END_POINTS.SAVING.DETAIL_SAVING_TERM(item.id))
    if (query.data) {
      setSavingTermSelected(query.data)
      setShowModal(true)
    }
  }
  const handleAddTerm = () => {
    setMyAction(actions.addTerm)
    setShowModal(true)
  }

  const handleSearchByUsername = async (inputValue: string) => {
    if (inputValue) {
      const query = await axiosClient().get(
        END_POINTS.USER_API_END_POINTS.SEARCH_CONTAIN(inputValue),
      )
      if (!query.data) return []
      return query.data
    }
    return []
  }

  const handleChangePenalty = async () => {
    const query = await axiosClient().post(END_POINTS.SAVING.UPDATE_EARLY_WITHDRAWAL_RATE(), {
      withdrawalRate: percent / 100,
    })

    if (query) {
      OpenAlert('Update early withdrawal rate success!', '', 'success')
      loadDataSavingTerm()
    }
  }

  const loadOptionByUsername = debounce((inputText, callback) => {
    handleSearchByUsername(inputText).then((options) => callback(options))
  }, 1300)

  const loadDataSavingTerm = useCallback(async () => {
    let params: any = {
      PageNumber: searchSavingTerm.currentPage,
      PageSize: searchSavingTerm.pageSize,
    }
    if (searchSavingTerm.startDate) {
      params.fromDate = moment(searchSavingTerm.startDate).format(FORMAT_DATE_PARAMS)
    }

    if (searchSavingTerm.endDate) {
      params.toDate = moment(searchSavingTerm.endDate).format(FORMAT_DATE_PARAMS)
    }
    if (searchSavingTerm.status) {
      params.status = searchSavingTerm.status
    }
    if (searchSavingTerm.periodType !== '') {
      params.interestPeriodType = searchSavingTerm.periodType
    }
    setLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setLoading(data)
    })
    const query = await axiosClient().get(END_POINTS.SAVING.GET_SAVING_TERM(), {
      params,
    })
    if (query?.data) {
      const mydata: SavingTerm[] = query.data.items
      setSavingTerms(mydata)
      setTotalCountSavingTerm(query.data.totalCount)
    } else {
      setTotalCountSavingTerm(0)
    }
  }, [
    searchSavingTerm.currentPage,
    searchSavingTerm.endDate,
    searchSavingTerm.pageSize,
    searchSavingTerm.periodType,
    searchSavingTerm.startDate,
    searchSavingTerm.status,
  ])

  const loadData = useCallback(async () => {
    let params: any = {
      UserId: userSeacrh ? userSeacrh.id : null,
      PageNumber: searchSaver.currentPage,
      PageSize: searchSaver.pageSize,
    }
    if (searchSaver.status) {
      params.status = searchSaver.status
    }
    if (searchSaver.periodType !== '') {
      params.interestPeriodType = searchSaver.periodType
    }
    if (searchSaver.openDate.startDate) {
      params.openFromDate = moment(searchSaver.openDate.startDate).format(FORMAT_DATE_PARAMS)
    }

    if (searchSaver.openDate.endDate) {
      params.openToDate = moment(searchSaver.openDate.endDate).format(FORMAT_DATE_PARAMS)
    }

    if (searchSaver.maturity.startDate) {
      params.maturityFromDate = moment(searchSaver.maturity.startDate).format(FORMAT_DATE_PARAMS)
    }

    if (searchSaver.maturity.endDate) {
      params.maturityToDate = moment(searchSaver.maturity.endDate).format(FORMAT_DATE_PARAMS)
    }

    const query = await axiosClient().get(END_POINTS.SAVING.GET_SAVING_ACCOUNT(), {
      params,
    })

    if (query?.data) {
      setSavingAccounts(query.data.items)
      setTotalCountSaver(query.data.totalCount)
    } else {
      setSavingAccounts([])
      setTotalCountSaver(0)
    }
  }, [
    searchSaver.currentPage,
    searchSaver.maturity.endDate,
    searchSaver.maturity.startDate,
    searchSaver.openDate.endDate,
    searchSaver.openDate.startDate,
    searchSaver.pageSize,
    searchSaver.periodType,
    searchSaver.status,
    userSeacrh,
  ])

  const getEarlyWithdrawalRate = useCallback(async () => {
    const query = await axiosClient().get(END_POINTS.SAVING.GET_EARLY_WITHDRAWAL_RATE())
    if (query?.data) {
      setPercent(query?.data * 100)
    }
  }, [])

  useEffect(() => {
    if (menuActive === MENU_ITEMS_VALUE.SETTING) loadDataSavingTerm()
  }, [loadDataSavingTerm, menuActive])

  useEffect(() => {
    if (menuActive === MENU_ITEMS_VALUE.USERS) loadData()
  }, [loadData, menuActive])

  useEffect(() => {
    getEarlyWithdrawalRate()
  }, [getEarlyWithdrawalRate])

  const handleSaveSavingTerm = async (values: any) => {
    if (myAction === actions.addTerm || myAction === actions.copyTerm) {
      setLoading(true)
      PubSub.subscribe(LOADING, function (msg, data) {
        setLoading(data)
      })
      const query = await axiosClient().post(END_POINTS.SAVING.ADD_SAVING_TERM(), values)
      if (query) {
        OpenAlert('Create saving term success!', '', 'success')
        setShowModal(false)
        setTimeout(() => {
          loadDataSavingTerm()
        }, 500)
      }
    } else if (myAction === actions.editTerm && savingTermSelected) {
      values.id = savingTermSelected.id
      setLoading(true)
      PubSub.subscribe(LOADING, function (msg, data) {
        setLoading(data)
      })
      const query = await axiosClient().put(END_POINTS.SAVING.UPDATE_SAVING_TERM(), values)
      if (query) {
        OpenAlert('Update saving term success!', '', 'success')
        setTimeout(() => {
          loadDataSavingTerm()
        }, 500)
        setShowModal(false)
      }
    }
  }
  const handleDeleteSavingTerm = async (id: string) => {
    const query = await axiosClient().delete(END_POINTS.SAVING.DELETE_SAVING_TERM(id))
    if (query) {
      OpenAlert('Delete saving term success!', '', 'success')
      loadDataSavingTerm()
    }
  }
  const handleCopy = async (item: SavingTerm) => {
    setMyAction(actions.copyTerm)
    setLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setLoading(data)
    })
    const query: any = await axiosClient().get(END_POINTS.SAVING.DETAIL_SAVING_TERM(item.id))
    if (query.data) {
      setSavingTermSelected(query.data)
      setShowModal(true)
    }
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center page-title">
          <div className="col-12 col-md mb-2 mb-sm-0">
            <h3 className="mb-0">Saving management</h3>
          </div>
        </div>
        <hr className="p-0 m-0 mb-4" />
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <ul className="nav nav-tabs justify-content-center nav-WinDOORS nav-lg mb-4 mt-4">
              {MenuItems.map((item) => (
                <li className="nav-item" key={item.value}>
                  <button
                    className={`nav-link ${menuActive === item.value ? 'active' : ''}`}
                    onClick={() => handleMenuActive(item.value)}
                  >
                    <span className="h5">{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
            {menuActive === MENU_ITEMS_VALUE.SETTING && (
              <>
                <div className="row align-items-center justify-content-between pb-4">
                  <div className="col-auto">
                    <div className="p-2 border">
                      <div className="row align-items-center gx-1">
                        <div className="col-auto">Penalty for early settlement:</div>
                        <div className="col-auto">
                          <input
                            className="form-control border"
                            type="number"
                            value={percent}
                            onChange={onChangePercents}
                          />
                        </div>
                        <div className="col-auto">%</div>
                        <div className="col-auto">
                          <button className="btn btn-sm btn-primary" onClick={handleChangePenalty}>
                            Change
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <button className="btn btn-sm btn-primary btn-2" onClick={handleAddTerm}>
                          <div className="d-flex align-items-center gx-3 text-uppercase">
                            <i className="bi bi-plus"></i>
                            <span>Add New Savings Term</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="card-body">
              {menuActive === MENU_ITEMS_VALUE.SETTING && (
                <>
                  <div className="row gx-1 align-items-end justify-content-end mb-4">
                    <div className="col">
                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <label className="mb-1">Period type</label>
                          <select
                            className="form-select border w-100"
                            value={searchSavingTerm.periodType}
                            onChange={(event) =>
                              setSearcSavingTerm({
                                ...searchSavingTerm,
                                periodType: event.target.value,
                              })
                            }
                          >
                            <option value={''}>All</option>
                            {Object.values(SavingInterestPeriodType).map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <DateRange
                            lbl1="Start Date From"
                            lbl2="Start Date To"
                            dafaultDate={{
                              dateStart: searchSavingTerm.startDate,
                              dateEnd: searchSavingTerm.endDate,
                            }}
                            onChange={(myDate: any) => {
                              const newParams = { ...searchSavingTerm }
                              searchSavingTerm.startDate = myDate.dateStart
                              searchSavingTerm.endDate = myDate.dateEnd
                              setSearcSavingTerm(newParams)
                            }}
                            isClear
                          />
                        </div>
                        <div className="col-md-3 mb-2">
                          <label className="lbl mb-1">Status</label>
                          <div className="d-flex align-items-center">
                            <div className="col">
                              <select
                                className="form-select border w-100"
                                value={searchSavingTerm.status}
                                onChange={(event) =>
                                  setSearcSavingTerm({
                                    ...searchSavingTerm,
                                    status: event.target.value,
                                  })
                                }
                              >
                                <option value={''}>All</option>
                                {Object.values(SavingTermStatus).map((item) => (
                                  <option key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-auto mb-2">
                      <button className="btn btn-primary btn-md border-0 px-2">
                        <small>Search</small>
                      </button>
                    </div>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col">
                      <h4 className="mb-0">List saving term</h4>
                    </div>
                  </div>
                  {loading && <Loader />}
                  <ListSavingTerm
                    data={savingTerms}
                    onEdit={handleEdit}
                    onDelete={handleDeleteSavingTerm}
                    onCopy={handleCopy}
                  />
                  <Pagination
                    pageSize={searchSavingTerm.pageSize}
                    totalCount={totalCountSavingTerm}
                    onChange={(e: number) =>
                      setSearcSavingTerm({ ...searchSavingTerm, currentPage: e })
                    }
                    pageNumber={searchSavingTerm.currentPage}
                    onChangePageSize={(e: number) =>
                      setSearcSavingTerm({ ...searchSavingTerm, pageSize: e })
                    }
                    hidden={!savingTerms.length}
                    disableLocationPath={true}
                  />
                </>
              )}

              {menuActive === MENU_ITEMS_VALUE.USERS && (
                <>
                  <form>
                    <div className="row gx-1 align-items-end mb-5">
                      <div className="col">
                        <div className="row gx-1 justify-content-end align-items-end">
                          <div className="col-md-5 mb-3">
                            <label htmlFor="selectUser" className="lbl mb-1">
                              Member
                            </label>
                            <AsyncSelect
                              id="selectUser"
                              name="selectUser"
                              value={userSeacrh}
                              cacheOptions={false}
                              loadOptions={loadOptionByUsername}
                              defaultOptions
                              formatOptionLabel={(options: ModelUserSearch) => (
                                <>{options?.username}</>
                              )}
                              placeholder="Enter username..."
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(user: any) => {
                                setUserSeacrh(user)
                              }}
                              isClearable={true}
                              styles={colourStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label className="mb-1">Period type</label>
                            <select
                              className="form-select border w-100"
                              value={searchSaver.periodType}
                              onChange={(event) =>
                                setSearchSaver({ ...searchSaver, periodType: event.target.value })
                              }
                            >
                              <option value={''}>All</option>
                              {Object.values(SavingInterestPeriodType).map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-3 mb-3">
                            <label className="lbl mb-1">Status</label>
                            <div className="d-flex align-items-center">
                              <div className="col">
                                <select
                                  className="form-select border w-100"
                                  value={searchSaver.status}
                                  onChange={(event) =>
                                    setSearchSaver({
                                      ...searchSaver,
                                      status: event.target.value,
                                    })
                                  }
                                >
                                  <option value={''}>All</option>
                                  {Object.values(SavingAccountStatus).map((item) => (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <DateRange
                              lbl1="Open date from"
                              lbl2="Open date to"
                              dafaultDate={{
                                dateStart: searchSaver.openDate.startDate,
                                dateEnd: searchSaver.openDate.endDate,
                              }}
                              onChange={(myDate: any) => {
                                const newParams = { ...searchSaver }
                                searchSaver.openDate.startDate = myDate.dateStart
                                searchSaver.openDate.endDate = myDate.dateEnd
                                setSearchSaver(newParams)
                              }}
                              isClear
                            />
                          </div>

                          <div className="col-md-6">
                            <DateRange
                              lbl1="Maturity date from"
                              lbl2="Maturity date to"
                              dafaultDate={{
                                dateStart: searchSaver.maturity.startDate,
                                dateEnd: searchSaver.maturity.endDate,
                              }}
                              onChange={(myDate: any) => {
                                const newParams = { ...searchSaver }
                                searchSaver.maturity.startDate = myDate.dateStart
                                searchSaver.maturity.endDate = myDate.dateEnd
                                setSearchSaver(newParams)
                              }}
                              isClear
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-auto mb-2">
                        <button className="btn btn-primary btn-md border-0 px-2">
                          <small>Search</small>
                        </button>
                      </div>
                    </div>
                  </form>
                  {loading && <Loader />}
                  {savingAccounts && <ListSaver data={savingAccounts} />}

                  <Pagination
                    pageSize={searchSaver.pageSize}
                    totalCount={totalCountSaver}
                    onChange={(e: number) => setSearchSaver({ ...searchSaver, currentPage: e })}
                    pageNumber={searchSaver.currentPage}
                    onChangePageSize={(e: number) =>
                      setSearchSaver({ ...searchSaver, pageSize: e })
                    }
                    hidden={!savingAccounts.length}
                    disableLocationPath={true}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-md"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {myAction === actions.editTerm && 'Edit Saving Term'}
            {(myAction === actions.addTerm || myAction === actions.copyTerm) &&
              'Add New Saving Term'}
          </Modal.Title>
        </Modal.Header>

        {loading && <Loader />}
        {(myAction === actions.editTerm ||
          myAction === actions.addTerm ||
          myAction === actions.copyTerm) && (
          <SavingTermForm
            initialData={
              myAction === actions.editTerm || myAction === actions.copyTerm
                ? savingTermSelected
                : undefined
            }
            onCancel={() => {
              setShowModal(false)
            }}
            onSave={handleSaveSavingTerm}
          />
        )}
      </Modal>
    </>
  )
}

export default SavingManagement
