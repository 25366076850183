import React, { useCallback, useEffect, useState } from 'react'
import ListPayoutMethod from './components/list-payout-method'
import { Modal } from 'react-bootstrap'
import Loader from 'components/loader'
import FormAddNewPayoutMethod from './components/add-payout-method'
import { LOADING, PAYOUT_METHOD, PAYOUT_METHOD_TYPE, PayoutStatus } from 'helpers/constants'
import FormAddPayoutType1 from './components/add-payout-type-1'
import END_POINTS from 'http-services/end-points'
import { axiosClient } from 'http-services'
import FormAddPayoutType2 from './components/add-payout-type-2'
import Pagination from 'components/pagination'
import {
  MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE,
  MENU_MANAGEMENT_PACKAGE,
} from './system-management/packages-setting'
import { Link } from 'react-router-dom'

const actions = {
  add: 'add',
  edit: 'edit',
}

function PayoutMethodManagement() {
  const [menuActive, setMenuActive] = useState(MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE.Packages)
  const [payoutMethods, setPayoutMethods] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [myAction, setMyAction] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const [wallets, setWallets] = useState()
  const [itemEdit, setItemEdit] = useState()
  const initSearchFormValue = {
    status: PayoutStatus.Active.toString(),
    payoutType: '',
    pageSize: 10,
    currentPage: 1,
  }
  const [searchFormValues, setSearchFormValues] = useState(initSearchFormValue)
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const loadData = useCallback(async () => {
    setIsLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setIsLoading(data)
    })

    let params: any = {
      PageNumber: searchFormValues.currentPage,
      PageSize: searchFormValues.pageSize,
    }
    if (searchFormValues.status) {
      params.status = searchFormValues.status
    }
    if (searchFormValues.payoutType !== '') {
      params.interestPeriodType = searchFormValues.payoutType
    }

    //  Todo: call api get list payout
    const query = await axiosClient().get(END_POINTS.PAYOUT.GET_LIST(), {
      params,
    })

    if (query?.data) {
      setPayoutMethods(query.data.items)
      setTotalCount(query.data.totalCount)
    } else {
      setPayoutMethods([])
      setTotalCount(0)
    }
  }, [
    searchFormValues.currentPage,
    searchFormValues.pageSize,
    searchFormValues.payoutType,
    searchFormValues.status,
  ])
  useEffect(() => {
    loadData()
  }, [loadData])

  const handleAddTerm = () => {
    setSelectedType('')
    setMyAction(actions.add)
    setShowModal(true)
  }
  const handleSelectMethod = (e: any) => {
    setShowModal(false)
    setSelectedType(e)
    setTimeout(() => {
      setShowModal(true)
    }, 100)
  }
  useEffect(() => {
    loadWalletTopUpTypes()
  }, [])

  const loadWalletTopUpTypes = async () => {
    setIsLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setIsLoading(data)
    })
    // todo: call api load all wallet
    // const query = await axiosClient().get(END_POINTS.WALLETS.MY_WALLET())
    // if (query.data) {
    //   setWallets(query.data)
    // }
  }
  const handleAddPayout1 = (data: any) => {
    // todo: call api add new payout method 1
    setShowModal(false)
    setMyAction('')
  }
  const handleAddPayout2 = (data: any) => {
    // todo: call api add new payout method 2
    setShowModal(false)
    setMyAction('')
  }
  const handleUpdatePayout2 = (data: any) => {
    // todo: call api edit payout method 2
    setShowModal(false)
    setMyAction('')
    setItemEdit(undefined)
  }

  const handleUpdatePayout1 = (data: any) => {
    // todo: call api edit payout method 1
    setShowModal(false)
    setMyAction('')
    setItemEdit(undefined)
  }
  const handleEdit = (item: any) => {
    setSelectedType(item.type)
    setItemEdit(item)
    setMyAction(actions.edit)
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center page-title">
          <div className="col-12 col-md mb-2 mb-sm-0">
            <h3 className="mb-0">Payout method management</h3>
          </div>
        </div>
        <hr className="p-0 m-0 mb-4" />
      </div>

      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <ul className="nav nav-tabs justify-content-center nav-WinDOORS nav-lg mb-4 mt-4">
              {MENU_MANAGEMENT_PACKAGE.map((item) => (
                <li className="nav-item" key={item.value}>
                  <Link
                    className={`nav-link ${menuActive === item.value ? 'active' : ''}`}
                    to={`/${item.link}`}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
            <div className="row align-items-center justify-content-end pb-4">
              <div className="col-auto">
                <button className="btn btn-sm btn-primary btn-2" onClick={handleAddTerm}>
                  <div className="d-flex align-items-center gx-3 text-uppercase">
                    <i className="bi bi-plus"></i>
                    <span>Add payout method</span>
                  </div>
                </button>
              </div>
            </div>
            <div className="row gx-1 align-items-end justify-content-end mb-4">
              <div className="col-md-3 mb-2">
                <label className="mb-1">Type</label>
                <select
                  className="form-select border w-100"
                  value={initSearchFormValue.payoutType}
                  onChange={(event) =>
                    setSearchFormValues({
                      ...searchFormValues,
                      payoutType: event.target.value,
                    })
                  }
                >
                  <option value={''}>All</option>
                  {Object.values(PAYOUT_METHOD_TYPE).map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3 mb-2">
                <label className="lbl mb-1">Status</label>
                <div className="d-flex align-items-center">
                  <div className="col">
                    <select
                      className="form-select border w-100"
                      value={searchFormValues.status}
                      onChange={(event) =>
                        setSearchFormValues({
                          ...searchFormValues,
                          status: event.target.value,
                        })
                      }
                    >
                      <option value={''}>All</option>
                      {Object.values(PayoutStatus).map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-auto mb-2">
                <button className="btn btn-primary btn-md border-0 px-2">
                  <small>Search</small>
                </button>
              </div>
            </div>
            {/* {menuActive === MENU_ITEMS_VALUE.PayoutMethods && ( */}
            <>
              {isLoading && <Loader />}
              <ListPayoutMethod data={payoutMethods} onEdit={handleEdit} />
              <Pagination
                pageSize={searchFormValues.pageSize}
                totalCount={totalCount}
                onChange={(e: number) =>
                  setSearchFormValues({ ...searchFormValues, currentPage: e })
                }
                pageNumber={searchFormValues.currentPage}
                onChangePageSize={(e: number) =>
                  setSearchFormValues({ ...searchFormValues, pageSize: e })
                }
                hidden={!payoutMethods.length}
                disableLocationPath={true}
              />
            </>
            {/* )} */}
          </div>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-md"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {myAction === actions.add && <>Add new payout method </>}
            {myAction === actions.edit && <>Edit payout method </>}
          </Modal.Title>
        </Modal.Header>
        {myAction === actions.add && selectedType === '' && (
          <FormAddNewPayoutMethod onSubmit={handleSelectMethod} />
        )}
        {myAction === actions.add && selectedType === PAYOUT_METHOD.PAYOUT_METHOD_TYPE_1 && (
          <FormAddPayoutType1
            wallets={wallets}
            onSubmit={handleAddPayout1}
            onCancel={() => setShowModal(false)}
          />
        )}
        {myAction === actions.add && selectedType === PAYOUT_METHOD.PAYOUT_METHOD_TYPE_2 && (
          <FormAddPayoutType2
            wallets={wallets}
            onSubmit={handleAddPayout2}
            onCancel={() => setShowModal(false)}
          />
        )}
        {itemEdit &&
          myAction === actions.edit &&
          selectedType === PAYOUT_METHOD.PAYOUT_METHOD_TYPE_2 && (
            <FormAddPayoutType2
              wallets={wallets}
              onSubmit={handleUpdatePayout2}
              onCancel={() => setShowModal(false)}
              data={itemEdit}
            />
          )}
        {itemEdit &&
          myAction === actions.edit &&
          selectedType === PAYOUT_METHOD.PAYOUT_METHOD_TYPE_1 && (
            <FormAddPayoutType2
              wallets={wallets}
              onSubmit={handleUpdatePayout1}
              onCancel={() => setShowModal(false)}
              data={itemEdit}
            />
          )}
      </Modal>
    </>
  )
}

export default PayoutMethodManagement
