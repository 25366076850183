import ModalLogin from 'components/modal-login'
import React, { useEffect, useState } from 'react'

function LoginPage() {
  const [isShowModal, setIsShowModal] = useState(true)
  const onCloseModal = () => {
    setIsShowModal(false)
  }
  return (
    <>
      <img
        src="/assets/img/mascot/bg-bee.jpg"
        alt="VMM Cashback"
        width={'100%'}
        height={'100%'}
        className="img-err"
      />
      <div className="position-absolute start-0 end-0 text-center" style={{ top: '83%' }}>
        <div className="page-login">
          <ModalLogin isShowModal={isShowModal} onCloseModal={onCloseModal} />
        </div>
      </div>
    </>
  )
}

export default LoginPage
