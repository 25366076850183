import {
  formatCoin,
  formatDate,
  formatStableCoin,
  formatStableCoinUSD,
} from 'components/commons-function'
import { SavingAccountStatus } from 'helpers/constants'
import { SavingAccount, SavingTerm } from 'models'
import React, { useEffect, useState } from 'react'
interface TypeProps {
  data: Array<SavingAccount> | undefined
}
interface DataType extends SavingAccount {
  isChecked: boolean
}
function ListSaver(props: TypeProps) {
  const { data } = props
  const [list, setList] = useState<Array<DataType>>([])
  useEffect(() => {
    setList([])
    if (data && data.length > 0) {
      const mydata = data?.map((item) => {
        return { isChecked: false, ...item } as DataType
      })
      setList(mydata ?? [])
    }
  }, [data])

  return (
    <>
      {data && (
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead>
              <tr className="text-muted">
                <th></th>
                <th>Username</th>
                <th>Status</th>
                <th className="text-end">Quantity</th>
                <th className="text-end">Value in USD</th>
                <th className="text-end">Period</th>
                <th className="text-end">Annual Interest</th>
                <th className="text-end">Estimate Interest</th>
                <th className="text-end">Paid Interest Total Amount</th>
                <th style={{ paddingLeft: 30 }}>Open date</th>
                <th>Maturity Date</th>
                <th>Interest Payment Cycle</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item) => (
                <tr key={item.id} className={`row-of-tbl${item.isChecked ? '-selected' : ''}`}>
                  <td></td>
                  <td>
                    <b>{item.profile?.username}</b>
                  </td>
                  <td>
                    {item.status && (
                      <span
                        className={`badge badge-sm ${
                          item.status === SavingAccountStatus.New
                            ? 'bg-pink'
                            : item.status === SavingAccountStatus.InProgress
                            ? 'bg-yellow'
                            : item.status === SavingAccountStatus.Cancelled
                            ? 'bg-secondary'
                            : item.status === SavingAccountStatus.Completed
                            ? 'bg-success'
                            : 'bg-warning'
                        }`}
                      >
                        {item.status}
                      </span>
                    )}
                  </td>
                  <td className="text-end">
                    {formatCoin(item.currencyAmount)} {item.currencyUnit}
                  </td>
                  <td className="text-end">{formatStableCoinUSD(item.baseAmount)}</td>
                  <td className="text-end">{item.months}</td>
                  <td className="text-end">{formatCoin(item.annualInterestRate * 100)}%</td>
                  <td className="text-end">
                    {formatCoin(item.estimatedInterestAmount)} {item.receivedUnit}
                  </td>
                  <td className="text-end">
                    {formatCoin(item.paidInterestTotalAmount)} {item.receivedUnit}
                  </td>
                  <td style={{ paddingLeft: 30 }}>
                    <b>{formatDate(item?.startDate)}</b>
                  </td>
                  <td>
                    <b>{formatDate(item?.endDate)}</b>
                  </td>
                  <td>{item?.interestPeriodType}</td>
                  {/* <td>
                    <div className="row gx-2">
                      <div className="col-auto">
                        <button
                          className=" btn btn-primary btn-sm py-0"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Update"
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                      </div>
                      <div className="col-auto">
                        <button
                          className="btn btn-danger btn-sm py-0 text-white"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete"
                        >
                          <i className="bi bi-trash"></i>
                        </button>
                      </div>
                    </div>
                  </td> */}
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default ListSaver
