import { CheckVersionApp } from 'models'
import React, { useEffect, useState } from 'react'
import IsConfirmAlert from 'components/confirmAlert'
import { time } from 'console'
interface TypeProps {
  data: Array<CheckVersionApp>
  onUpdate: Function
}

function ListVersion(props: TypeProps) {
  const { data, onUpdate } = props

  const [list, setList] = useState<Array<CheckVersionApp>>([])
  useEffect(() => {
    setList(data)
  }, [data])
  const handleUpdateAvailable = async (id: string) => {
    let version = prompt('Please enter version name', '')
    if (version != null) {
      const param = { id: id, version: version }
      onUpdate(param)
    }
  }
  return (
    <>
      {data && (
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr className="text-muted">
                  <th>Platform</th>
                  <th>Version</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <b>{item.platform}</b>
                    </td>
                    <td>
                      <b>{item.version}</b>
                    </td>
                    <td>
                      <button
                        type="button"
                        className=" btn btn-primary btn-sm py-0"
                        onClick={() => handleUpdateAvailable(item.id)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Update version"
                      >
                        <i className="bi bi-pencil"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default ListVersion
