import { ModelPaymentMethod } from 'models'
import React, { useEffect, useState } from 'react'
interface TypeProps {
  data: Array<ModelPaymentMethod> | undefined
  onEdit: Function
}
interface DataType extends ModelPaymentMethod {
  isChecked: boolean
}

function ListPayoutMethod(props: TypeProps) {
  const { data, onEdit } = props
  const [list, setList] = useState<Array<DataType>>([])
  useEffect(() => {
    setList([])
    if (data && data.length > 0) {
      const mydata = data?.map((item) => {
        return { isChecked: false, ...item } as DataType
      })
      setList(mydata ?? [])
    }
  }, [data])
  return (
    <>
      {data && data.length > 0 && (
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead>
              <tr className="text-muted">
                <th></th>
                <th>Name</th>
                <th>Desc</th>
                <th>Type</th>
                <th>Status</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item) => (
                <tr key={item.id} className={`row-of-tbl${item.isChecked ? '-selected' : ''}`}>
                  <td></td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{item.type}</td>
                  <td>{item.status}</td>
                  <td>
                    <div className="row gx-2">
                      <div className="col-auto">
                        <button
                          className=" btn btn-primary btn-sm py-0"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          onClick={() => onEdit(item)}
                        >
                          <i className="bi bi-pencil"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {data && data.length === 0 && (
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            className="bi bi-database-exclamation"
            viewBox="0 0 16 16"
          >
            <path d="M12.096 6.223A4.92 4.92 0 0 0 13 5.698V7c0 .289-.213.654-.753 1.007a4.493 4.493 0 0 1 1.753.25V4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16c.536 0 1.058-.034 1.555-.097a4.525 4.525 0 0 1-.813-.927C8.5 14.992 8.252 15 8 15c-1.464 0-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13h.027a4.552 4.552 0 0 1 0-1H8c-1.464 0-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10c.262 0 .52-.008.774-.024a4.525 4.525 0 0 1 1.102-1.132C9.298 8.944 8.666 9 8 9c-1.464 0-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777ZM3 4c0-.374.356-.875 1.318-1.313C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4Z" />
            <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5Zm0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
          </svg>
          <p>No data</p>
        </div>
      )}
    </>
  )
}

export default ListPayoutMethod
