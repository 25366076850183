import { FORMAT_DATE_DATEPICKER } from 'helpers/constants'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { validateRequiredDateTime } from './commons-function'
import moment from 'moment'
function DateRange(props: any) {
  const {
    dafaultDate,
    onChange,
    isClear,
    lbl1,
    lbl2,
    minDateStart,
    validation,
    onError,
    lbl1Require,
    showIcon,
  } = props
  const [dateStart, setDateStart] = useState(dafaultDate?.dateStart)
  const [dateEnd, setDateEnd] = useState(dafaultDate?.dateEnd)
  const [isValidStartDate, setIsValidStartDate] = useState(false)
  const [isValidEndDate, setIsValidEndDate] = useState(false)
  const [errorStartDate, setErrorStartDate] = useState<any>()
  const [errorEndDate, setErrorEndDate] = useState<any>()
  const validateEndDate = (dateStart: any, dateEnd: any) => {
    if (validation && validation.endDate) {
      if (validation.endDate.isRequired) {
        if (!validateRequiredDateTime(dateEnd)) {
          setErrorEndDate({
            isRequired: true,
          })
          return false
        }
      }
      if (validation.endDate.isEqualOrLargerStartDate) {
        if (!moment(dateEnd).isSameOrAfter(dateStart)) {
          setErrorEndDate({
            isEqualOrLargerStartDate: true,
          })
          return false
        }
      }
    }
    setErrorEndDate(null)
    return true
  }
  const validateStartDate = (dateStart: any) => {
    if (validation && validation.startDate) {
      if (validation.startDate.isRequired) {
        if (!validateRequiredDateTime(dateStart)) {
          setErrorStartDate({
            isRequired: true,
          })
          return false
        }
      }
    }
    setErrorStartDate(null)
    return true
  }
  useEffect(() => {
    setIsValidEndDate(validateEndDate(dateStart, dateEnd))
    setIsValidStartDate(validateStartDate(dateStart))
  }, [dateStart, dateEnd])
  useEffect(() => {
    if (onError) {
      onError({
        startDate: errorStartDate,
        endDate: errorEndDate,
      })
    }
  }, [errorStartDate, errorEndDate])
  return (
    <>
      <div className="row align-items-center gx-1">
        <div className="col-md mb-2 position-relative">
          {lbl1 && (
            <label className="mb-1">
              {lbl1}{' '}
              {lbl1Require ? (
                <>
                  <span className="text-danger">*</span>
                </>
              ) : (
                ''
              )}
            </label>
          )}
          <div className="border rounded">
            <div className="row align-items-center gx-0">
              {showIcon && (
                <div className="col-auto">
                  <span className="px-1 bg-white mx-1 input-group-addon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.66797 1.66797V4.16797"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.332 1.66797V4.16797"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.91797 7.57422H17.0846"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 7.08464V14.168C17.5 16.668 16.25 18.3346 13.3333 18.3346H6.66667C3.75 18.3346 2.5 16.668 2.5 14.168V7.08464C2.5 4.58464 3.75 2.91797 6.66667 2.91797H13.3333C16.25 2.91797 17.5 4.58464 17.5 7.08464Z"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.0801 11.4167H13.0875"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.0801 13.9167H13.0875"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99803 11.4167H10.0055"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99803 13.9167H10.0055"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.91209 11.4167H6.91957"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.91209 13.9167H6.91957"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              )}

              <div className="col">
                <div
                  className={`position-relative ${
                    validation && validation.startDate && 'check-valid'
                  }`}
                >
                  <DatePicker
                    placeholderText="From Date"
                    selected={dateStart || ''}
                    onChange={(date: Date) => {
                      setDateStart(date)
                      onChange({ dateStart: date, dateEnd: dateEnd })
                    }}
                    required={validation?.startDate?.isRequired || undefined}
                    selectsStart
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={minDateStart ?? minDateStart}
                    dateFormat={FORMAT_DATE_DATEPICKER}
                    className={`form-control form-group ${
                      validation && validation.startDate && 'check-valid'
                    } ${validation && validation.startDate && isValidStartDate && 'is-valid'} ${
                      !isValidStartDate && 'is-invalid'
                    }`}
                  />
                  {isClear && (
                    <span
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                        padding: '5px 10px',
                        height: '100%',
                        fontSize: 20,
                      }}
                      onClick={() => {
                        setDateStart(null)
                        onChange({ dateStart: null, dateEnd: dateEnd })
                      }}
                      hidden={!dateStart}
                    >
                      <i className="bi bi-x"></i>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`invalid-feedback h-20 ${!isValidStartDate && 'is-invalid'}`}>
            {errorStartDate && errorStartDate.isRequired && ' Start date cannot be empty'}
          </div>
        </div>
        <div className="col-md mb-2 position-relative">
          {lbl2 && <label className="mb-1">{lbl2}</label>}
          <div className="border rounded">
            <div className="row align-items-center gx-0">
              {showIcon && (
                <div className="col-auto">
                  <span className="px-1 bg-white mx-1 input-group-addon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.66797 1.66797V4.16797"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.332 1.66797V4.16797"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.91797 7.57422H17.0846"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 7.08464V14.168C17.5 16.668 16.25 18.3346 13.3333 18.3346H6.66667C3.75 18.3346 2.5 16.668 2.5 14.168V7.08464C2.5 4.58464 3.75 2.91797 6.66667 2.91797H13.3333C16.25 2.91797 17.5 4.58464 17.5 7.08464Z"
                        stroke="#2C3EBA"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.0801 11.4167H13.0875"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.0801 13.9167H13.0875"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99803 11.4167H10.0055"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.99803 13.9167H10.0055"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.91209 11.4167H6.91957"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.91209 13.9167H6.91957"
                        stroke="#2C3EBA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              )}
              <div className="col">
                <div
                  className={`position-relative ${
                    validation && validation.endDate && 'check-valid'
                  }`}
                >
                  <DatePicker
                    placeholderText="To Date"
                    selected={dateEnd || ''}
                    onChange={(date: Date) => {
                      setDateEnd(date)
                      onChange({ dateStart: dateStart, dateEnd: date })
                    }}
                    selectsEnd
                    startDate={dateStart}
                    endDate={dateEnd}
                    minDate={dateStart}
                    dateFormat={FORMAT_DATE_DATEPICKER}
                    required={validation?.startDate?.isRequired || undefined}
                    className={`form-control form-group ${
                      validation && validation.endDate && 'check-valid'
                    } ${validation && validation.startDate && isValidEndDate && 'is-valid'} ${
                      !isValidEndDate && 'is-invalid'
                    }`}
                  />

                  {isClear && (
                    <span
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 1,
                        padding: '5px 10px',
                        fontSize: 20,
                        height: '100%',
                      }}
                      onClick={() => {
                        setDateEnd(null)
                        onChange({ dateStart: dateStart, dateEnd: null })
                      }}
                      hidden={!dateEnd}
                    >
                      <i className="bi bi-x"></i>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`invalid-feedback h-20 ${!isValidEndDate && 'is-invalid'}`}>
            {(errorEndDate && errorEndDate.isRequired && 'End date cannot be empty') ||
              (errorEndDate &&
                errorEndDate.isEqualOrLargerStartDate &&
                ' Start date cannot be larger than End date')}
          </div>
        </div>
      </div>
    </>
  )
}

export default DateRange
