import React, { useEffect, useState } from 'react'
import { getNameLanguage, validateRequiredText } from './commons-function'
import { CKEditor } from 'ckeditor4-react'
import { Form, Modal, ModalFooter } from 'react-bootstrap'
import { GetPackageSettingDto, PackageDescriptionDetail, PackageDtoModel } from 'models'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import moment from 'moment'
import { Tabs, Tab } from 'react-bootstrap'
import FormLimitPackages from './form-limit-packages'
import * as yup from 'yup'
import { useFormik } from 'formik'

const schema = yup.object().shape({
  name: yup.string().required('Package name is required'),
  price: yup
    .number()
    .min(0, 'Price must be greater than or equal to 0')
    .required('Price is required'),
})

interface TypeProps {
  onSubmit: Function
  onClose: Function
  data: GetPackageSettingDto
  showFormLimitEdit?: PackageDtoModel | undefined
  onLimitSubmit(
    monthlyPurchasedReserveByRanks: any,
    monthlyPurchasedPackageLimit: any,
    isOn: any,
  ): void
}
export enum UploadType {
  Receipt = 'Receipt',
  Avatar = 'Avatar',
  Certificate = 'Certificate',
  Contact = 'Contact',
  PackageImage = 'PackageImage',
}

function FormEditPackage(props: TypeProps) {
  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      name: '',
      price: 0,
    },
    onSubmit: () => {
      setShowModalSubmit(true)
    },
  })

  const isMonthlyPass = props.showFormLimitEdit?.entityType
  const [activeTab, setActiveTab] = useState<string>('edit')
  const handleTabSelect = (eventKey: string | null) => {
    if (eventKey) {
      setActiveTab(eventKey)
    }
  }

  const toolbarGroups = [
    { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'clipboard', groups: ['clipboard', 'undo'] },
    { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
    { name: 'forms', groups: ['forms'] },
    '/',
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
    { name: 'links', groups: ['links'] },
    { name: 'insert', groups: ['insert'] },
    '/',
    { name: 'styles', groups: ['styles'] },
    { name: 'colors', groups: ['colors'] },
    { name: 'tools', groups: ['tools'] },
    { name: 'others', groups: ['others'] },
    { name: 'about', groups: ['about'] },
  ]
  const removeButtons =
    'About,Maximize,Image,Flash,Table,HorizontalRule,Smiley,PageBreak,SpecialChar,Iframe,Anchor,Language,BidiRtl,BidiLtr,Blockquote,CreateDiv,Outdent,Indent,CopyFormatting,RemoveFormat,Styles,Source,Save,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Form,Checkbox,Radio,TextField,Select,Textarea,Button,ImageButton,HiddenField,Preview,NewPage,Print,Format,Font,FontSize,ShowBlocks,BGColor,TextColor'
  const editorUrl = 'assets/js/ckeditor.js'
  const { onSubmit, onClose, data } = props
  const [isBlock, setIsBlock] = useState<boolean>(false)
  const [tag, settag] = useState<string>('')
  const [isLoadingImageUpload, setIsLoadingImageUpload] = useState<boolean>(false)
  const [imagePath, setImagePath] = useState<string>('')
  const [inforPackage, setInforPackage] = useState<PackageDescriptionDetail[]>(
    data.packageDescriptions,
  )
  let cloneContent: any[] = data.packageDescriptions
  const [content, setContent] = useState<any>(cloneContent)
  const [showModalSubmit, setShowModalSubmit] = useState<boolean>(false)
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false)
  const [packageTabSelected, setPackageTabSelected] = useState<string[]>()

  useEffect(() => {
    if (data) {
      setInforPackage(data.packageDescriptions)
      setContent(cloneContent)
      setIsBlock(data.isBlockPurchase)
      settag(data.tag)
      setImagePath(data.imageUrl)
      setPackageTabSelected(data.packageTabSelected)

      formik.setValues({
        name: data.name,
        price: data.price,
      })
    }
  }, [data])
  const handleSubmit = () => {
    const details = inforPackage.map((x: PackageDescriptionDetail) => {
      return {
        ...x,
        description: content?.find((i: any) => i.languageId === x.languageId)?.description,
      }
    })
    const model = {
      ...data,
      imageUrl: imagePath,
      isBlockPurchase: isBlock,
      tag: tag,
      name: formik.values.name,
      price: formik.values.price,
      packageDescriptions: details,
    } as GetPackageSettingDto
    onSubmit(model)
  }

  const onCancelConfirm = (e: any) => {
    setShowModalCancel(true)
  }

  const onChangeContent = (evt: any, item: any) => {
    var newContent = evt.editor.getData()
    const details = cloneContent.map((x: any) => {
      if (x.languageId === item.languageId) {
        return {
          ...x,
          description: newContent,
        }
      }
      return x
    })
    cloneContent = details
    setContent(cloneContent)
  }
  const handleFileInput = (e: any) => {
    if (!e?.target?.files || e?.target?.files?.length <= 0) {
      return
    }
    setIsLoadingImageUpload(true)
    const fileUpload = e.target.files[0] as File
    const formData = new FormData()
    formData.append('file', fileUpload, fileUpload?.name)

    axiosClient()
      .post(END_POINTS.AZURE_STORAGE.UPLOAD_ADMIN(UploadType.PackageImage), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        setIsLoadingImageUpload(false)
        setImagePath(res.data)
      })
      .catch(() => {
        setIsLoadingImageUpload(false)
      })
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="mb-3">
          <Tab eventKey="edit" title="Edit">
            <div className="row p-3">
              <div className="col-12 mb-2 px-4 py-2">
                <div className="row border rounded px-4 py-2">
                  <div className="col-6">
                    <div className="mb-2">Updated at </div>
                    <div>
                      <strong>{moment(data?.lastModified).format('YYYY-MM-DD HH:mm:ss ')}</strong>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-2">Updated by</div>
                    <div>
                      <strong>{data?.lastModifiedBy}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8 mb-3">
                <div className="row">
                  <div className="col-12 mb-3">
                    <p className="text-dark mb-1">
                      <strong>Name</strong>
                    </p>
                    <input
                      className="form-control border"
                      type="text"
                      value={formik.values.name}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {formik.errors && <p className="error-msg">{formik.errors.name}</p>}
                  </div>
                  <div className="col-12 mb-3">
                    <p className="text-dark mb-1">
                      <strong>Price</strong>
                    </p>
                    <input
                      className="form-control border"
                      type="number"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="price"
                      min={0}
                      required
                    />

                    {formik.errors && <p className="error-msg">{formik.errors.price}</p>}
                  </div>
                  <div className="col-12 mb-3">
                    <p className="text-dark mb-1">
                      <strong>Status</strong>
                    </p>
                    {
                      <select
                        className=" form-select form-control border w-100"
                        value={tag}
                        onChange={(e) => {
                          settag(e.target.value)
                          setIsBlock(e.target.value === 'SoldOut')
                        }}
                      >
                        {packageTabSelected?.map((rank: string) => (
                          <option key={rank} value={rank}>
                            {rank}
                          </option>
                        ))}
                      </select>
                    }
                  </div>

                  <div className="row mt-3">
                    <div className="col-6 mb-3">
                      <Form.Check
                        label="Block purchase"
                        type="switch"
                        checked={isBlock}
                        onChange={(e: any) => {
                          setIsBlock(e.target.checked)
                          settag(e.target.checked ? 'SoldOut' : 'none')
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mb-3">
                <div className="row">
                  <div className="col-12 mb-3 position-relative">
                    <p className="text-dark mb-1 text-center">
                      <strong>Package icon</strong>
                    </p>
                    <div className="text-center">
                      {isLoadingImageUpload && (
                        <div className="avatar avatar-140">
                          <div className="loading-data">
                            <div className="spinner-border text-primary" role="status">
                              <span className="sr-only"> </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {!isLoadingImageUpload && (
                        <img
                          src={imagePath || data.imageUrl}
                          className="avatar avatar-140"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="text-center mt-2 d-flex align-items-center justify-content-center">
                      <input
                        disabled={isLoadingImageUpload}
                        type="file"
                        multiple={false}
                        accept=".png,.jpg,.jpeg,.heic,.HEIC"
                        id="uploadReceipt"
                        className="opactity-0 position-absolute top-0 start-0 h-100 w-100 z-index-1 pointer"
                        onChange={(e) => handleFileInput(e)}
                      />
                      <button
                        style={{ height: '28px', fontSize: '14px' }}
                        className="btn btn-outline-primary d-flex align-items-center justify-content-center"
                        type="button"
                        disabled={isLoadingImageUpload}
                      >
                        <img
                          className="me-1"
                          src={'/assets/img/packs/iconChangePackage.svg'}
                          alt=""
                        />
                        <span className="d-inline">Change</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-dark mb-1">
                <strong>Description</strong>
              </p>
              {inforPackage?.length > 0 &&
                inforPackage?.map((item: PackageDescriptionDetail, index) => {
                  return (
                    <div className="row" key={item.languageId}>
                      <div className="col-12 col-md-12 mb-2">
                        <div
                          className={`form-group mt-3 position-relative check-valid ${
                            validateRequiredText(content[index]?.description) && 'is-valid'
                          }`}
                        >
                          <label
                            className="mb-1 d-flex align-items-center"
                            style={{ color: '#2C3EBA' }}
                          >
                            <img
                              className="me-1"
                              src={'/assets/img/packs/iconoir_language.svg'}
                              alt=""
                            />
                            {getNameLanguage(item?.languageCulture?.split('-')?.[0])}
                          </label>
                          <CKEditor
                            name={`${item.languageId}content`}
                            initData={item.description || ''}
                            config={{
                              toolbarGroups: toolbarGroups,
                              removeButtons: removeButtons,
                              editorUrl: editorUrl,
                            }}
                            onChange={(e) => {
                              onChangeContent(e, item)
                            }}
                          />
                        </div>
                        <div className={`invalid-feedback is-invalid}`}>
                          Content cannot be empty.
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </Tab>
          {isMonthlyPass === 'MonthlyPass' && (
            <Tab eventKey="limitPackages" title="Limit Packages">
              <FormLimitPackages
                showFormLimitEdit={props.showFormLimitEdit}
                onLimitSubmit={(
                  monthlyPurchasedReserveByRanks,
                  monthlyPurchasedPackageLimit,
                  isOn,
                ) =>
                  props.onLimitSubmit(
                    monthlyPurchasedReserveByRanks,
                    monthlyPurchasedPackageLimit,
                    isOn,
                  )
                }
              />
            </Tab>
          )}
        </Tabs>
        <div className="mb-4"></div>
        <div className="d-flex gap-4 justify-content-center">
          <button className="btn btn-outline-theme w-25" type="button" onClick={onCancelConfirm}>
            <span className="d-inline">Cancel</span>
          </button>
          <button className="btn btn-theme w-25" type="submit">
            <span className="d-inline">Submit</span>
          </button>
        </div>
      </form>

      <Modal
        show={showModalSubmit}
        centered
        onHide={() => {
          setShowModalSubmit(false)
        }}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row">
            <h3 className="col-12 text-center">
              Please check carefully before saving this package.
            </h3>
          </div>
        </Modal.Body>
        <ModalFooter className="justify-content-center">
          <div className="row">
            <div className="col-auto">
              <button className="btn btn-lg btn-theme" type="button" onClick={handleSubmit}>
                <span className="d-inline">Yes</span>
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-lg btn-outline-theme"
                type="button"
                onClick={() => setShowModalSubmit(false)}
              >
                <span className="d-inline">No</span>
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <Modal
        show={showModalCancel}
        centered
        onHide={() => {
          setShowModalCancel(false)
        }}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="row">
            <h3 className="col-12 text-center">
              Are you sure you want to cancel? All changes will not be saved.
            </h3>
          </div>
        </Modal.Body>
        <ModalFooter className="justify-content-center">
          <div className="row">
            <div className="col-auto">
              <button className="btn btn-lg btn-theme" type="button" onClick={() => onClose()}>
                <span className="d-inline">Yes</span>
              </button>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-lg btn-outline-theme"
                type="button"
                onClick={() => setShowModalCancel(false)}
              >
                <span className="d-inline">No</span>
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default FormEditPackage
