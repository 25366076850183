import swal from 'sweetalert'
export default function IsConfirmAlert(
  title: string,
  text: string,
  icon: string,
  dangerMode: boolean,
  callback: Function,
) {
  swal({
    title: title,
    icon: icon,
    text: text,
    dangerMode: dangerMode || false,
    buttons: {
      cancel: true,
      confirm: true,
    },
  }).then((isConfirm) => {
    callback(isConfirm && isConfirm === true)
  })
  //   console.log(isConfirm.value)
  //   return isConfirm.value
}
