import { ModelRank, PackageDtoModel } from 'models'
import React, { useCallback, useEffect, useState } from 'react'
import PubSub from 'pubsub-js'
import { Form } from 'react-bootstrap'
import { LOADING } from 'helpers/constants'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import Swal from 'sweetalert2'
function FormLimitPackages({
  showFormLimitEdit,
  onLimitSubmit,
}: {
  showFormLimitEdit?: PackageDtoModel | undefined
  onLimitSubmit(
    monthlyPurchasedReserveByRanks: any,
    monthlyPurchasedPackageLimit: any,
    isOn: any,
  ): void
}) {
  const myStyle = {
    borderRadius: '5px',
    color: 'var(--bs-modal-color)',
    width: '100%',
  }
  const inputStyle = {
    borderRadius: '5px',
    border: '1px solid black',
    padding: '5px',
  }
  const disabled = {
    opacity: '0.5',
    cursor: 'not-allowed',
  }
  const [dataRank, setDataRank] = useState<ModelRank[] | null>(null)
  const [monthlyPurchasedReserveByRanks, setMonthlyPurchasedReserveByRanks] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOn, setIsOn] = useState(showFormLimitEdit?.hasMonthlyLimitAndReserve)
  const [monthlyPurchasedPackageLimit, setMonthlyPurchasedPackageLimit] = useState(
    showFormLimitEdit?.monthlyPurchasedPackageLimit,
  )

  const handleTotalLimitChange = (event: any, key: any) => {
    setMonthlyPurchasedPackageLimit(event.target.value > 0 ? parseInt(event.target.value, 10) : 0)
  }
  const handleRankReserveChange = (index: number, value: any) => {
    if (dataRank) {
      const newData = [...dataRank]
      newData[index].reserve = value > 0 ? parseInt(value, 10) : 0
      setDataRank(newData)
      const updatedMonthlyPurchasedReserveByRanks = newData.map((item) => item.reserve)
      setMonthlyPurchasedReserveByRanks(updatedMonthlyPurchasedReserveByRanks)
    }
  }
  const loadData = useCallback(async () => {
    setIsLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setIsLoading(data)
    })
    try {
      const query = await axiosClient().get(END_POINTS.DATA.GET_RANK(), {})
      const result: ModelRank[] = query.data
      const newData = result.map((item, index) => ({
        ...item,
        reserve: showFormLimitEdit?.monthlyPurchasedReserveByRanks?.[index] || 0,
      }))
      setDataRank(newData)
      setMonthlyPurchasedReserveByRanks(newData.map((item) => item.reserve))
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error Load Data',
        text: String(error),
      })
    } finally {
      setIsLoading(false)
    }
  }, [])
  useEffect(() => {
    loadData()
  }, [loadData])
  const handleToggle = () => {
    setIsOn(!isOn)
  }
  useEffect(() => {
    setMonthlyPurchasedReserveByRanks(showFormLimitEdit?.monthlyPurchasedReserveByRanks || [])
  }, [showFormLimitEdit?.monthlyPurchasedReserveByRanks])
  useEffect(() => {
    onLimitSubmit(monthlyPurchasedReserveByRanks, monthlyPurchasedPackageLimit, isOn)
  }, [monthlyPurchasedReserveByRanks, monthlyPurchasedPackageLimit, isOn])
  return (
    <>
      <div className="container-fluid" style={myStyle}>
        <div className="width-limit-packages">
          <div className="card-header">
            <div className="row">
              <div className="table-responsive">
                <form className="responsive">
                  <div className="d-flex justify-content-between">
                    <div className="row my-2 mx">
                      <label htmlFor="">
                        <h6>Limit package:</h6>
                      </label>
                      <input
                        placeholder="Input mount limit"
                        className={`my-2 mx-2 w-50 ${isOn ? '' : 'disabled'}`}
                        style={inputStyle}
                        type="number"
                        value={monthlyPurchasedPackageLimit || ''}
                        disabled={!isOn}
                        min={0}
                        onChange={(e) => handleTotalLimitChange(e, showFormLimitEdit?.id)}
                      />
                    </div>
                    <div className="row my-2">
                      <div className="col-6 mb-3">
                        <Form.Check
                          label="On/Off"
                          type="switch"
                          checked={isOn}
                          onChange={handleToggle}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6 className="mb-0 fw-semibold mt-2">
                      Reserve by rank:<br></br>
                    </h6>
                  </div>
                  <div className="flex row justify-between">
                    <div className="row my-2">
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'space-between',
                        }}
                      >
                        {dataRank?.map((item, index) => (
                          <div
                            className="d-flex row"
                            key={item.id}
                            style={{ flex: '0 0 50%', padding: '10px' }}
                          >
                            <label htmlFor="">
                              <h6>{item.name}</h6>
                            </label>
                            {isOn ? (
                              <input
                                min={0}
                                placeholder="Input amount reserve"
                                className={`my-2`}
                                style={inputStyle}
                                value={monthlyPurchasedReserveByRanks[index] || ''}
                                type="number"
                                onChange={(e) => {
                                  handleRankReserveChange(index, e.target.value)
                                }}
                              />
                            ) : (
                              <input
                                min={0}
                                placeholder="Input amount reserve"
                                className={`my-2`}
                                style={inputStyle}
                                value={monthlyPurchasedReserveByRanks[index] || ''}
                                type="number"
                                disabled
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-2"></div>
      </div>
    </>
  )
}
export default FormLimitPackages
