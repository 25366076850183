import Loader from 'components/loader'
import { PAYMENT_METHOD_V2 } from 'helpers/constants'
import { PackageDtoModel, I2PaymentMethod, ModelPaymentMethodItem } from 'models'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'
export const MENU_ITEMS = {
  Single: 'Single',
  Combine: 'Combine',
  Vlinkpay: 'Vlinkpay',
  Trc20: 'Trc20',
}

export const MENU_ITEMS_VALUE = {
  Single: 'Single',
  Combine: 'Combine',
  Vlinkpay: 'Vlinkpay',
  Trc20: 'Trc20',
}
export const MENU = [
  {
    label: MENU_ITEMS.Single,
    value: MENU_ITEMS_VALUE.Single,
  },
  {
    label: MENU_ITEMS.Combine,
    value: MENU_ITEMS_VALUE.Combine,
  },

  {
    label: MENU_ITEMS.Vlinkpay,
    value: MENU_ITEMS_VALUE.Vlinkpay,
  },
  {
    label: MENU_ITEMS.Trc20,
    value: MENU_ITEMS_VALUE.Trc20,
  },
]
interface TypeProps {
  onSubmit: Function
  onCancel: Function
  selectedPackage: PackageDtoModel | undefined
  currentPaymentForPackage: I2PaymentMethod[]
  paymentMethods: ModelPaymentMethodItem[]
  isLoading?: boolean
}
function EditPackagePayment(props: TypeProps) {
  const { paymentMethods, onSubmit, onCancel, isLoading, currentPaymentForPackage } = props
  // todo: lấy các phương thức thanh toán từ  currentPaymentForPackage đưa vào các state: selectedSingleMethods, selectedVlinkpayMethods ...
  const [menuActive, setMenuActive] = useState(MENU_ITEMS_VALUE.Single)
  const [selectedSingleMethods, setSelectedSingleMethods] = useState<any[]>([])
  const [selectedVlinkpayMethods, setSelectedVlinkpayMethods] = useState<any[]>([])
  const [selectedCombineMethods, setSelectedCombineMethods] = useState<any[]>([])
  const [selectedTrc20Methods, setSelectedTrc20Methods] = useState<any[]>([])

  useEffect(() => {
    if (currentPaymentForPackage) {
      const singlePayment = currentPaymentForPackage.filter(
        (i) => i.type === PAYMENT_METHOD_V2.SINGLE,
      )
      const combinePayment = currentPaymentForPackage.filter(
        (i) => i.type === PAYMENT_METHOD_V2.COMBINE,
      )
      const vlinkpayPayment = currentPaymentForPackage.filter(
        (i) => i.type === PAYMENT_METHOD_V2.VLINKPAY,
      )

      const trc20Payment = currentPaymentForPackage.filter(
        (i) => i.type === PAYMENT_METHOD_V2.TRC20,
      )
      if (singlePayment.length > 0) {
        const arr: any[] = extractPaymentMethodDetails(singlePayment) || []
        setSelectedSingleMethods(arr)
      }
      if (combinePayment.length > 0) {
        const arr: any[] = extractPaymentMethodDetails(combinePayment) || []
        setSelectedCombineMethods(arr)
      }
      if (vlinkpayPayment.length > 0) {
        const arr: any[] = extractPaymentMethodDetails(vlinkpayPayment) || []
        setSelectedVlinkpayMethods(arr)
      }
      if (trc20Payment.length > 0) {
        const arr: any[] = extractPaymentMethodDetails(trc20Payment) || []
        setSelectedTrc20Methods(arr)
      }
    }
  }, [currentPaymentForPackage])
  function extractPaymentMethodDetails(data: I2PaymentMethod[]) {
    return data.map((method) => ({
      randomId: uuidv4(),
      methodId: method.id,
      name: method.name,
    }))
  }
  const handleAddMethod = () => {
    const initNewData = {
      randomId: uuidv4(),
      methodId: '',
    }
    if (menuActive === MENU_ITEMS.Single) {
      setSelectedSingleMethods([...selectedSingleMethods, initNewData])
    } else if (menuActive === MENU_ITEMS.Combine) {
      setSelectedCombineMethods([...selectedCombineMethods, initNewData])
    } else if (menuActive === MENU_ITEMS.Vlinkpay) {
      setSelectedVlinkpayMethods([...selectedVlinkpayMethods, initNewData])
    } else if (menuActive === MENU_ITEMS.Trc20) {
      setSelectedTrc20Methods([...selectedTrc20Methods, initNewData])
    }
  }
  const handleRemoveMethod = (id: string) => {
    if (menuActive === MENU_ITEMS.Single) {
      const newData = selectedSingleMethods.filter((item) => item.randomId !== id)
      setSelectedSingleMethods([...newData])
    } else if (menuActive === MENU_ITEMS.Combine) {
      const newData = selectedCombineMethods.filter((item) => item.randomId !== id)
      setSelectedCombineMethods([...newData])
    } else if (menuActive === MENU_ITEMS.Vlinkpay) {
      const newData = selectedVlinkpayMethods.filter((item) => item.randomId !== id)
      setSelectedVlinkpayMethods([...newData])
    } else if (menuActive === MENU_ITEMS.Trc20) {
      const newData = selectedTrc20Methods.filter((item) => item.randomId !== id)
      setSelectedTrc20Methods([...newData])
    }
  }

  const updateList = (arr: any[], id: string, name: string, value: any) => {
    return arr.map((item) => {
      if (item.randomId === id) {
        return {
          ...item,
          [name]: value,
        }
      }
      return item
    })
  }

  const handleChangeMethod = (id: string, e: any) => {
    const { name, value } = e.target
    if (menuActive === MENU_ITEMS.Single) {
      const updatedList = updateList(selectedSingleMethods, id, name, value)
      setSelectedSingleMethods(updatedList)
    } else if (menuActive === MENU_ITEMS.Combine) {
      const updatedList = updateList(selectedCombineMethods, id, name, value)
      setSelectedCombineMethods(updatedList)
    } else if (menuActive === MENU_ITEMS.Vlinkpay) {
      const updatedList = updateList(selectedVlinkpayMethods, id, name, value)
      setSelectedVlinkpayMethods(updatedList)
    } else if (menuActive === MENU_ITEMS.Trc20) {
      const updatedList = updateList(selectedTrc20Methods, id, name, value)
      setSelectedTrc20Methods(updatedList)
    }
  }
  const handleSubmit = () => {
    let mergedArray = selectedSingleMethods.concat(
      selectedCombineMethods,
      selectedVlinkpayMethods,
      selectedTrc20Methods,
    )
    mergedArray = mergedArray.filter((item) => item.methodId)

    const paymentMethodIds: string[] = extractMethodIds(mergedArray)

    onSubmit(paymentMethodIds)
  }
  const handleCancel = () => {
    onCancel()
  }
  function extractMethodIds(data: any) {
    return data.map((item: any) => item.methodId)
  }
  function isIdExists(array: any[], idToCheck: string) {
    return array.some((item: any) => item.methodId === idToCheck)
  }
  return (
    <>
      <Modal.Body>
        <ul className="nav nav-tabs justify-content-center nav-WinDOORS nav-lg mb-4 mt-4">
          {MENU.map((item) => (
            <li className="nav-item" key={item.value}>
              <button
                className={`nav-link ${menuActive === item.value ? 'active' : ''}`}
                onClick={() => {
                  setMenuActive(item.value)
                }}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
        <div className="position-relative">
          {isLoading && <Loader />}

          {menuActive === MENU_ITEMS_VALUE.Single && (
            <>
              <h6 className="text-center mb-3">Single payment method with tokens on the system</h6>
              <div>
                {selectedSingleMethods.map((item, index) => (
                  <>
                    <div className="payout-wallet-item mb-4" key={item?.id}>
                      <button
                        type="button"
                        className="btn btn-primary iconbutton"
                        onClick={() => handleRemoveMethod(item.randomId)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>

                      <div className="row gx-1">
                        <div className="col-12">
                          <label>
                            Method <span className="text-danger">*</span>
                          </label>
                          <div className="mb-2 mt-1">
                            <select
                              className="form-select border w-100"
                              value={item.methodId}
                              name="methodId"
                              onChange={(e: any) => handleChangeMethod(item.randomId, e)}
                            >
                              <option disabled value={''}>
                                Select a single method
                              </option>
                              {paymentMethods
                                .filter((a: any) => a.type === PAYMENT_METHOD_V2.SINGLE.toString())
                                ?.map((paymentItem: any) => (
                                  <option
                                    value={paymentItem.id}
                                    key={paymentItem.id}
                                    disabled={isIdExists(selectedSingleMethods, paymentItem.id)}
                                  >
                                    {paymentItem?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
          {menuActive === MENU_ITEMS_VALUE.Combine && (
            <>
              <h6 className="text-center mb-3">Custom payment method with tokens on the system</h6>
              <div>
                {selectedCombineMethods.map((item, index) => (
                  <>
                    <div className="payout-wallet-item mb-4">
                      <button
                        type="button"
                        className="btn btn-primary iconbutton"
                        onClick={() => handleRemoveMethod(item.randomId)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>

                      <div className="row gx-1">
                        <div className="col-12">
                          <label>
                            Method <span className="text-danger">*</span>
                          </label>
                          <div className="mb-2 mt-1">
                            <select
                              className="form-select border w-100"
                              value={item.methodId}
                              name="methodId"
                              onChange={(e: any) => handleChangeMethod(item.randomId, e)}
                            >
                              <option disabled value={''}>
                                Select a combine method
                              </option>
                              {paymentMethods
                                .filter((a: any) => a.type === PAYMENT_METHOD_V2.COMBINE.toString())
                                ?.map((paymentItem: any) => (
                                  <option
                                    value={paymentItem.id}
                                    key={paymentItem.id}
                                    disabled={isIdExists(selectedCombineMethods, paymentItem.id)}
                                  >
                                    {paymentItem?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
          {menuActive === MENU_ITEMS_VALUE.Vlinkpay && (
            <>
              <h6 className="text-center mb-3">Payment via VLINKAPP</h6>
              <div>
                {selectedVlinkpayMethods.map((item, index) => (
                  <>
                    <div className="payout-wallet-item mb-4">
                      <button
                        type="button"
                        className="btn btn-primary iconbutton"
                        onClick={() => handleRemoveMethod(item.randomId)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>

                      <div className="row gx-1">
                        <div className="col-12">
                          <label>
                            Method <span className="text-danger">*</span>
                          </label>
                          <div className="mb-2 mt-1">
                            <select
                              className="form-select border w-100"
                              value={item.methodId}
                              name="methodId"
                              onChange={(e: any) => handleChangeMethod(item.randomId, e)}
                            >
                              <option disabled value={''}>
                                Select a Vlinkpay method
                              </option>
                              {paymentMethods
                                .filter(
                                  (a: any) => a.type === PAYMENT_METHOD_V2.VLINKPAY.toString(),
                                )
                                ?.map((paymentItem: any) => (
                                  <option
                                    value={paymentItem.id}
                                    key={paymentItem.id}
                                    disabled={isIdExists(selectedVlinkpayMethods, paymentItem.id)}
                                  >
                                    {paymentItem?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
          {menuActive === MENU_ITEMS_VALUE.Trc20 && (
            <>
              <h6 className="text-center mb-3">Pay with USDT (TRC20)</h6>
              <div>
                {selectedTrc20Methods.map((item, index) => (
                  <>
                    <div className="payout-wallet-item mb-4">
                      <button
                        type="button"
                        className="btn btn-primary iconbutton"
                        onClick={() => handleRemoveMethod(item.randomId)}
                      >
                        <i className="bi bi-trash"></i>
                      </button>

                      <div className="row gx-1">
                        <div className="col-12">
                          <label>
                            Method <span className="text-danger">*</span>
                          </label>
                          <div className="mb-2 mt-1">
                            <select
                              className="form-select border w-100"
                              value={item.methodId}
                              name="methodId"
                              onChange={(e: any) => handleChangeMethod(item.randomId, e)}
                            >
                              <option disabled value={''}>
                                Select a Trc20 method
                              </option>
                              {paymentMethods
                                .filter((a: any) => a.type === PAYMENT_METHOD_V2.TRC20.toString())
                                ?.map((paymentItem: any) => (
                                  <option
                                    value={paymentItem.id}
                                    key={paymentItem.id}
                                    disabled={isIdExists(selectedTrc20Methods, paymentItem.id)}
                                  >
                                    {paymentItem?.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}

          <button type="button" className="btn btn-md btn-link" onClick={handleAddMethod}>
            <i className="bi bi-plus"></i> Add {menuActive} Method
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer className="">
        <div className="row w-100 align-items-center justify-content-center">
          <div className="col-auto">
            <Button
              variant="secondary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Cancel'}
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="col-auto">
            <Button
              type="submit"
              variant="primary text-white"
              className="border-0"
              data-toggle="tooltip"
              data-placement="top"
              title={'Update'}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

export default EditPackagePayment
