interface ErrorMessage {
  [key: string]: string
}
export const errorMessage: ErrorMessage = {
  congratulations: 'Congratulations!',
  'CATEGORY-CREATE_NAME-EMPTY': 'Name is required',
  'CATEGORY-CREATE-NAME_LONGER_200': 'Name must not exceed 200 characters',
  'CATEGORY-CREATE-NAME_ALREADY_EXIST': 'The specified name already exists',
  'CATEGORY-DELETE-CONTAINING_STORE': 'Unable to delete the category containing the store',
  'CATEGORY-DELETE-NOT_FOUND': 'Not found category',
  'CATEGORY-UPDATE-NAME-EMPTY': 'Name is required',
  'CATEGORY-UPDATE-NAME_LONGER_200': 'Name must not exceed 200 characters',
  'CATEGORY-UPDATE-NAME_ALREADY_EXIST': 'The specified name already exists',
  'CREDIT-CREATE-CARD_NUMBER_EMPTY': 'Name is required',
  'CREDIT-CREATE-CARD_NUMBER_LONGER_200': 'Name must not exceed 200 characters',
  'CREDIT-CREATE-CARD_NUMBER_ALREADY_EXIST': 'The specified name already exists',
  'CREDIT-UPDATE-CARD_NUMBER_EMPTY': 'Name is required',
  'CREDIT-UPDATE-CARD_NUMBER_LONGER_200': 'Name must not exceed 200 characters',
  'CREDIT-UPDATE-CARD_NUMBER_ALREADY_EXIST': 'The card number already exists',
  'COMMON-VALIDATE-PIN_EMPTY': 'PIN is required',
  'COMMON-VALIDATE-PIN_WRONG_LENGTH': 'PIN must be 6 characters',
  'COMMON-VALIDATE-INVALID_PIN': 'Invalid PIN',
  'CREDIT-CREATE-NOT_ACTIVATED_YEARLY_PASS': 'You need activate a Yearly pass',
  'CREDIT-CREATE-CARD_MORE_THEN_X': 'You can only enter up to {{0}} card(s)',
  'NETWORK-CHANGE_SPONSOR-USER_OR_SPONSOR_NOT_FOUND': 'User or sponsor not found',
  'NETWORK-CHANGE_SPONSOR-USER_NETWORK_OR_SPONSOR_NETWORK_NOT_FOUND':
    'User network or sponsor network not found',
  'NETWORK-CHANGE_SPONSOR_USER_DONT_BELONG_SPONSOR': "User doesn't belong to any branch of sponsor",
  'EXCHANGE-TRANSFER_TO_CASHBACK-CLIENT_ID_EMPTY': 'ClientId is required',
  'EXCHANGE-TRANSFER_TO_CASHBACK-SECRET_KEY_EMPTY': 'SecretKey is required',
  'EXCHANGE-TRANSFER_TO_CASHBACK-SYMBOL_EMPTY': 'Symbol is required',
  'EXCHANGE-TRANSFER_TO_CASHBACK-CHECKSUM_EMPTY': 'CheckSum is required',
  'EXCHANGE-TRANSFER_TO_CASHBACK-INVALID_EXPIRED': 'ExpiredUtcAt must greater than 0',
  'EXCHANGE-TRANSFER_TO_CASHBACK-FROM_ADDRESS_EMPTY': 'FromAddress is required',
  'EXCHANGE-TRANSFER_TO_CASHBACK-TO_ADDRESS_EMPTY': 'ToAddress is required',
  'EXCHANGE-TRANSFER_TO_CASHBACK-INVALID_AMOUNT': 'Amount must greater than 0',
  'EXCHANGE-TRANSFER_TO_CASHBACK-MESSAGE_LONGER_500': 'Message must not exceed 500 characters',
  'EXCHANGE-TRANSFER-NO_TRANSACTION_FOUND': 'Transaction not found',
  'EXCHANGE-TRANSFER-WALLET_NOT_FOUND': 'Wallet not found',
  'EXCHANGE-TRANSFER-NOT_ENOUGH_BALANCE_TO_PAY': 'Not enough balance to pay',
  'EXCHANGE-TRANSFER-TRANSACTION_EXPIRATION': 'Transaction expired',
  'COMMON-FIND-CURRENT_USER_NOT_FOUND': 'User not found',
  'MONTHLY_PASS-ACTIVATE-ID_NOT_EXISTS': 'Monthly Pass does not exist',
  'MONTHLY_PASS-ACTIVATE_BY_ADMIN-NOTE_EMPTY': 'Note is required',
  'MONTHLY_PASS-ACTIVATE_BY_ADMIN-USER-ID_EMPTY': 'UserId is required',
  'MONTHLY_PASS-ACTIVATE_BY_ADMIN-USER_NOT_EXISTS': 'Username does not exist',
  'PAYMENT-PROFILE-ADD-MORE_ONE_CARD': 'Cannot add more than one credit card',
  'PAYMENT-PROFILE-ADD-FAI': 'Failed To Create Payment Profile',
  'PAYMENT_PROFILE-UPDATE-CREDIT_CARD_NOT_ADDED':
    "You haven't added any credit cards for payment before",
  'PAYMENT_PROFILE-UPDATE-FAIL': 'Failed To Update Payment Profile',
  'WALLET-TRANSFER-INVALID_AMOUNT': 'Amount must greater than 0',
  'WALLET-TRANSFER-MESSAGE_LONGER_50': 'Message must not exceed 50 characters',
  'WALLET-TRANSFER-RECEIVER_NOT_FOUND': 'Receiver does not exist',
  'YEARLY_PASS-ACTIVATE-NOT_FOUND': 'Yearly Pass does not exist',
  'YEARLY_PASS-ACTIVATE-NOTE_EMPTY': 'Note is required',
  'YEARLY_PASS-ACTIVATE-USER_NOT_FOUND': 'User not found',
  'YEARLY_PASS-GET_PAYMENT_INFO-NOT_ALLOW_ACTIVATE': 'You cannot activate this package',
  'YEARLY_PASS-GET_PAYMENT_INFO-NOT_ALLOW_UPGRADE': 'You cannot upgrade to a smaller package',
  'COMMON-VALIDATE-NOT_AUTHORIZED_TO_ACCESS': 'You are not authorized to access to this resource',
  'COMMON-VALIDATE-NOT_SUPPORT_X': 'Not support {{0}}',
  'COMMON-SUBMIT-REQUEST_X_IS_NULL': 'Request {{0}} is null',
  'COMMON-SEARCH-NOT_FOUND_X': 'User was not found',
  'PAYMENT-PROFILE-ADD-FAIL': 'Failed To Create Payment Profile',
  'AZURESTORE-SAVE-FILE_EXCEEDS_CONTENT_LENGTH': 'File exceeds the request content length',
  'MONTHLY_PASS-BUY-YOU_NOT_JOIN_YEARLY_PASS':
    'Error: In order to purchase this monthly pass, you must first join the Annual Platform fee. Please purchase the annual package before trying again.',
  'MONTHLY_PASS-BUY-YOUR_MEMBER_NOT_JOIN_YEARLY_PASS':
    'Error: In order to purchase this monthly pass, your member must first join the Annual Platform fee. Please purchase the package for him/her before trying again.',
  'MONTHLY_PASS-BUY-YOU_NOT_JOIN_VIP_YEARLY_PASS':
    'Error: In order to purchase this monthly pass, you must first join the VIP Business for Annual Platform fee. Please purchase the VIP business package before trying again.',
  'MONTHLY_PASS-BUY-YOUR_YEARLY_PASS_IS_NOT_VIP':
    'Error: Your Annual Platform fee is currently activated, but in order to purchase the selected monthly pass, please upgrade to the VIP business first.',
  'MONTHLY_PASS-BUY-YOUR_MEMBER_NOT_JOIN_VIP_YEARLY_PASS':
    'Error: In order to purchase this monthly pass, your member must first join the VIP Business for Annual Platform fee. Please purchase the VIP business package for him/her before trying again.',
  'MONTHLY_PASS-BUY-YOUR_MEMBER_YEARLY_PASS_IS_NOT_VIP':
    "Error: Your member's Annual Platform fee is currently activated, but in order to give this member the selected monthly pass, he/she has to upgrade to the VIP business first.",
  'MONTHLY_PASS-ACTIVATE-MORE_THAN_ONE_PASS':
    'Cannot activate more than 1 Monthly Pass. Please wait until finishing current Monthly Plan first.',
  'MONTHLY_PASS-DELETE-CONTAINS_PASS_CODE': 'Cannot delete In-User Monthly Pass',
  'NETWORK-TREE-GET-NOT_ACTIVATED_VIP_PASS':
    'This account has not activated the VIP Annual package, so it does not exist in the tree',
  'NETWORK-TREE-GET-NOT_FOUND_USER_NETWORK': 'User network not found action',
  'PAYMENT_PROFILE-GET-NOT_FOUND': "You haven't added any credit cards for payment before",
  'COMMON-SUBMIT-VALUE_X_IS_NULL': '{{0}} is null',
  'COMMON-HANDLE-INVALID_X': '{{0}} is invalid',
  'DAILY_BONUS-SET_WALLET-DO_NOT_HAVE_DAILY_BONUS':
    "You don't have daily bonus to set received wallet",
  'DAILY_BONUS-SET_WALLET-ALLREADY_SET_WALLET':
    'You have already set wallet to receive daily bonus',
  'CREADIT_CARD-PAYMENT-CARD_NUMBER_INVALID': "'Card Number' is not valid",
  'CREDIT_CARD-PAYMENT_DISABLED': 'Credit Card Payment is not enabled.',
  'NETWORK-TREE-GET-NOT_ALLOW_ADD_TO_TREE': 'Cannot add this user node to network tree',
  'RECEIPT-NOT_EXIST_CREDIT_INFO': 'Credit card is invalid',
  'RECEIPT-VALUE_MUST_LARGER_THAN_ZERO': 'Amount must be larger than zero',
  'AZURESTORE-UPLOAD-INVALID_FILE': 'Unsupported image format or invalid image',
  'AZURESTORE-UPLOAD-INVALID_FILE_EXTENSION_X': 'The system only supports file types: {{0}}',
  'AZURESTORE-UPLOAD-INVALID_FILE_SIZE_X': 'You are only allowed to upload file up to {{0}}MB',
  'AZURESTORE-UPLOAD-EMPTY_FILE': 'The file is empty',
  'AZURESTORE-UPLOAD-EMPTY_FILE_NAME': 'The file name is empty',
  'AZURESTORE-UPLOAD-CAN_NOT_OPEN_FILE': "The file can't be open",
  'RECEIPT-CREDIT_INFO_IS_EMPTY': 'Credit Card is emtpty',
  'RECEIPT-SHOP_NAME_IS_EMPTY': 'Shop name is emtpty',
  'RECEIPT-CURRENCY_IS_EMPTY': 'Currency is emtpty',
  'RECEIPT-USED_DATE_IS_NULL': 'Used date is null',
  'RECEIPT-IMAGE_PATH_IS_EMPTY': 'Image path is empty',
  'RECEIPT-VALUE_MUST_LOWER_THAN_X': 'Total amount does not exceed {{0}}',
  'RECEIPT-APPROVE-RECEIPT_ID_DOES_NOT_EXIST': 'Receipt does not exist in the system',
  'RECEIPT-CANNOT_DELETE_RECEIPT': 'Can not delete this receipt',
  'RECEIPT-ADD-NOT_ACTIVATED_MONTHLY_PASS':
    'Please purchase a monthly pass before uploading your receipts',
  'RECEIPT-RE_UPLOAD-INVALID_STATUS': "You can't re-upload for this receipt. Please refresh page.",
  'RECEIPT-APPROVE-INVALID_STATUS': 'Cannot approve this receipt',
  'RECEIPT-REJECT-INVALID_STATUS': 'Cannot reject this receipt',
  'RECEIPT-EDIT-INVALID_STATUS': 'Cannot edit this receipt',
  'RECEIPT-RE_UPLOAD-INVALID_USED_DATE': 'You are unable to select a future date.',
  'RECEIPT-UPLOAD-INVALID_USED_DATE': 'You are unable to select a future date.',
  'RECEIPT-ASK_RE_UPLOAD-INVALID_STATUS': "You just can't ask for {{0}} receipt",
  'RECEIPT-RE_UPLOAD-RECEIPT_NOT_FOUND': 'Receipt does not exist',
  'MONTHLY_PASS-ACTIVATE-NOT_ALLOW_FOR_NEWBIE':
    'This package is not for members who have never activated any package, please choose another package',
  'MONTHLY_PASS-ACTIVATE-REACH_PURCHASE_LIMITATION':
    'This package has reached the purchase limitation, please choose another package',
  'LUCKY_TICKET-GENERATE-NOT_FOUND_USERNAME': 'The system cannot find your account',
  'LUCKY_TICKET-GENERATE-GUEST_CODE_DOES_NOT_EXIST': 'The code does not exist',
  'LUCKY_TICKET-GENERATE-GUEST_CODE_ALREADY_USED': 'The code already used by another account',
  'LUCKY_TICKET-GENERATE-ACCOUNT_HAS_BEEN_ACTIVAED_OTHER_CODE':
    'Your account has been activated another code',
  'MEMBER_SUPPORT-FULLNAME_EMPTY': 'Fullname is required',
  'MEMBER_SUPPORT-FULLNAME_MAXLENGTH': 'Fullname must be maximum 30 characters',
  'MEMBER_SUPPORT-USERNAME_EMPTY': 'Username is required',
  'MEMBER_SUPPORT-USERNAME_MAXLENGTH': 'Username must be maximum 16 characters',
  'MEMBER_SUPPORT-ISSUES_EMPTY': 'Issue is required',
  'MEMBER_SUPPORT-CONTENT_EMPTY': 'Content is required',
  'MEMBER_SUPPORT-EMAIL_EMPTY': 'Email is required',
  'MEMBER_SUPPORT-CONTENT_MAXLENGTH': 'Content must be maximum 300 characters',
  'MEMBER_SUPPORT-MAX_LENGTH': 'Issue must be maximum 100 characters',
  'MONTHLY_PASS-ACTIVATE-NOT_ACTIVATE_3_MONTHS_PASS':
    'This package is only for those accounts that have activated the 3-month membership.',
  'MONTHLY_PASS-BUY-EXIST_DATA_RECEIPT':
    'Unable to activate because you are using DATA RECEIPT package.',
  'PACKAGE-ACTIVATE-NOT_ACTIVATE_DR_3_YET':
    'This package is only for accounts that have activated the DATA RECEIPT 3-month.',
  'PACKAGE-ACTIVATE-MEMBERSHIP_MUST_BE_VALID_MORE_THAN_1M':
    'You can only activate when the monthly membership package is valid for more than 1 month.',
  'PACKAGE-ACTIVATE-CURRENT_MONTHLY_PASS_IS_NOT_3M':
    'This package is only for those accounts that have activated the 3-month membership.',

  'LUCKY_TICKET-CHECK_IN-CODE_DOES_NOT_EXIST': 'The code does not exist',
  'LUCKY_TICKET-CHECK_IN-CODE_HAS_BEEN_CHECKED_IN': 'The code has been checked-in',
  'LUCKY-ANNIVERSARY-GALA-DINNER':
    'Congratulations on winning {{0}} in the 4th anniversary event of VLINKGROUP',

  'MONTHLY_PASS-BUY-RECEIVER_NOT_FOUND': 'Receiver not found',
  'YEARLY_PASS-BUY-RECEIVER_NOT_FOUND': 'Receiver not found',
  'MONTHLY_PASS-BUY-RECEIVER_ALREADY_ACTIVATED': 'Receiver already has an active monthly pass',
  'YEARLY_PASS-BUY-RECEIVER_ALREADY_ACTIVATED': 'Receiver already has an active yearly pass',
  'MONTHLY_PASS-ACTIVATE-USER_NOT_FOUND': 'User not found',
  'EXCHANGE-GET_PRICE-CANNOT_GET_PRICE_0':
    "Can't get the price of {{0}}. Please try again in a few minutes",
  'PACKAGE-ACTIVATE-NOT_FOUND': 'Package not found',
  'PACKAGE-ACTIVATE-NOT_ACTIVATE_MONTHLY_PASS':
    'You are not qualified for this package. Please purchase our Monthly pass and Annual platform fee (Business VIP)',
  'PACKAGE-ACTIVATE-NOT_ACTIVATE_BUSINESS_VIP':
    'You are not qualified for this package. Please purchase our Monthly pass and Annual platform fee (Business VIP)',
  'PACKAGE-ACTIVATE-ALREADY_EXIST_BIG_DATA':
    'You have already activated the Bigdata package, so it cannot be activated this package',
  'PACKAGE-ACTIVATE-YEARY_PASS_MISSING_TIME':
    'You need to activate the "annual platform fee" more than the period of using this plan',
  'PACKAGE-ACTIVATE-MONTHLY_PASS_MISSING_TIME':
    'You need to activate the monthly pass a quarter for more than the duration of this package',
  'COMMON-FIND-USERNAME_EXISTS': 'Username already exists',
  'COMMON-FIND-EMAIL_EXISTS': 'Email already exists',
  'USER-CHANGE-NOT_ENOUGH_FEE': 'Balance is not enough',
  'USER-CHANGE-REQUEST_NOT_FOUND': 'The confirmation link is not valid',
  'USER-CHANGE-REQUEST_EXPIRED': 'The confirmation link has expired. Please request a new one',
  'USER-CHANGE-REQUEST_FAILED': 'The confirmation link is not working',
  'USER-CHANGE-INVALID_CODE': 'The confirmation link is not valid',
  'WALLET-TRANSFER-NOT_FOUND': 'Wallet not found',
  'WALLET-TRANSFER-AMOUNT_IS_ZERO': 'Amount should be greater than 0',
  'WALLET-TRANSFER-BALANCE_NOT_ENOUGH': 'Balance is not enough',
  'MONTHLY_PASS_TRAVEL-BUY-USER_ALREADY_ACTIVATED': 'You already had an active Bigdata Travel',
  'MONTHLY_PASS_TRAVEL-BUY-RECEIVER_ALREADY_ACTIVATED':
    'Member already had an active Bigdata Travel',
  'WALLET-TRANSFER-SELF_TRANSFER': 'Cannot tranfer to yourself',
  'MONTHLY_PASS-ACTIVATE-ACTION_NOT_SUPPORTED': 'The action is not supported for this package',
  'MONTHLY_PASS-ACTIVATE-ACTIVATING_IN_DEBT_NOT_SUPPORTED':
    'Activating in debt is not supported for this package',
  'MONTHLY_PASS-ACTIVATE-ACTIVATING_FROM_LAST_EXPIRATION_NOT_SUPPORTED':
    'Activating from last expiration date is not supported for this package',
  'MONTHLY_PASS-UPGRADE-OLD_MONTHLY_PASS_NOT_EXIST': 'The selected monthly passcode does not exist',
  'MONTHLY_PASS-UPGRADE-CONTAIN_NON_UPGRADABLE_PACKAGES':
    'The monthly passcodes to upgrade contain non-upgradable packages',
  'MONTHLY_PASS-UPGRADE-CANNOT_UPGRADE_FUTURE_PACKAGE':
    'The upgraded monthly passcode cannot start activation in future',
  'MONTHLY_PASS-UPGRADE-UPGRADING_IN_DEBT_NOT_SUPPORTED':
    'Upgrading in debt is not supported for this package',
  'MONTHLY_PASS-UPGRADE-NEW_PACKAGE_CANNOT_COVER_ALL_QUEUED_PACKAGES':
    'The upgraded monthly passcode cannot cover all queued packages in future',
  'DEBT-ADD-USER_ID_EMPTY': 'Please select a user for debt',
  'DEBT-ADD-USER_NOT_EXIST': 'User does not exist. Please select another one',
  'DEBT-ADD-WALLET_TYPE_EMPTY': 'Please select a debt wallet type',
  'DEBT-ADD-AMOUNT_MUST_GREATER_THAN_ZERO': 'Amount must be greater than 0',
  'DEBT-ADD-RATE_MUST_GREATER_THAN_ZERO': 'Rate must be greater than 0',
  'DEBT-ADD-REASON_EMPTY': 'Please specify a debt reason',
  'MONTHLY_PASS_BIGDATA-BUY-USER_ALREADY_ACTIVATED':
    'You have activated this package. Please retry with another option.',
  'MONTHLY_PASS_BIGDATA-BUY-RECEIVER_ALREADY_ACTIVATED':
    'Member has activated this package. Please retry with another option.',
  'MONTHLY_PASS_BUY-RECEIVER_ALREADY_HIGHER_ACTIVATED': `It's not possible to activate the {{ A }} package because the member has already activated the {{ B }} package.`,
  'COMMON-UNKNOWN-ERROR': 'Oops! Something went wrong. Please try again',
  'YEARLY_PASS-EXCEED-MAX-USER-LIST': 'Maximum 10 users for active annual passes at the same time',
  'MONTHLY_PASS-EXCEED-MAX-USER-LIST':
    'Maximum 10 users for active monthly passes at the same time',
  'LOTTERY-LATEST_LOTERRY_CYCLE_NOT_AVAILABLE': 'The latest lottery cycle is not available',
  'LOTTERY-CHECKLOTTERY_TICKET_STATUS_WITH_EMPTY_ARGUMENT':
    'Check lottery ticket status as empty argument',
  'LOTTERY-LUCKY_NUMBER_EXISTED': 'Lottery tickets exist',
  'LOTTERY-LUCKY_NUMBER_NOT_EXISTED': 'Lottery ticket does not exist',
  'LOTTERY-BUY_LOTTERY_TICKET_WITH_EMPTY_ARGUMENT': 'Lottery ticket with empty argument',
  'LOTTERY-LOTTERY_TICKET_NOT_EXISTED': 'Lottery ticket not found',
  'LOTTERY-LOTTERY_TICKET_IS_NOT_JACKPOT': 'Lottery ticket is not jackpot',
  'LOTTERY-LOTTERY_TICKET_IS_PAID': 'Lottery tickets have been paid',
  'LOTTERY-OUTSIDE_THE_TICKET_PURCHASE_TIME_FRAME': 'Outside of ticket purchase hours',
  'LOTTERY-TICKET_NUMBER_INVALID_FORMAT': 'Invalid ticket number format',
  'LOTTERY-TICKET_EXCEED_MAX_LIMIT_PER_ORDER': 'Tickets exceed the maximum limit per order',
  'LOTTERY-LOTTERY_CYCLE_NOT_FOUND': 'Lottery cycle not found',
  'LOTTERY-LOTTERY_CYCLE_ENDED': 'The lottery cycle is over',
  'LOTTERY-LOTTERY_CYCLE_PROCESSING': 'Lottery cycle processing',
  'LOTTERY-EDIT-INVALID_PRIZE_DATE_TIME': 'Lottery cycle invalid date and time of prize opening',
  'LOTTERY-EDIT-WINNER_NOT_SET': 'The winner of the lottery cycle is not set',
  'LOTTERY-WINNER_NOT_BUY_TICKET': `Lottery winners don't buy tickets`,
  'LOTTERY-OUT_OF_RANGE_CREATING_LOTTERY_CYCLE_PER_REQUEST':
    'Out of scope Create a lottery cycle on demand',
  'LOTTERY-CREATING_LOTTERY_CYCLE_WITH_INVALID_STARTDATE':
    'Create a lottery cycle with an invalid start date',
  'LOTTERY-BUYER_NOT_FOUND': 'Lottery buyer not found',
  'LOTTERY-CAN_NOT_CANCEL_LOTTERY_CYCLE_NOT_EXISTED_OR_ACTIVE': `The lottery cycle needed to cancel is not exist or active`,
  'LOTTERY-OUTSIDE_CANCEL_LOTTERY_CYCLE_TIME_FRAME': `The lottery cycle need to cancel is outside time frame`,
  'ERROR-COMMON': 'Oops! Something went wrong. Please try again!',
  'ACCOUNT-INVALID_ACCESS_TOKEN': 'Access token is invalid. Please log in again.',
  ACCOUNT_USERNAME_AND_PASSWORD_ARE_REQUIRED: 'Username and password are required.',
  'LOGIN-INVALID_USERNAME_OR_PASSWORD': 'Invalid username or password.',
  'TOKEN_PRICE_DAILY-CANNOT_MODIFY_FINALIZED_RECORD':
    'This record is already signed off. No modification allowed!',
  'DAILY_BONUS-MEMBER-IN-BLACK-LIST': 'Account is locked for bonuses',
  'WALLET-BLACKLIST-USERNAME_EXISTS': 'The wallet of this member has already been locked.',
  'PAYMENT_METHOD-PAYMENT_ITEM_NOT_FOUND': 'Package cannot be found or no longer available',
  'PAYMENT_METHOD-ALREADY_EXISTS': 'Payment method already exists',
  'PAYMENT_METHOD-DUPLICATE_OPTION_ITEM': 'Duplicated payment token',
  'PAYMENT_METHOD-NOT_FOUND': 'Payment method not found',
  'PAYMENT_METHOD-OPTION_ITEM_NOT_FOUND': 'Payment token not found',
}
