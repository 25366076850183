import { ModelPaymentMethod } from 'models'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ListPayoutMethod from './list-payout-method'
import Loader from 'components/loader'
import { Pagination } from 'react-bootstrap'
import { LOADING, PAYOUT_METHOD_TYPE, PayoutStatus } from 'helpers/constants'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import {
  MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE,
  MENU_MANAGEMENT_PACKAGE,
} from 'pages/system-management/packages-setting'
interface TypeProps {
  data: Array<ModelPaymentMethod> | undefined
  onEdit: Function
}

function OverViewPayout() {
  const [menuActive, setMenuActive] = useState(MENU_ITEMS_MANAGEMENT_PACKAGE_VALUE.Overview)
  const [payoutMethods, setPayoutMethods] = useState([])
  const initSearchFormValue = {
    status: PayoutStatus.Active.toString(),
    payoutType: '',
    pageSize: 10,
    currentPage: 1,
  }
  const [searchFormValues, setSearchFormValues] = useState(initSearchFormValue)
  const [totalCount, setTotalCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const loadData = useCallback(async () => {
    setIsLoading(true)
    PubSub.subscribe(LOADING, function (msg, data) {
      setIsLoading(data)
    })

    let params: any = {
      PageNumber: searchFormValues.currentPage,
      PageSize: searchFormValues.pageSize,
    }
    if (searchFormValues.status) {
      params.status = searchFormValues.status
    }
    if (searchFormValues.payoutType !== '') {
      params.interestPeriodType = searchFormValues.payoutType
    }

    //  Todo: call api get list payout
    const query = await axiosClient().get(END_POINTS.PAYOUT.GET_LIST(), {
      params,
    })

    if (query?.data) {
      setPayoutMethods(query.data.items)
      setTotalCount(query.data.totalCount)
    } else {
      setPayoutMethods([])
      setTotalCount(0)
    }
  }, [
    searchFormValues.currentPage,
    searchFormValues.pageSize,
    searchFormValues.payoutType,
    searchFormValues.status,
  ])
  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <>
      <style>
        {`
            .table-bordered th,
            .table-bordered td {
                border: 1px solid rgba(0, 0, 0, 0.085) !important;
            }
            table th {
                position: relative;
                overflow: hidden;
                border: 1px solid rgba(0, 0, 0, 0.085) !important
                color: var(--WinDOORS-theme-color);
            }
            .line {
                position: absolute;
                height: 41px;
                top: 40px;
                bottom: 0;
                margin: auto;
                left: -5px;
                width: 100%;
                border-top: 1px solid rgba(0, 0, 0, 0.085) !important;
                -webkit-transform: rotate(7deg);
                -ms-transform: rotate(7deg);
                transform: rotate(7deg);
              }
              
              .diagonal {
                width: 327px;
                height: 40px;
              }
              .diagonal span.lb {
                position: absolute;
                bottom: 2px;
                left: 2px;
                color: gray;
              }
              .diagonal span.rt {
                position: absolute;
                top: 2px;
                right: 2px;
                color: gray;
              }
              .table td:first-child {
                background-color: #EEEEEE; 
              }
        `}
      </style>
      <div className="container-fluid">
        <div className="row align-items-center page-title">
          <div className="col-12 col-md mb-2 mb-sm-0">
            <h3 className="mb-0">OverView Payout</h3>
          </div>
        </div>
        <hr className="p-0 m-0 mb-4" />
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <ul className="nav nav-tabs justify-content-center nav-WinDOORS nav-lg mb-4 mt-4">
              {MENU_MANAGEMENT_PACKAGE.map((item) => (
                <li className="nav-item" key={item.value}>
                  <Link
                    className={`nav-link ${menuActive === item.value ? 'active' : ''}`}
                    to={`/${item.link}`}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="container-fluid table-bordered">
            <table className="table">
              <thead>
                <tr>
                  <th className="diagonal" scope="col">
                    <span className="lb">Packages</span>
                    <span className="rt">Payment Method</span>
                    <div className="line"></div>
                  </th>
                  <th scope="col">50% VMM + 50% VPC</th>
                  <th scope="col">100% VMM</th>
                  <th scope="col">100% USDT</th>
                  <th scope="col">100% USDC</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img src="" alt="" />
                    <span></span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default OverViewPayout
