import React, { useEffect, useState, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import Dropdown from 'react-bootstrap/Dropdown'
import { useLocation, useNavigate } from 'react-router-dom'

function Pagination(props: any) {
  const { totalCount, onChange, onChangePageSize, pageNumber, hidden, disableLocationPath } = props
  const location = useLocation()
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(pageNumber | 1)
  const [pageSize, setPageSize] = useState(10)
  const myfunc = useCallback(
    (currentPage: number, pageSize: number) => {
      if (!disableLocationPath) {
        navigate(`${location.pathname}?pageNumber=${currentPage}&pageSize=${pageSize}`, {
          state: {
            pageNumber: currentPage,
            pageSize: pageSize,
          },
        })
      }
    },
    [currentPage, pageSize],
  )

  useEffect(() => {
    setCurrentPage(pageNumber)
  }, [pageNumber])

  useEffect(() => {
    const search = location.search
    const mypageNumberStr = new URLSearchParams(search).get('pageNumber')
    const mypageSizeStr = new URLSearchParams(search).get('pageSize')

    if (mypageNumberStr && mypageSizeStr && !disableLocationPath) {
      let mypageNumber = parseInt(mypageNumberStr)
      const mypageSize = parseInt(mypageSizeStr)
      const pageCount = Math.ceil(totalCount / mypageSize)

      if (mypageNumber > pageCount) {
        mypageNumber = 1
      }

      setCurrentPage(mypageNumber)
      setPageSize(mypageSize)
      myfunc(mypageNumber, mypageSize)
    }
  }, [disableLocationPath, location.search, myfunc, totalCount])

  const handleChangePagesize = (e: number) => {
    const pageCount = Math.ceil(totalCount / e)
    let mycurrentPage = currentPage
    if (mycurrentPage > pageCount) {
      mycurrentPage = 1
      setCurrentPage(1)
      onChange(1)
    }
    setPageSize(e)
    onChangePageSize(e)
    myfunc(mycurrentPage, e)
  }

  return (
    <>
      <div className="row justify-content-between py-3" hidden={hidden}>
        <div className="col-12">
          <nav>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={(e: any) => {
                setCurrentPage(e.selected + 1)
                onChange(e.selected + 1)
                myfunc(e.selected + 1, pageSize)
              }}
              forcePage={currentPage - 1}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalCount / pageSize)}
              previousLabel="Previous"
              className={`pagination justify-content-center`}
              breakClassName={`page-item break-item`}
              breakLinkClassName={`page-link break-link`}
              pageClassName={`page-item`}
              pageLinkClassName={`page-link`}
              previousClassName={`page-item`}
              nextClassName={`page-item`}
              previousLinkClassName={`page-link`}
              nextLinkClassName={`page-link`}
              disabledClassName="page-item disabled"
              activeClassName="active"
            />
          </nav>
        </div>
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-auto">
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-paging">
                  {pageSize}/page
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleChangePagesize(10)} active={pageSize === 10}>
                    10/page
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleChangePagesize(20)} active={pageSize === 20}>
                    20/page
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => handleChangePagesize(50)} active={pageSize === 50}>
                    50/page
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleChangePagesize(100)}
                    active={pageSize === 100}
                  >
                    100/page
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center" style={{ gap: 8 }}>
                <span>Go to page</span>
                <input
                  className="form-control border form-control-sm"
                  placeholder=""
                  value={currentPage}
                  style={{ width: 60 }}
                  onChange={(e: any) => {
                    if (e.target.value <= Math.ceil(totalCount / pageSize) && e.target.value > 0) {
                      setCurrentPage(e.target.value)
                      onChange(e.target.value)
                      myfunc(e.target.value, pageSize)
                    } else {
                      setCurrentPage(1)
                      onChange(1)
                      myfunc(1, pageSize)
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pagination
