import Decimal from 'decimal.js'
import {
  CurrencyUnit,
  FunctionSettingGroupType,
  FunctionSettingSubGroupType,
  PaymentOptionGroup,
  SavingAccountStatus,
  SavingInterestPeriodType,
  SavingTermStatus,
  StatusExtenralStatus,
  SwapStatus,
  TransactionType,
} from 'helpers/constants'
import {
  ChannelType,
  DateRangeFilterType,
  DateType,
  MemberReportFilterStatus,
  PackageSituation,
  PackageStatus,
  PassCodeType,
  PaymentMethod,
  TokenStatisticFilterBy,
  WalletType,
} from 'helpers/enums'
export interface PaginationModel {
  pageNumber: number
  pageSize: number
  totalCount: number
}

export interface ModelUser {
  user: {
    id: string
    userName: string
    normalizedUserName: string
    email: string
    normalizedEmail: string
    emailConfirmed: boolean
    passwordHash: string
    securityStamp: string
    concurrencyStamp: string
    phoneNumber: string
    phoneNumberConfirmed: boolean
    twoFactorEnabled: boolean
    lockoutEnd: string | null
    lockoutEnabled: boolean | null
    accessFailedCount: number
  }
  profile: {
    id: string
    firstName: string
    lastName: string
    username: string
    phoneNumber: string
    dialCode: string
    address: string
    gender: string
    country: string
    referralCode: number
    referralCodeFormat: string
    birthday: string
    created: string
    createTimeConverted: string
    deactiveAnnualByAdmin: boolean
    deactiveMonthlyPassByAdmin: boolean
    rank: {
      id: string
      name: string
      level: number
      requiredDirectTeam: number
      requiredLeftTeam: number
      requiredMidTeam: number
      requiredRightTeam: number
      bonusPerDay: number
      rewardAmount: number
      isMidTeamDisabled: boolean
    }
    latestMonthlyPassCode: {
      id: string
      code: string
      activatedDate: string
      amount: number
      expirationDate: string
      status: string
      numberOfMonth: number
      paymentDate: string
      paymentMethod: string
      monthlyPass: {
        id: string
        price: number
        name: string
        description: string
        numberOfMonth: number
        status: string
        imagePath: string
      }
    }
    latestYearlyPassCode: {
      id: string
      code: string
      activatedDate: string
      amount: number
      expirationDate: string
      status: string
      numberOfMonth: number
      paymentDate: string
      paymentMethod: string
      yearlyPass: {
        id: string
        name: string
        price: number
        status: string
        imagePath: string
      }
    }
  }
  sponsor: {
    id: string
    firstName: string
    lastName: string
    username: string
    phoneNumber: string
    dialCode: string
    address: string
    gender: string
    country: string
    referralCode: number
    referralCodeFormat: string
    birthday: string
    rank: {
      id: string
      name: string
      level: number
      requiredDirectTeam: number
      requiredLeftTeam: number
      requiredMidTeam: number
      requiredRightTeam: number
      bonusPerDay: number
      rewardAmount: number
      isMidTeamDisabled: boolean
    }
    latestMonthlyPassCode: {
      id: string
      code: string
      activatedDate: string
      amount: number
      expirationDate: string
      status: string
      numberOfMonth: number

      monthlyPass: {
        id: string
        price: number
        name: string
        description: string
        numberOfMonth: number
        status: string
      }
    }
    latestYearlyPassCode: {
      id: string
      code: string
      activatedDate: string
      amount: number
      expirationDate: string
      status: string
      numberOfMonth: number
      yearlyPass: {
        id: string
        name: string
        price: number
        status: string
      }
    }
  }
  roles?: Array<string>
}

export interface IUser {
  id: string
  username: string
  email: string
  emailConfirm: boolean
  profileId: string
  firstName: string
  lastName: string
  phoneNumber: any
  referralCodeFormat: string
  created: string
  createTimeConverted: string
  rankId: any
  rankName: any
  latestYearlyPassCodeId: string
  latestYearlyPassCodeName: any
  latestYearlyPassCodeImagePath: any
  latestMonthlyPassCodeId: string
  latestMonthlyPassCodeName: string
  latestMonthlyPassCodeImagePath: string
  sponsorUserName: string
  sponsorReferralCodeFormat: string
  deactiveAnnualByAdmin: boolean
  deactiveMonthlyPassByAdmin: boolean
  latestYearlyPassCodeIsFree: boolean
  latestMonthlyPassCodeBoDisplayName: string
  latestYearlyPassCodeBoDisplayName: string
}
export interface ModelDailyBonus {
  id: string
  profile: {
    id: string
    firstName: string
    lastName: string
    username: string
    phoneNumber: string
    dialCode: string
    address: string
    gender: string
    country: string
    referralCode: number
    birthday: string | Date
    rank: {
      id: string
      domainEvents: [{}]
      created: string | Date
      createdBy: string
      lastModified: string | Date
      lastModifiedBy: string
      name: string
      level: number
      requiredDirectTeam: number
      requiredLeftTeam: number
      requiredMidTeam: number
      requiredRightTeam: number
      bonusPerDay: number
      rewardAmount: number
      isMidTeamDisabled: boolean
      users: [
        {
          id: string
          domainEvents: [{}]
          created: string | Date
          createdBy: string
          lastModified: string | Date
          lastModifiedBy: string
          email: string
          username: string
          phoneNumber: string
          dialCode: string
          address: string
          gender: string
          referralCode: number
          birthday: string | Date
          country: string
          firstName: string
          lastName: string
          vlgWallet: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            vlgWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string | Date
                createdBy: string
                lastModified: string | Date
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                vlgWalletId: string
              },
            ]
          }
          vmmWallet: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            address: string
            vmmWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string | Date
                createdBy: string
                lastModified: string | Date
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                vmmWalletId: string
              },
            ]
          }
          vusdWallet: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            address: string
            vusdWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string | Date
                createdBy: string
                lastModified: string | Date
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                vusdWalletId: string
              },
            ]
          }
          usdtWallet: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            vusdWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string | Date
                createdBy: string
                lastModified: string | Date
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                usdtWalletId: string
              },
            ]
          }
          usdWallet: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            usdWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string | Date
                createdBy: string
                lastModified: string | Date
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                usdWalletId: string
              },
            ]
          }
          vndWallet: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            vndWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string | Date
                createdBy: string
                lastModified: string | Date
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                vndWalletId: string
              },
            ]
          }
          treeConfig: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            profileId: string
            bParentId: string
            bLeftId: string
            bRightId: string
            bMidId: string
            bAddress: string
            bLevel: number
            sParentId: string
            sLevel: number
            sAddress: string
            profile: string
            status: number
            refCode: number
            parentCode: number
            team: string
          }
          rankId: string
          rank: string
          merchantCustomerId: string
          latestMonthlyPassCodeId: string
          latestMonthlyPassCode: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            code: string
            buyerUserId: string
            paymentMethod: number
            paymentDate: string | Date
            activatedDate: string | Date
            amount: number
            refId: string
            expirationDate: string | Date
            activatedUserId: string
            status: number
            numberOfMonth: number
            isAutoRenew: boolean
            transactionId: string
            note: string
            monthlyPassId: string
            monthlyPass: {
              id: string
              domainEvents: [{}]
              created: string | Date
              createdBy: string
              lastModified: string | Date
              lastModifiedBy: string
              price: number
              numberOfLotteryTickets: number
              name: string
              description: string
              numberOfMonth: number
              status: string
              monthlyPassCodes: [string]
            }
          }
          latestYearlyPassCodeId: string
          latestYearlyPassCode: {
            id: string
            domainEvents: [{}]
            created: string | Date
            createdBy: string
            lastModified: string | Date
            lastModifiedBy: string
            code: string
            buyerUserId: string
            paymentMethod: number
            paymentDate: string | Date
            activatedDate: string | Date
            amount: number
            refId: string
            expirationDate: string | Date
            activatedUserId: string
            status: number
            numberOfMonth: number
            isAutoRenew: boolean
            transactionId: string
            note: string
            yearlyPassId: string
            yearlyPass: {
              id: string
              domainEvents: [{}]
              created: string | Date
              createdBy: string
              lastModified: string | Date
              lastModifiedBy: string
              name: string
              price: number
              numberCreditCardAllow: number
              documentFullOption: boolean
              joiningAffiliateProgram: string
              numberOfLotteryTickets: number
              imagePath: string
              level: number
              allowShowBTree: boolean
              status: string
              yearlyPassCodes: [string]
            }
            cardNumber: string
            cardType: string
          }
          receipts: [
            {
              id: string
              domainEvents: [{}]
              created: string | Date
              createdBy: string
              lastModified: string | Date
              lastModifiedBy: string
              phoneNumber: string
              creditInfoId: string
              shopName: string
              submittedDate: string
              totalAmount: string
              currency: string
              status: string
              imagePath: string
              profileId: string
              profile: string
            },
          ]
          creditInfos: [
            {
              id: string
              domainEvents: [{}]
              created: string | Date
              createdBy: string
              lastModified: string | Date
              lastModifiedBy: string
              cardNumber: string
              fullName: string
              type: string
              expirationDate: string | Date
              bankName: string
              imagePath: string
              profileId: string
              profile: string
            },
          ]
          userTreeCaches: [
            {
              id: string
              domainEvents: [{}]
              created: string | Date
              createdBy: string
              lastModified: string | Date
              lastModifiedBy: string
              profile: string
              profileId: string
              transactionDate: string | Date
              numberLeftTeam: number
              numberMidTeam: number
              numberRightTeam: number
              numberDirect: number
              transactionId: string
              rankId: string
              status: number
              dailyBonus: number
              vmmPrice: number
            },
          ]
        },
      ]
    }
  }
  profileId: string
  transactionDate: string | Date
  numberLeftTeam: number
  numberMidTeam: number
  numberRightTeam: number
  numberDirect: number
  transactionId: string
  rank: ModelRank
  rankId: string
  status: string
  dailyBonus: number
  vmmPrice: number
  vmmAmount: number
  created: string | Date
  createdBy: string
  lastModified: string | Date
  lastModifiedBy: string
}

export interface ModelAllPackageFilter {
  passCodeType: string
  packages: ModelMembershipPass[]
}
export interface ModelYearlyPass {
  id: string
  name: string
  numberCreditCardAllow: number
  documentFullOption: boolean
  joiningAffiliateProgram: string
  numberOfLotteryTickets: number
  status: string
  price: number
  imagePath?: string
  boName: string
  boDisplayName: string
}
export interface ModelMonthlyPass {
  id: string
  price: number
  numberOfLotteryTickets: number
  name: string
  boName: string
  numberOfMonth: number
  description: string
  imagePath?: string
  type: string
  promotion: ModelPromotion
  subType: string
  boDisplayName: string
}

export interface ModelPromotion {
  options: Array<ModelPromotionOption>
}

export interface ModelPromotionOption {
  id: string
  promotionId: string
  optionValueForTravel: ModelOptionValueForTravel
  optionName: string
}

export interface ModelOptionValueForTravel {
  discountAmount: number
  paidOutStartDate: number
}

export interface ModelMembershipPass {
  id: string
  name: string
  price?: number
  isActive: boolean
}
export interface InforEdit {
  userName: string
  email: string
  emailConfirmed: boolean
  passwordString: string
  lockoutEnabled: boolean | null
  firstName: string
  lastName: string
  birthday: string | Date
  country: string
  phoneNumber?: string
  dialCode?: string
  address?: string
  gender: string
  role: string
  lockoutEnd?: boolean | null
}

export interface ModelNetworkTree {
  id: string | null
  nodeId: string | null
  username: string | null
  referralCode: number
  referralCodeFormat: string | null
  bParentCodeFormat: string | null
  bParentUsername: string | null
  bParentId: string | null
  fLevel: number | null
  bLevel: number
  children: ModelNetworkTree[]
  branchSignal: string
  isNewNode: boolean
  isEmptyNode: boolean
  isUnavailable: boolean
  isDirectMember: boolean
  hasActiveMonthlyPass: boolean
  isLocked: boolean
  rank: ModelNetworkTreeRank | null
  monthlyPassCode: ModelNetworkTreeMonthlyPass | null
  yearlyPassCode: ModelNetworkTreeMonthlyPass | null
}

export interface ModelNetworkTreeRank {
  id: string
  name: string
  level: number
  requiredDirectTeam: number
  requiredLeftTeam: number
  requiredMidTeam: number
  requiredRightTeam: number
  bonusPerDay: number
  rewardAmount: number
  isMidTeamDisabled: boolean
}
export interface ModelNetworkTreeMonthlyPass {
  id: string
  code: string | null
  activatedDate: Date
  amount: number
  expirationDate: Date
  status: string
  numberOfMonth: number
  monthlyPass?: ModelMonthlyPass
  paymentMethod: string
  paymentDate: Date
  extraMonths?: number
  yearlyPass?: ModelYearlyPass
}

export interface ModelNetworkTreeInfor {
  nbOfLeftMembers: number
  nbOfRightMembers: number
  nbOfMidMembers: number
  nbOfDirectMembers: number
  nbOfActiveLeftMembers: number
  nbOfActiveRightMembers: number
  nbOfActiveMidMembers: number
  nbOfActiveDirectMembers: number
  sponsor: {
    id: string
    firstName: string
    lastName: string
    username: string
    phoneNumber: string
    dialCode: string
    address: string
    gender: string
    country: string
    referralCode: number
    referralCodeFormat: string
    birthday: string
    rank: {
      id: string
      domainEvents: [{}]
      created: string
      createdBy: string
      lastModified: string
      lastModifiedBy: string
      name: string
      level: number
      requiredDirectTeam: number
      requiredLeftTeam: number
      requiredMidTeam: number
      requiredRightTeam: number
      bonusPerDay: number
      rewardAmount: number
      isMidTeamDisabled: boolean
      isRequiredDailyBonusApproval: boolean
      users: [
        {
          id: string
          domainEvents: [{}]
          created: string
          createdBy: string
          lastModified: string
          lastModifiedBy: string
          email: string
          username: string
          phoneNumber: string
          dialCode: string
          address: string
          gender: string
          referralCode: number
          referralCodeFormat: string
          birthday: string
          country: string
          firstName: string
          lastName: string
          vlgWallet: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            vlgWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string
                createdBy: string
                lastModified: string
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                vlgWalletId: string
              },
            ]
          }
          vmmWallet: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            address: string
            vmmWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string
                createdBy: string
                lastModified: string
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                vmmWalletId: string
              },
            ]
          }
          vusdWallet: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            address: string
            vusdWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string
                createdBy: string
                lastModified: string
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                vusdWalletId: string
              },
            ]
          }
          usdtWallet: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            vusdWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string
                createdBy: string
                lastModified: string
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                usdtWalletId: string
              },
            ]
          }
          usdWallet: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            usdWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string
                createdBy: string
                lastModified: string
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                usdWalletId: string
              },
            ]
          }
          vndWallet: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            profileId: string
            amount: number
            profile: string
            rowVersion: string
            vndWalletHistories: [
              {
                id: string
                domainEvents: [{}]
                created: string
                createdBy: string
                lastModified: string
                lastModifiedBy: string
                amount: number
                oldValue: number
                newValue: number
                message: string
                transactionId: string
                partner: string
                fee: number
                vndWalletId: string
              },
            ]
          }
          treeConfig: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            profileId: string
            bParentId: string
            bLeftId: number
            bRightId: number
            bMidId: number
            bAddress: string
            bLevel: number
            sParentId: number
            sLevel: number
            sAddress: string
            profile: string
            status: string
            refCode: number
            parentCode: number
            team: string
          }
          rankId: string
          rank: string
          merchantCustomerId: string
          latestMonthlyPassCodeId: string
          latestMonthlyPassCode: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            code: string
            buyerUserId: string
            paymentMethod: string
            paymentDate: string
            activatedDate: string
            amount: number
            refId: string
            expirationDate: string
            activatedUserId: string
            status: string
            numberOfMonth: number
            isAutoRenew: boolean
            transactionId: string
            note: string
            monthlyPassId: string
            monthlyPass: {
              id: string
              domainEvents: [{}]
              created: string
              createdBy: string
              lastModified: string
              lastModifiedBy: string
              price: number
              numberOfLotteryTickets: number
              name: string
              description: string
              numberOfMonth: number
              status: string
              monthlyPassCodes: [string]
            }
          }
          latestYearlyPassCodeId: string
          latestYearlyPassCode: {
            id: string
            domainEvents: [{}]
            created: string
            createdBy: string
            lastModified: string
            lastModifiedBy: string
            code: string
            buyerUserId: string
            paymentMethod: string
            paymentDate: string
            activatedDate: string
            amount: number
            refId: string
            expirationDate: string
            activatedUserId: string
            status: string
            numberOfMonth: number
            isAutoRenew: boolean
            transactionId: string
            note: string
            yearlyPassId: string
            yearlyPass: {
              id: string
              domainEvents: [{}]
              created: string
              createdBy: string
              lastModified: string
              lastModifiedBy: string
              name: string
              price: number
              numberCreditCardAllow: number
              documentFullOption: boolean
              joiningAffiliateProgram: string
              numberOfLotteryTickets: number
              imagePath: string
              level: number
              allowShowBTree: boolean
              status: string
              yearlyPassCodes: [string]
            }
            cardNumber: string
            cardType: string
          }
          receipts: [
            {
              id: string
              domainEvents: [{}]
              created: string
              createdBy: string
              lastModified: string
              lastModifiedBy: string
              phoneNumber: string
              creditInfoId: string
              shopName: string
              submittedDate: string
              totalAmount: string
              currency: string
              status: string
              imagePath: string
              profileId: string
              profile: string
            },
          ]
          creditInfos: [
            {
              id: string
              domainEvents: [{}]
              created: string
              createdBy: string
              lastModified: string
              lastModifiedBy: string
              cardNumber: string
              fullName: string
              type: string
              expirationDate: string
              bankName: string
              imagePath: string
              profileId: string
              profile: string
            },
          ]
          userTreeCaches: [
            {
              id: string
              domainEvents: [{}]
              created: string
              createdBy: string
              lastModified: string
              lastModifiedBy: string
              profile: string
              profileId: string
              transactionDate: string
              numberLeftTeam: number
              numberMidTeam: number
              numberRightTeam: number
              numberDirect: number
              transactionId: string
              rankId: string
              status: string
              dailyBonus: number
              vmmPrice: number
            },
          ]
        },
      ]
    }
  }
}

export interface ModelAddUser {
  userName: string
  email: string
  passwordString: string
  passwordStringConfrirm: string
  firstName: string
  lastName: string
  parentNodeUsername: string
  sponsorUsername: string
  teamPosition: string
  activeMonthlyMembership: boolean
  activeYearlyMembership: boolean
  role: string
}

export interface ModelRank {
  id: string
  created: string | Date
  createdBy: string
  lastModified: string | Date
  lastModifiedBy: string
  name: string
  level: number
  requiredDirectTeam: number
  requiredLeftTeam: number
  requiredMidTeam: number
  requiredRightTeam: number
  bonusPerDay: number
  rewardAmount: number
  isMidTeamDisabled: boolean
  isRequiredDailyBonusApproval: boolean
  reserve: number
}
export interface ModelUserDetail {
  id: string
  firstName: string
  lastName: string
  username: string
  phoneNumber: string
  dialCode: string
  address: string
  gender: string
  country: string
  referralCode: number
  referralCodeFormat: string
  email: string
  logoPath: string
  birthday: string
  created: string
  createTimeConverted: string
  rank: {
    id: string
    name: string
    level: number
    requiredDirectTeam: number
    requiredLeftTeam: number
    requiredMidTeam: number
    requiredRightTeam: number
    bonusPerDay: number
    rewardAmount: number
    isMidTeamDisabled: boolean
  }
  currentMonthlyPassCodes?: Array<ModelLastestMonthlyPass>
  latestMonthlyPassCode: ModelLastestMonthlyPass
  latestYearlyPassCode: ModelLastestYearlyPass
  listMonthlyPass: Array<ModelLastestMonthlyPass>
  listYearlyPass: Array<ModelLastestYearlyPass>
  emailConfirmed: boolean
  lockoutEnabled: boolean | null
  roles: string[]
  lockoutEnd: string | null
}

export interface ModelLastestYearlyPass {
  id: string
  code: string
  activatedDate: string
  amount: number
  expirationDate: string
  status: string
  numberOfMonth: number
  yearlyPass: ModelYearlyPass
  paymentMethod: string
  paymentDate: string
  isFree: boolean
}
export interface ModelLastestMonthlyPass {
  id: string
  code: string
  activatedDate: string
  amount: number
  expirationDate: string
  status: string
  numberOfMonth: number
  monthlyPass: ModelMonthlyPass
  paymentMethod: string
  paymentDate: string
}
export interface ModelWallet {
  id: string
  amount: number
  name: string
  address: string
  amountInUsd: number
  walletType: string
}
export interface ModelWalletRate {
  id: string
  amount: number
  name: string
  address: string
  valueInUsd: number
  tokenRate: number
}
export interface ModelUserSearch {
  id: string
  username: string
  phoneNumber: string
  email: string
  referralCodeFormat: string
  referralCode: number
  promotionOptions?: Array<ModelPromotionOption>
}

export interface ModelPromotionOption {
  id: string
  promotionId: string
  price: number
  optionName: string
}

export interface ModelReceiptSummary {
  uploadedTotal: number
  approvedTotal: number
  preApprovedTotal: number
  rejectedTotal: number
  earningTotal: number
}

export interface ModelUserPayload {
  userId: string
  promotionOptionId: string
}

export interface ModelReceipt {
  id: string
  submittedDate: string
  usedDate: string
  usdValue: number
  status: string
  store: ModelReceiptStore
  cardNumber: string
  imagePath: string
  amount: number
  currency: string
  approvedDate: string
  rejectedDate: string
  rejectedReason: string
  note: string
  profile: ModelReceiptProfile
  storeId: string
  notAllowDelete: boolean
  cashbackCycle: ModelCashbackCycle
  paidDate: string
  askToResubmitReason: string
  creditInfoId: string
  approvedBaseAmount: number
}
export interface ModelReceiptDetail {
  id: string
  submittedDate: string
  usedDate: string
  usdValue: number
  status: string
  store: ModelReceiptStore
  cardNumber: string
  cashBackPercent: number
  imagePath: string
  amount: number
  currency: string
  approvedDate: string
  approvedBy: string
  rejectedDate: string
  rejectedBy: string
  rejectedReason: string
  userInfo: ModelReceiptUserInfo
  approvedUserInfo: ModelReceiptUserInfo
  rejectedUserInfo: ModelReceiptUserInfo
  cashbackCycle: ModelCashbackCycle
  paidDate: string
  askToResubmitReason: string
  creditInfoId: string
  approvedCashBackPercent: number
  preApprovedAmount: number
  approvedAmount: number
  approvedBaseAmount: number
  note: string
  currencyRate: number
}
export interface ModelCashbackCycle {
  id: string
  startDate: string
  uploadEndDate: string
  endDate: string
  orderNumber: number
}

interface ModelReceiptProfile {
  id: string
  username: string
  phoneNumber: string
  email: string
  referralCode: number
  referralCodeFormat: string
}

interface ModelReceiptStore {
  name: string
  code: string
}

export interface ModelTransactionSummary {
  type: string
  amount: number
}
export interface ModelTransaction {
  username: string
  amount: number
  amountInUsd: number
  paymentMethod: string
  type: string
  category: string
  transactionDate: string
}
export interface ModelStatistic {
  total: ModelTotal
  today: ModelTotal
}

export interface ModelTotal {
  paidOut: {
    networkDailyBonus: number
    networkLeadershipBonus: number
    networkGlobalBonus: number
    cashbackDailyPaidOut: number
    cashbackReward: number
    lottery: number
    total: number
  }
  revenue: {
    monthlyPass: number
    yearlyPass: number
    total: number
  }
}

export interface ModelRevenue {
  total: ModelItemRevenue
  today: ModelItemRevenue
  thisMonth: ModelItemRevenue
  items: ModelItemRevenueData[]
}

export interface ModelItemRevenueData {
  monthlyPass: number
  yearlyPass: number
  total: number
  year: number
  month: number
}
interface ModelItemRevenue {
  monthlyPass: number
  yearlyPass: number
  total: number
}
interface ModelItemPayout {
  networkDailyBonus: number
  networkLeadershipBonus: number
  networkGlobalBonus: number
  cashbackDailyPaidOut: number
  cashBackReward: number
  total: number
  lottery: number
}
export interface ModelItemPayoutData {
  networkDailyBonus: number
  networkLeadershipBonus: number
  networkGlobalBonus: number
  cashbackDailyPaidOut: number
  cashBackReward: number
  total: number
  year: number
  month: number
  lottery: number
}
export interface ModelPayout {
  total: ModelItemPayout
  today: ModelItemPayout
  thisMonth: ModelItemPayout
  items: ModelItemPayoutData[]
}
export interface ModelWalletType {
  text: string
  value: string
  name: string
}

export interface ModelWalletHistory {
  amount: number
  newValue: number
  message: string
  transactionDate: string
  transactionId: string
  fee: number
}
export interface ModelAdminWalletHistory {
  transactionId?: string
  username: string
  created: string
  amount: number
  message: string
  transactionType: string
  token: string
}

interface ModelReceiptUserInfo {
  id: string
  username: string
  email: string
  phoneNumber: string
  rank: ModelReceiptRank
  latestMonthlyPassCode: ModelReceiptLatestLyPassCode
  latestYearlyPassCode: ModelReceiptLatestLyPassCode
}

interface ModelReceiptLatestLyPassCode {
  id: string
  code: string
  activatedDate: string
  amount: number
  expirationDate: string
  status: string
  numberOfMonth: number
  monthlyPass?: ModelReceiptMonthlyPass
  paymentMethod: string
  paymentDate: string
  yearlyPass?: ModelReceiptYearlyPass
}

interface ModelReceiptMonthlyPass {
  id: string
  price: number
  name: string
  description: string
  numberOfMonth: number
  status: string
  availableDateTime: string
  imagePath: string
  boName: string
  boDisplayName: string
}

interface ModelReceiptYearlyPass {
  id: string
  name: string
  price: number
  status: string
  imagePath: string
  boName: string
  boDisplayName: string
}

interface ModelReceiptRank {
  id: string
  name: string
  level: number
  requiredDirectTeam: number
  requiredLeftTeam: number
  requiredMidTeam: number
  requiredRightTeam: number
  bonusPerDay: number
  rewardAmount: number
  isMidTeamDisabled: boolean
}

export interface ModelTokenRate {
  name: string
  unit: string
  value: number
  baseUnit: string | undefined
}
export interface ModelUserPassesItem {
  id: string
  name: string
  expirationDate: Date
  paymentDate: Date
  isCurrentActivatePass: boolean
  canUpgrade: boolean
  passId: string
  isAutoRenew: boolean
  isFree: boolean
  boName: string
  boDisplayName: string
}
export interface ModelUserPasses {
  monthlyPassed: ModelUserPassesItem[]
  yearlyPassed: ModelUserPassesItem[]
  autoRenewYearlyPass: boolean
  autoRenewMonthlyPass: boolean
}

export interface ModelTokenTransctionItem {
  id: string
  transactionDate: string
  currencyUnit: string
  defaultPrice: number
  price: number
  customPrice: number
  packagePurchasingPrice: number
  startTimeSpan: string
  endTimeSpan: string
  isFinalized: boolean
  lastSyncedTime: string
}
export interface ModelCurrencyUnit {
  text: string
  value: string
}

export interface ModelLotterySummary {
  currentVpcPoolAmount: number
  currentUsdPoolAmount: number
  currentTicketsSold: number
  totalUsdPoolAmount: number
  totalTicketsSold: number
}
export interface ModelLotteryHistoryItem {
  id: string
  cycleCode: string
  status: string
  prizeDateTime: Date
  luckyNumber: string
  poolAmount: number
  winnerType: string
  finalWinnerType: string
  ticketPrice: number
  maxTicketCanSubmitPerOrder: number
  winnerTypeChangedReason: string
  finalPrizeDateTime: Date
  prizeTokenRate: number
  setWinnerBy: string
  setWinnerAt: Date
  setWinnerByUser: SetWinnerByUser
}
export interface ModelLotteryHistoryItemDetail {
  id: string
  status: string
  finalPrizeDateTime: Date
  luckyNumber: string
  poolAmount: number
  prizeTokenRate: number
  winnerType: string
  finalWinnerType: string
  winnerTypeChangedReason: string
  numberOfWinners: number
  winningTickets: WinningTicket[]
}

export interface WinningTicket {
  id: string
  owner: Owner
  vpcAmount: number
}

export interface Owner {
  id: string
  username: string
  referralCodeFormat: string
  firstName: string
  lastName: string
  logoPath: string
}

export interface SetWinnerByUser {
  id: string
  firstName: string | null
  lastName: string | null
  username: string
  phoneNumber: string | null
  dialCode: string | null
  gender: string | null
  referralCodeFormat: string
  logoPath: string | null
}

export interface ModelUserSearchLottery {
  id: string
  username: string
  // referralCodeFormat: string
  // firstName: string
  // lastName: string
  // logoPath: string
}

export interface ModelLotteryNumberInfo {
  luckyNumber: string
  tickets: Ticket[]
}

export interface Ticket {
  id: string
  owner: Owner
  luckyNumber: string
}

export interface Owner {
  id: string
  username: string
  referralCodeFormat: string
  firstName: string
  lastName: string
  logoPath: string
}

export interface ModelTicketOfCycle {
  ownerId: string
  owner: Owner
  luckyNumber: string
  lotteryCycleId: string
  lotteryCycle: LotteryCycle
  status: string
  purchasedDate: string
  paymentMethod: string
  paymentStatus: string
  ticketPrice: number
  paymentTokenRate: number
}

export interface LotteryCycle {
  id: string
  cycleCode: string
  prizeDateTime: string
  initialPoolAmount: number
  poolAmount: number
  finalPrizeDateTime: Date | string
}

export interface Owner {
  id: string
  username: string
  referralCodeFormat: string
  firstName: string
  lastName: string
  logoPath: string
}

export interface ModelTicketPrizePlanItem {
  id: string
  type: string
  paidDate: string
  drawnDate: string
  baseAmount: number
  vpcAmount: number
  vpcRate: number
  transactionId: string
  status: string
  note: string
  created: string
  username: string
  profileId: string
  poolAmount: number
  lotteryCycleId: string
}

export interface ModelVMMUsages {
  vmmUsages: ModelVMMUsage[]
  totalInWalletNow: number
  packageStatistic: ModelPackage[]
}

export interface ModelVMMUsage {
  groupDate: string
  totalPay: number
  totalVMMInUserWallet: number
}
export interface ModelPackage {
  packageId: string
  packageName: string
  sold: number
}
export interface ModelTokenStatistic {
  tokenName: string
  totalInUSD: number
  totalInCurrentToken: number
  percent: number
  colorCode: string
}
export interface ModelTokenReportStatisticFilter {
  filterBy: TokenStatisticFilterBy
  filterKey: string
  walletType: WalletType
  fromDate: Date
  toDate: Date
  dateRangeFilterType: DateRangeFilterType
}

export interface ModelLatestTokenStatisticFilter {
  walletType: WalletType
  userId: string
}
export interface VlgWalletModel extends BaseWalletHistoryModel {
  walletType: string
}
export interface BaseWalletHistoryModel {
  amount: number
}
export interface ModelLatestTokenStatistics {
  id: string
  username: string
  token: string
  wallet: string
  amount: number
  quantity: number
  inUSD: number
}

export interface ModelTokenReportStatistics {
  walletType: string
}
export interface ModelTokenReport {
  reportDate: string
  amount: number
}

export interface ModelStore {
  id: string | undefined
  name: string
  code?: string
  categoryId?: string
  address?: string
  country?: string
  phone?: string
  cashBackPercent?: number
  category?: CategoryModel
  status?: string
}

export interface CategoryModel {
  id: string
  name: string
}

export interface ModelAddStore {
  name: string
  code?: string
  categoryId?: string
  address?: string
  country?: string
  phone?: string
  cashBackPercent?: number
  status?: string
}
export interface ModelAnnouncement {
  id: string | undefined
  publishStartDate: string | Date
  publishEndDate: string | Date
  isPublish: boolean
  details: ModelAnnouncementDetail[]
}

export interface ModelAnnouncementDetail {
  languageId: string
  title: string
  description: string
  uniqueSeoCode?: string
}

export interface ModelAnnouncementItem {
  id: string | undefined
  title: string
  publishStartDate: string | null
  publishEndDate: string | null
  isPublish: boolean
  isActive?: boolean
}
export interface ModelLanguage {
  id: string
  name: string
  uniqueSeoCode: string
}
export interface ModelTransactionType {
  text: string
  value: TransactionType | string
}
export interface ModelWithdrawHistoryItem {
  id: string
  transactionId: string
  fromAddress: string
  toAddress: string
  inAmount: number
  inUnit: string
  outAmount: number
  outUnit: string
  feeAmount: number
  feeUnit: string
  created: string
  transactionDate: string
  status: string
  username: string
}
export interface ModelParamMyBNetWork {
  NodeId: string
  UserId: string
  LevelLimit: number
}

export interface ModelDownlineMember {
  id: string
  nodeId: string
  firstName: string
  lastName: string
  username: string
  branch: string
  hasActiveMonthlyPass: boolean
  referralCodeFormat: string
}
export interface ReportFilter {
  type: PassCodeType | undefined | string
  packageId?: string
  username?: string
  status?: MemberReportFilterStatus | string
  packageStatus?: PackageSituation | string
  fromDate: Date | undefined
  toDate: Date | undefined
  dateType: DateType
}

export interface ModelPassCode {
  id: string
  packageId?: string
  packageName?: string
  buyerUserId?: string
  buyerUsername?: string
  activatedUserId?: string
  activatedUsername?: string
  paymentDate?: string
  expirationDate?: string
  amount?: number
  paymentMethod?: PaymentMethod
  isAutoRenew?: boolean
  isExpired?: boolean
  boDisplayName: string
  vmmUsdAmount?: number
  paymentLog: PaymentData | null
}
export interface ModelPassCodeStatistic {
  activePackages?: number
  expiredPackages?: number
  thisMonthNewlyActivePackages?: number
  todayNewlyActivePackages?: number
  expiringInThreeDaysPackages?: number
}
export interface ModelPassCodeType {
  text: string
  value: PassCodeType
}

export interface FeaturesConfig {
  text?: string
  groupType?: FunctionSettingGroupType
  configurations?: IConfig[]
}

export interface IConfig {
  text?: string
  key?: FunctionSettingSubGroupType
  value?: boolean
  displayOrder?: number
}

export interface ModelMergeStore {
  id: string | undefined
  storeIds: string[]
}

export interface PackagesSettingDto {
  packageSettingGroup?: PackageSettingGroup
  packagesSetting?: PackageDtoModel[]
}

export enum PackageSettingGroup {
  AnnualPlatformFee = 'AnnualPlatformFee',
  AiBigData = 'AiBigData',
  MerchantPackage = 'MerchantPackage',
  PackageService = 'PackageService',
  AiBigDataTravel = 'AiBigDataTravel',
  AiTrader = 'AiTrader',
}

export interface PackageDtoModel {
  id: string
  name: string
  price: number
  status?: string
  packageSettingGroup?: string
  blockPurchase: boolean
  rowSpan?: number
  entityType?: string
  boName?: string
  boDisplayName: string
  hasMonthlyLimitAndReserve: boolean
  monthlyPurchasedPackageLimit: number | null
  monthlyPurchasedReserveByRanks: number[]
  tabSelectedList: string[]
}

export interface GetPackageSettingDto extends PackageDtoModel {
  isBlockPurchase: boolean
  tag: string
  imageUrl: string
  packageDescriptions: PackageDescriptionDetail[]
  lastModified: Date | undefined
  lastModifiedBy: string | undefined
  packageTabSelected: string[]
}
export interface PackageDescriptionDetail {
  languageId?: string | undefined
  languageCulture?: string
  description?: string
}

export interface PaymentOptionGroupDto {
  paymentOptionGroup?: PaymentOptionGroup
  displayOrder?: number
  paymentOptions?: PaymentOptionDto[]
}

export interface PaymentOptionDto {
  paymentMethod: WalletType
  accept?: boolean
  displayOrder?: number
  paymentOptionId?: string
  paymentOptionGroup?: PaymentOptionGroup
}

export interface SavingTerm {
  id: string
  name: string | null
  months: number
  currencyUnit: CurrencyUnit // Assume CurrencyUnit is a defined enum
  status: SavingTermStatus // Assume SavingTermStatus is a defined enum
  startDate: Date | string | null
  endDate?: Date | string | null
  fromAmount: number
  toAmount?: number | null
  interestPeriodType: SavingInterestPeriodType // Assume SavingInterestPeriodType is a defined enum
  annualInterestRate: number
  maxInterestPeriods: number
  earlyWithdrawalRate: number
}

export interface SavingAccount {
  id: string
  profileId: string
  profile?: Profile | null
  savingTermId: string
  savingTerm?: SavingTerm | null
  accountNo?: string | null
  baseAmount: number
  currencyAmount: number
  currencyRate: number
  currencyUnit: CurrencyUnit // Assume CurrencyUnit is a defined enum
  startDate: Date | string
  endDate: Date | string
  status: SavingAccountStatus // Assume SavingAccountStatus is a defined enum
  annualInterestRate: number
  months: number
  estimatedInterestAmount: number
  interestPeriodType: SavingInterestPeriodType // Assume SavingInterestPeriodType is a defined enum
  interestPeriodAmount: number
  maxInterestPeriods: number
  paidInterestPeriods: number
  nextInterestPeriodDueDate?: Date | null | string
  paidInterestTotalAmount: number
  receivedUnit: CurrencyUnit // Assume CurrencyUnit is a defined enum
  receivedWallet: WalletType // Assume WalletType is a defined enum
  earlyWithdrawalRate: number
}
interface Profile {
  id: string
  username: string
  phoneNumber: string
  email: string
  referralCode: number
  referralCodeFormat: string
}
export interface SavingTermDetail {
  id: string
  months: number
  currencyUnit: string
  startDate: string
  endDate: string
  status: string
  maxInterestPeriods: number
  fromAmount: number
  toAmount: number
  interestPeriodType: string
  annualInterestRate: number
  earlyWithdrawalRate: number
  localeName: {
    en: string
    vi: string
    ja: string
  }
}
export interface SavingTermDetailUpdate {
  id?: string
  months: number
  currencyUnit: string
  startDate: string | Date | null
  endDate: string | Date | null
  status: string
  fromAmount: number
  toAmount: number
  interestPeriodType: string
  annualInterestRate: Decimal | number
  earlyWithdrawalRate: number
  localeName: {
    en: string
    vi: string
    ja: string
  }
}

export interface SwapProgressingModel {
  userName: string
  id: string
  inAmount: number
  feeAmount: number
  inUnit: string | undefined
  outAmount: number
  outUnit: string | undefined
  status: SwapStatus
  isAuto: boolean
  slippageAmount: number
  completedOrCancelDate: Date | undefined
  created: Date
  rate: number
}
export interface ExternalTransferModel {
  id: string | undefined
  userName: string
  email: string
  grossAmount: number
  feeAmount: number
  netAmount: number
  token: string
  status: StatusExtenralStatus
  expireTime: string
  txID: string
}
export interface MaintenanceSetting {
  id: string
  page: string
  available: boolean
}

export enum WalletBlackListStatus {
  Unactived = 'Unactived',
  Actived = 'Actived',
}
export interface UserLock {
  id: string
  username: string
  profileId: string
  status: WalletBlackListStatus
}
export interface CheckVersionApp {
  id: string
  platform: string
  version: string
}

export interface BonusRecipientChangeHistory {
  id: string
  bonusInitiator: BasicUserProfile
  originalRecipient: BasicUserProfile
  newRecipient: BasicUserProfile
  startDate: Date
  endDate: Date
  status: MonthlyPassCodeBonusRecipientChangeStatus
  bonusType: BonusRecipientChangeType
  paidAmount: number
}

export interface BasicUserProfile {
  id: string
  firstName: string
  lastName: string
  username: string
  phoneNumber: string
  dialCode: string
  gender: string
  referralCodeFormat: string
  logoPath: string
}

export enum MonthlyPassCodeBonusRecipientChangeStatus {
  Removed = 'Removed',
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum BonusRecipientChangeType {
  Direct = 'Direct',
  Indirect = 'Indirect',
  Both = 'Both',
}
export interface ModelFeeTransferConfig {
  id: string | undefined
  channelType: string
  walletType: string
  minTransfer: number
  maxTransfer: number
  feeRate: number
}

export interface ModelPayoutMethod {
  // todo: update model theo API
  id: string | undefined
  name: string | undefined
  description: string | undefined
  type: string | undefined
  status: boolean
}
export interface ModelPaymentMethod {
  // todo: update model theo API
  id: string | undefined
  name: string | undefined
  description: string | undefined
  type: string | undefined
  status: boolean
}

export interface ModelPaymentOption {
  paymentMethod: string
  accept: boolean
  renewAccepted: boolean
  displayOrder: number
  paymentOptionId: string
  paymentOptionGroup: string
}

export interface ModelPaymentOptionGroup {
  paymentOptionGroup: string
  displayOrder: number
  paymentOptions: ModelPaymentOption[]
}

export interface ModelPaymentData {
  paymentOptionGroups: ModelPaymentOptionGroup[]
}

export interface ModelPaymentMethodItem {
  id: string
  name: string
  description: string
  imagePath: string
  displayOrder: number
  type: string
  status: string
  paymentMethodOptionDetails: ModelPaymentMethodOptionDetails[]
}

interface ModelPaymentMethodOptionItem {
  name: string
  description: string
  imagePath: string
  paymentWallet: string
  tokenUnit: string
  isShowUsdValue: boolean
  displayOrder: number
}

interface ModelPaymentMethodOptionDetails {
  paymentMethodOptionItem: ModelPaymentMethodOptionItem
  percentage: number
  isFixedPercentage: boolean
  feeRate: number
}

export interface ModePackageFitter {
  packageName: string
  status: string
  group: string
}

export interface PaymentMethodFitter {
  type: string | null
  status: string | null
  searchText: string | null
}
export interface PaymentMethodOptionDetails {
  tokenItemId: string
  percentage: number
  isFixedPercentage: boolean
}

export interface PaymentMethodOption {
  name: string
  type: string
  paymentMethodOptionDetails: PaymentMethodOptionDetails[]
}
export interface Status {
  id: string
  value: boolean
}

//search payment method
export interface I2SearchPaymentMethod {
  type?: string
  entity?: I2SearchMonthlyPassEntity
  status?: string
  searchText?: string
}

interface I2SearchMonthlyPassEntity {
  entityType: string
  entityId: string
  isIncludeUnsetItems: boolean
}
interface I2PaymentMethodOptionItem {
  id: string
  name: string
  description: string | null
  imagePath: string
  paymentWallet: string
  tokenUnit: string
  isShowUsdValue: boolean
  displayOrder: number
}

export interface I2PaymentMethodOptionDetail {
  id: string
  paymentMethodOptionItem: I2PaymentMethodOptionItem
  percentage: number
  isFixedPercentage: boolean
  feeRate: number
}

export interface I2PaymentMethod {
  id: string
  name: string
  description: string | null
  imagePath: string
  displayOrder: number
  type: string
  paymentMethodOptionDetails: I2PaymentMethodOptionDetail[]
  isSetToEntityItem: boolean
  status: string
}
export interface I2ParamSetPayment {
  entityType: string
  entityId: string
  paymentMethodIds: string[]
}
// Interfaces for the data structure

interface PaymentMethodOptions {
  name: string
  imagePath: string
  type: string
}

interface PaymentMethodOptionItem {
  name: string
  imagePath: string
  paymentWallet: string
  tokenUnit: string
  isShowUsdValue: boolean
}

interface PaymentLogDetail {
  paymentMethodOptionItem: PaymentMethodOptionItem
  percentage: number
  usdAmount: number
  amount: number
  feeAmount: number
  feeRate: number
  tokenRate: number
}

export interface PaymentData {
  paymentMethodOption: PaymentMethodOptions
  paymentMethodConfigs: string
  paymentLogDetails: PaymentLogDetail[]
}
