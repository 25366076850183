import { MaintenanceSetting } from 'models'
import React, { useEffect, useState } from 'react'
import IsConfirmAlert from 'components/confirmAlert'
import { time } from 'console'
interface TypeProps {
  data: Array<MaintenanceSetting>
  onUpdate: Function
}

function ListMaintenance(props: TypeProps) {
  const { data, onUpdate } = props

  const [list, setList] = useState<Array<MaintenanceSetting>>([])
  useEffect(() => {
    setList(data)
  }, [data])
  const handleUpdateAvailable = async (id: string, myValue: any, page: string) => {
    IsConfirmAlert('Are you sure?', ``, '', false, function (confirmed: any) {
      if (confirmed) {
        onUpdate({
          id: id,
          page: page,
          available: myValue,
        })
      }
    })
  }
  return (
    <>
      {data && (
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-hover">
              <thead>
                <tr className="text-muted">
                  <th>Page</th>
                  <th>Avalible</th>
                </tr>
              </thead>
              <tbody>
                {list?.map((item, index) => (
                  <tr key={item.id}>
                    <td>
                      <b>{item.page}</b>
                    </td>
                    <td>
                      {' '}
                      <div className="row gx-1">
                        <div className="col-auto">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={item.available}
                              onChange={(event) => {
                                const myValue =
                                  event.target.type === 'checkbox'
                                    ? !!event.target.checked
                                    : !!event.target.value
                                handleUpdateAvailable(item.id, myValue, item.page)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default ListMaintenance
