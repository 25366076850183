export enum PassCodeType {
  Normal = 'Normal',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}
export enum WalletType {
  Usdt = 'Usdt',
  Vlg = 'Vlg',
  Vmm = 'Vmm',
  Vusd = 'Vusd',
  Vnd = 'Vnd',
  Usd = 'Usd',
  Vpc = 'Vpc',
  VlgStalking = 'VlgStalking',
  VpcBonus = 'VpcBonus',
  Usdc = 'Usdc',
}
export enum TokenStatisticFilterBy {
  Total = 'Total',
  Username = 'Username',
}
export enum PaymentMethod {
  None = -1,
  CreditCard = 0,
  Usdt = 1,
  Vmm = 2,
  Vusd = 3,
  Vpc = 4,
  Usd = 5,
  Vnd = 6,
  Vlg = 7,
  VpcBonus = 8,
}
export enum MemberReportFilterStatus {
  ALL = 'All',
  Renewed = 'Renewed',
  Bought = 'Bought',
  Expired = 'Expired',
  Active = 'Active',
}
export enum PackageStatus {
  ALL = 'All',
  Active = 'ACTIVE',
  InActive = 'INACTIVE',
}

export enum DateRangeFilterType {
  Today = 'Today',
  Last7Days = 'Last 7 days',
  Last30Days = 'Last 30 days',
  Custom = 'Custom',
}
export enum PaymentMethodName {
  VmmWallet = 'VMM_WALLET',
  VlgWallet = 'VLG_WALLET',
  VusdWallet = 'VUSD_WALLET',
  UsdtWallet = 'USDT_WALLET',
  UsdWallet = 'USD_WALLET',
  VndWallet = 'VND_WALLET',
  VlgStalkingWallet = 'VLG_STALKING_WALLET',
  CreditCard = 'CREDIT_CARD',
  VpcWallet = 'VPC_WALLET',
  VpcBonusWallet = 'VPCBONUS_WALLET',
  VlinkPayQR = 'VLINKPAY_QR',
}
export enum PackageSituation {
  ALL = '',
  Available = 'ACTIVE',
  NotAvailable = 'INACTIVE',
}
export enum ChannelType {
  Internal = 0,
  MoongleFarm = 1,
  VlinkPay = 2,
  VlinkExchange = 3,
}
export enum ChannelTypeItem {
  Internal = 'Internal',
  MoongleFarm = 'MoongleFarm',
  VlinkPay = 'VlinkPay',
  VlinkExchange = 'VlinkExchange',
}
export enum DateType {
  BuyDate = 'BuyDate',
  ExpiredDate = 'ExpiredDate',
}

//Coding

export enum UserCountryEnum {
  UNITED_STATES = 'United States',
  VIETNAM = 'Vietnam',
}

export enum UserGenderEnum {
  MALE = 'Male',
  FEMALE = 'Female',
}
