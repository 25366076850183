import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'contexts/AuthContexts'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}
function Header(props: any) {
  const { handelSignOutCallback } = useContext(AuthContext)
  const [isOpen, setIsOpen] = useState(true)
  const [isDarkmode, setIsDarkmode] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.remove('menu-close')
    } else {
      document.body.classList.add('menu-close')
    }
  }, [isOpen])

  useEffect(() => {
    const localDarkmode = localStorage.getItem('dark-mode')
    setIsDarkmode(Boolean(localDarkmode))

    function handleResize() {
      const windowDimensions = getWindowDimensions()
      if (windowDimensions.width <= 992) {
        setIsOpen(false)
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const userinfor = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_AUTH_CLIENT}`,
  )
  const userinforObj = userinfor ? JSON.parse(userinfor) : null

  useEffect(() => {
    if (isDarkmode) {
      document.body.classList.add('dark-mode')
    } else {
      document.body.classList.remove('dark-mode')
    }
  }, [isDarkmode])

  const handleChangeDarkmode = (event: any) => {
    const isChecked = event.target.checked
    setIsDarkmode(isChecked)
    if (isChecked) {
      localStorage.setItem('dark-mode', 'true')
    } else {
      localStorage.removeItem('dark-mode')
    }
  }

  const logout = async () => {
    const res = await axiosClient().post(END_POINTS.USER_API_END_POINTS.LOGOUT(), {})
    if (res) {
      handelSignOutCallback()
    }
  }
  return (
    <>
      <div className="header">
        <nav className="navbar fixed-top">
          <div className="container-fluid">
            <div className="sidebar-width">
              <button
                className="btn btn-link btn-square menu-btn"
                type="button"
                onClick={() => setIsOpen(!isOpen)}
              >
                <i className="bi bi-list fs-4"></i>
              </button>
              <Link className="navbar-brand ms-2" to="/">
                <div className="row">
                  <div className="col-auto">
                    <img src="/logo-auth.png" className="mx-100" alt="" />
                  </div>
                </div>
              </Link>
            </div>

            <div className="ms-auto">
              <div className="row align-items-center ">
                <div className="col-auto">
                  <div
                    className="form-check form-switch mt-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Dark mode"
                  >
                    <div className="switch-dark-mode">
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="checkbox"
                        checked={isDarkmode}
                        onChange={handleChangeDarkmode}
                      />
                      <label htmlFor="checkbox" className="label">
                        <i className="bi bi-moon"></i>
                        <i className="bi bi-sun"></i>
                        <div className="ball"></div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      className="border-0"
                      size="sm"
                      id="dropdown-user"
                    >
                      <div className="row gx-1">
                        <div className="col-auto align-self-center">
                          <figure className="avatar avatar-30 rounded-circle coverimg vm border">
                            <img src="/assets/img/avatar.png" alt="" />
                          </figure>
                        </div>
                        <div className="col ps-0 align-self-center d-none d-lg-block">
                          <div className="mb-0">
                            <span className="text-dark username px-2">
                              {userinfor ? userinforObj?.profile?.email : 'null'}
                            </span>
                          </div>
                        </div>
                        <div className="col ps-0 align-self-center d-none d-lg-block">
                          <i className="bi bi-chevron-down small vm"></i>
                        </div>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>My Profile</Dropdown.Item>
                      <Dropdown.Item>Account Setting</Dropdown.Item>
                      <hr className="p-0 m-0 mt-2 mb-1" />
                      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  )
}
export default Header
