import React from 'react'
import UserManager from 'services/UserManager'
import { TOKEN_ADMIN, LOGIN_ROUTE as LOGIN_ROUTE } from 'helpers/constants'

export interface User {
  id_token: string
  session_state: string
  access_token: string
  token_type: string
  scope: string
  profile: Profile
  expires_at: number
}

interface Profile {
  s_hash: string
  sid: string
  sub: string
  auth_time: number
  idp: string
  amr: string[]
  email: string
  preferred_username: string
  name: string
  email_verified: boolean
  role: string
  phone_number: string
}

export interface AccessTokenClaims {
  iss: string
  aud: Array<string>
  client_id: string
  email: string
  scope: Array<string>
}

interface AppCtx {
  user: User
  claims?: AccessTokenClaims
  isAuthenticated: boolean
  verifiedPasscode: boolean
  handelSignInCallback: () => Promise<void>
  handelSignOutCallback: () => Promise<void>
  handelRefreshTokenCallback: () => Promise<void>
  handelSetVerifiedPasscode: (data: boolean) => void
}

export const AuthContext = React.createContext<AppCtx>({} as AppCtx)

const AuthProvider = ({ initToken, children }: any) => {
  const [user, setUser] = React.useState<any>(null)
  const [verifiedPasscode, setVerifiedPasscode] = React.useState(false)

  React.useEffect(() => {
    UserManager.Oidc.events.addUserLoaded(onUserLoaded)
    UserManager.Oidc.events.addUserUnloaded(onUserUnloaded)
    UserManager.Oidc.events.addAccessTokenExpired(onAccessTokenExpired)
    UserManager.Oidc.events.addSilentRenewError(onSilentRenewError)

    return () => {
      UserManager.Oidc.events.removeUserLoaded(onUserLoaded)
      UserManager.Oidc.events.removeUserUnloaded(onUserUnloaded)
      UserManager.Oidc.events.removeAccessTokenExpired(onAccessTokenExpired)
      UserManager.Oidc.events.removeSilentRenewError(onSilentRenewError)
    }
  })

  React.useEffect(() => {
    const onPageLoad = async () => {
      const token = localStorage.getItem(TOKEN_ADMIN)
      const userinfor = localStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_AUTH_CLIENT}`,
      )
      const userinforObj = userinfor ? JSON.parse(userinfor) : null
      if (!token || !userinforObj) {
        window.location.replace(LOGIN_ROUTE)
        return
      } else {
        const loginUser = await UserManager.Oidc.getUser()
        if (loginUser && loginUser.access_token && typeof window !== 'undefined') {
          await UserManager.Oidc.events.load(loginUser)
        }
      }
    }
    onPageLoad()
  }, [])

  const onUserLoaded = (user: any) => {
    setUser(user)
  }

  const onUserUnloaded = () => {
    setUser(null)
    localStorage.clear()
    UserManager.Oidc.clearStaleState()
    if (typeof window !== 'undefined') {
      window.location.href = LOGIN_ROUTE
    }
  }

  const onSilentRenewError = (err: any) => {
    console.error('renew error', err)
  }

  const onAccessTokenExpired = async () => {}

  const handelSignInCallback = async () => {}

  const handelSignOutCallback = async () => {
    onUserUnloaded()
  }

  const handelRefreshTokenCallback = async () => {}

  const handelSetVerifiedPasscode = (data: boolean) => {
    setVerifiedPasscode(data)
  }

  const value: AppCtx = {
    user,
    isAuthenticated: !!user,
    verifiedPasscode,
    handelSignInCallback,
    handelSignOutCallback,
    handelRefreshTokenCallback,
    handelSetVerifiedPasscode,
  }

  return (
    <AuthContext.Provider value={value}>
      {value.isAuthenticated ? children : null}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AppCtx => React.useContext<AppCtx>(AuthContext)
export default AuthProvider
