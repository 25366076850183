import swal from 'sweetalert'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
export default function OpenAlert(title: string, text: string, icon: string) {
  swal({
    title: title,
    text: text,
    icon: icon,
  })
}

const MySwal = withReactContent(Swal)
export function OpenAlert2(title: string, text: string, icon: string) {
  MySwal.fire({
    title: title,
    html: text,
    iconHtml: icon,
    width: '40em',
  })
}
