import { CheckVersionApp } from 'models'
import React, { useCallback, useEffect, useState } from 'react'
import { axiosClient } from 'http-services'
import END_POINTS from 'http-services/end-points'
import Toast from 'components/toast'
import ListVersion from 'components/app-check-version'
import OpenAlert from 'components/sweetAlert'
import { LOADING } from 'helpers/constants'

function AppCheckVersion() {
  const [dataCheckversion, setDataSwaps] = useState<Array<CheckVersionApp>>([])
  const [isLoading, setIsLoading] = useState(false)
  const loadData = useCallback(async () => {
    PubSub.subscribe(LOADING, function (msg, data) {
      setIsLoading(data)
    })
    const query = await axiosClient().get(END_POINTS.APP_SETTING.GET_LIST_CHECK_VERSION())
    if (!query.data) return
    setDataSwaps(query.data)
  }, [])
  useEffect(() => {
    loadData()
  }, [loadData])
  const onUpdate = async (update: any) => {
    const res = await axiosClient().put(END_POINTS.APP_SETTING.UPDATE_CHECK_VERSION(update.id), {
      id: update.id,
      version: update.version,
    })
    if (res) {
      loadData()
      OpenAlert('Update success', '', 'success')
    }
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center page-title">
          <div className="col-12 col-md mb-2 mb-sm-0">
            <h3 className="mb-0">APP Version</h3>
          </div>
        </div>
        <hr className="p-0 m-0 mb-4" />
      </div>
      <div className="container-fluid">
        <div className="card border-0 mt-3">
          {isLoading && (
            <div className="loading-data">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          )}
          <ListVersion data={dataCheckversion} onUpdate={onUpdate} />
        </div>
      </div>
      <div className="pt-2"></div>
      <Toast />
    </>
  )
}

export default AppCheckVersion
